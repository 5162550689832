import { CompanyRole } from "@/types";
import { NavMenu, NavMenuId, NavMenuType } from "@/models/core/nav-menu.model";

export default [
  {
    type: NavMenuType.MENU,
    title: "홈",
    path: "/home",
    icon: "mdi-home",
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
  },
  {
    type: NavMenuType.DIVIDER,
  },
  {
    id: NavMenuId.notification,
    type: NavMenuType.MENU,
    title: "알림",
    path: "/notification",
    badge: {
      icon: "mdi-bell",
      color: "red",
      emptyIcon: "mdi-bell-outline",
      content: "",
    },
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
  },
  {
    type: NavMenuType.DIVIDER,
  },
  {
    type: NavMenuType.MENU,
    title: "대시보드",
    path: "/dashboard",
    icon: "mdi-view-dashboard",
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
  },
  {
    type: NavMenuType.MENU,
    title: "견적",
    path: "/estimate",
    icon: "mdi-apps",
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
  },
  {
    type: NavMenuType.MENU,
    title: "상담내역",
    path: "/estimate-counsel",
    icon: "mdi-apps",
    companyRoles: [CompanyRole.OWNER],
  },
  {
    type: NavMenuType.MENU,
    title: "결제문자",
    path: "/payment-sms",
    icon: "mdi-email-outline",
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
  },
  {
    id: NavMenuId.support,
    type: NavMenuType.MENU,
    title: "고객지원",
    path: "/support",
    badge: {
      icon: "mdi-comment-question-outline",
      color: "red",
      emptyIcon: "mdi-comment-question-outline",
      content: "",
    },
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
  },
  {
    id: NavMenuId.supportAs,
    type: NavMenuType.MENU,
    title: "A/S 현황",
    path: "/support-as",
    badge: {
      icon: "mdi-headset",
      color: "red",
      emptyIcon: "mdi-headset",
      content: "",
    },
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
  },
  {
    type: NavMenuType.MENU,
    title: "재고관리",
    path: "/mgmt/inventory",
    icon: "mdi-briefcase-search",
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
  },
  {
    type: NavMenuType.DIVIDER,
  },
  {
    id: NavMenuId.schedule,
    type: NavMenuType.MENU,
    title: "일정",
    path: "/schedule",
    icon: "mdi-calendar",
    subMenuList: [],
  },
  {
    type: NavMenuType.DIVIDER,
  },
  {
    type: NavMenuType.MENU,
    title: "업체 정보 관리",
    path: "/mgmt",
    icon: "mdi-cogs",
    subMenuList: [
      {
        type: NavMenuType.MENU,
        title: "아파트",
        path: "/place",
        icon: "flaticon-pin",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "단가",
        path: "/unit-price",
        icon: "mdi-credit-card",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "특별단가",
        path: "/special-price",
        icon: "mdi-credit-card",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "평면도",
        path: "/floor-plan",
        icon: "mdi-floor-plan",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "작업범위",
        path: "/work-scope",
        icon: "mdi-application-settings",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "품목",
        path: "/category",
        icon: "mdi-application-settings",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "팀",
        path: "/team",
        icon: "mdi-microsoft-teams",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "결제문자",
        path: "/payment-sms",
        icon: "mdi-email-outline",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
      },
      {
        type: NavMenuType.MENU,
        title: "사용자",
        path: "/user",
        icon: "mdi-account-supervisor",
        companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
      },
    ],
  },
  // {
  //   type: NavMenuType.MENU,
  //   title: "이벤트",
  //   path: "/event",
  //   icon: "mdi-square-rounded-outline",
  //   subMenuList: [
  //     {
  //       type: NavMenuType.MENU,
  //       title: "룰렛 이벤트",
  //       path: "/list",
  //       companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
  //     },
  //     {
  //       type: NavMenuType.MENU,
  //       title: "특가표 링크 만들기",
  //       path: "/special-price-link",
  //       icon: "mdi-link-variant",
  //       companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER],
  //     },
  //     {
  //       type: NavMenuType.MENU,
  //       title: "이벤트 접속 로그",
  //       path: "/access-log",
  //       companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN],
  //     },
  //   ],
  // },
  {
    type: NavMenuType.DIVIDER,
  },
  {
    type: NavMenuType.MENU,
    title: "설정",
    path: "/settings",
    icon: "mdi-cog",
    companyRoles: [CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER, CompanyRole.USER],
  },
  {
    id: NavMenuId.logout,
    type: NavMenuType.MENU,
    title: "로그아웃",
    icon: "mdi-logout",
  },
] as NavMenu[];
