
import Vue from "vue";
import CompanyAddEditComponent from "@/components/company/CompanyAddEditComponent.vue";

export default Vue.extend({
  name: "CompanyAdd",
  components: {
    CompanyAddEditComponent,
  },
});
