
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import FloorPlanService from "@/services/work/floor-plan.service";

export default mixins(MixinsPage, MixinsTable, MixinsScroll).extend({
  name: "MgmtFloorPlan",
  components: {},
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
  }),
  mounted() {
    this.initTable({
      service: FloorPlanService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
    });
  },
  methods: {
    showEditPage(item: any) {
      //console.log("item : ", item);
      this.$router.push({ path: `/mgmt/floor-plan/edit`, query: { id: item.id } });
    },
    showAddPage() {
      //console.log("showAddPage");
      this.$router.push({ path: `/mgmt/floor-plan/add` });
    },
  },
});
