import korea from "vee-validate/dist/locale/ko.json";
import core from "@/core";

class Constant {
  appName = "콩콩매트";
  validate = {
    language: {
      korea: korea,
    },
  };

  customerUrl = "https://customer.kongkongmat.com";
  eventUrl = "https://event.kongkongmat.com";

  kakao = {
    javascriptKey: "42d1503821d0bfe873902a25fb3b5bc8",
    restApiKey: "395b9d805c7e3df0e23d674c1c5b5b1b",
  };

  viewerOptions = {
    inline: false,
    button: true,
    navbar: true,
    title: false,
    toolbar: {
      zoomIn: true,
      zoomOut: true,
      oneToOne: true,
      reset: true,
      rotateLeft: true,
      rotateRight: true,
      flipHorizontal: true,
      flipVertical: true,
      prev: true,
      play: true,
      next: true,
      download: async (e) => {
        const viewer = document.querySelector(
          ".viewer-container.viewer-in .viewer-canvas img"
        ) as any;
        if (viewer) {
          try {
            await core.http.downloadFile(viewer.src);
          } catch (e) {
            console.log(e);
          }
        }
      },
    },
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: true,
    keyboard: true,
  };

  iosStoreUrl = "https://apps.apple.com/kr/app/id6446343053";
}

export default new Constant();
