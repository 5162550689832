
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import store from "@/store";
import core from "@/core";

export interface MatModel {
  id: number;
  enableDelete: boolean;
  name: string;
  unitPrice: string;
  width: string;
  length: string;
  height: string;
  volume: string;
  price: string;
  count: string;
}

export default mixins(MixinsModalForm).extend({
  name: "ExpositionCalculatorModal",
  components: {},
  data: () => ({
    app: store.state.app,
    area: "",
    matIndex: 0,
    matList: [] as MatModel[],
  }),
  mounted() {
    this.$nextTick(async () => {
      const storageItem = (await this.$store.dispatch(
        "storage/getStorageItem",
        "exposition-calculator"
      )) as any;
      // console.log("storageItem : ", storageItem);
      if (storageItem != null && storageItem.matList) {
        this.matList = storageItem.matList;
        this.matIndex = storageItem.matIndex;
      } else {
        const matList = this.matList;
        matList.push({
          id: this.matIndex++,
          enableDelete: false,
          name: "콩콩매트",
          unitPrice: "",
          width: "",
          length: "",
          height: "",
          volume: "",
          price: "",
          count: "",
        });
        this.addMat();
      }

      // const watch = this.$store.watch(
      //     () => this.$store.state.auth.paymentSmsSettings,
      //     (paymentSmsSettings) => {
      //       core.loader.hide();
      //       this.itemList = paymentSmsSettings;
      //     }
      // );
    });
  },
  watch: {
    area() {
      this.matList.forEach((mat) => {
        this.changedMat(mat);
      });
    },
  },
  methods: {
    changedMatItem(item: MatModel, key: string) {
      let error = true;
      ["unitPrice", "width", "length", "height"].some((standardKey) => {
        if (key === standardKey) {
          error = false;
          return true;
        }
      });
      if (error) {
        console.log("not support key. ", key);
        return;
      }
      // console.log(`changed ${key} : ${item[key]}`);
      const val = item[key];
      const strChangeVal = core.utils.format.onlyNumberDecimalPoint(val);
      // console.log("val : ", val, ", strChangeVal : ", strChangeVal);
      if (strChangeVal != val) {
        // const changeVal = Number(strChangeVal);
        item[key] = strChangeVal;
      }
      this.changedMat(item);
    },
    numberToAlphabet(number) {
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      if (number >= 1 && number <= 26) {
        return alphabet.charAt(number - 1);
      } else {
        return "숫자가 범위를 벗어납니다.";
      }
    },
    addMat() {
      const matList = this.matList;
      const name = this.numberToAlphabet(matList.length) + "社매트";
      matList.push({
        id: this.matIndex++,
        enableDelete: true,
        name: name,
        unitPrice: "",
        width: "",
        length: "",
        height: "",
        volume: "",
        price: "",
        count: "",
      });
    },
    deleteMat(item: MatModel) {
      const matList = this.matList;
      const length = matList.length;
      for (let i = 0; i < length; i++) {
        const mat = matList[i];
        if (mat.id === item.id) {
          matList.splice(i, 1);
          break;
        }
      }
      // console.log("delete mat : ", item);
    },
    changedMat(mat: MatModel) {
      if (
        this.isNotBlank(mat.width) &&
        this.isNotBlank(mat.length) &&
        this.isNotBlank(mat.height) &&
        !isNaN(Number(mat.width)) &&
        !isNaN(Number(mat.length)) &&
        !isNaN(Number(mat.height))
      ) {
        console.log("calculator : ", mat.width, ", ", mat.length, ", ", mat.height);
        mat.volume = (Number(mat.width) * Number(mat.length) * Number(mat.height)).toFixed(2);
        mat.price = (Number(mat.unitPrice) / Number(mat.volume)).toFixed(2);

        let count = "";
        const area = (Number(mat.width) * Number(mat.length)) / 10000;
        if (this.isNotBlank(this.area)) {
          if (!isNaN(Number(this.area))) {
            count = (Number(this.area) / area).toFixed(0);
          }
        }
        mat.count = count;
      } else {
        mat.price = "";
      }

      const storageItem = {
        matList: this.matList,
        matIndex: this.matIndex,
      };
      this.$store
        .dispatch("storage/setStorageItem", {
          type: "exposition-calculator",
          items: storageItem,
        })
        .then(() => {
          console.log("저장");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    confirm() {},
  },
});
