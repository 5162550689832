
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";
import { EstimateMoreViewType } from "@/models/estimate";
import ScheduleCardComponent from "@/components/dashboard/ScheduleCardComponent.vue";
import EstimateCardComponent from "@/components/dashboard/EstimateCardComponent.vue";
import { ScheduleMoreViewType } from "@/models/schedule";

export default mixins(MixinsPage, MixinsScroll).extend({
  name: "Dashboard",
  components: { ScheduleCardComponent, EstimateCardComponent },
  data: () => ({
    component: {
      waitPayment: {
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: EstimateMoreViewType.WAIT_PAYMENT,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      oldWaitPayment: {
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: EstimateMoreViewType.OLD_WAIT_PAYMENT,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      notCompleteSchedule: {
        type: "schedule",
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: ScheduleMoreViewType.NOT_COMPLETE_SCHEDULE,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      issue: {
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: EstimateMoreViewType.ISSUE,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      waitContract: {
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: EstimateMoreViewType.WAIT_CONTRACT,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      expectedRemainingDate: {
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: EstimateMoreViewType.EXPECTED_REMAINING_DATE,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      lastScheduledDate: {
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: EstimateMoreViewType.LAST_SCHEDULED_DATE,
        },
      },
      workStatus: {
        params: {
          table: { response: { recordsTotal: 0 } } as any,
          viewType: EstimateMoreViewType.WORK_STATUS,
        },
      },
      updateEvent: null as any as UpdateEvent | null,
    },
  }),
  async activated() {
    console.log("activated");

    this.component.waitPayment.params.table.event.reloadFlag = true;
    this.component.oldWaitPayment.params.table.event.reloadFlag = true;
    this.component.notCompleteSchedule.params.table.event.reloadFlag = true;
    this.component.issue.params.table.event.reloadFlag = true;
    this.component.waitContract.params.table.event.reloadFlag = true;
    this.component.expectedRemainingDate.params.table.event.reloadFlag = true;
    this.component.lastScheduledDate.params.table.event.reloadFlag = true;
    this.component.workStatus.params.table.event.reloadFlag = true;

    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (
        event.result === UpdateEventType.RELOAD ||
        event.result === UpdateEventType.UPDATE ||
        event.result === UpdateEventType.DELETE
      ) {
        this.setScrollPosition(ScrollPosition.TOP);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },
  methods: {
    showEstimateMorePage(viewType: string) {
      this.$router.push({
        path: "/dashboard/estimate-more",
        query: {
          viewType: viewType,
        },
      });
    },
    showScheduleMorePage(viewType: string) {
      this.$router.push({
        path: "/dashboard/schedule-more",
        query: {
          viewType: viewType,
        },
      });
    },
  },
});
