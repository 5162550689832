
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import ScheduleSortModal from "@/modals/schedule/ScheduleSortModal.vue";
import core from "@/core";
import ScheduleService from "@/services/work/schedule.service";
import ScheduleListViewTypeModal from "@/modals/schedule/ScheduleListViewTypeModal.vue";
import ScheduleUtils, { FilterScheduleViewType, ScheduleViewType } from "@/models/schedule";
import TeamService from "@/services/team/team.service";
import TextFieldModal from "@/modals/core/TextFieldModal.vue";
import TextAreaModal from "@/modals/core/TextAreaModal.vue";
import ScheduleUserMemoService from "@/services/work/schedule-user-memo.service";
import UpdateEvent from "@/models";
import MixinsStorage from "@/mixins/single/storage";
import { UpdateEventType } from "@/types";
import { UserModel } from "@/models/user/user.model";

export default mixins(MixinsPage, MixinsStorage).extend({
  name: "ScheduleDay",
  components: {
    TextAreaModal,
    TextFieldModal,
    ScheduleListViewTypeModal,
    ScheduleSortModal,
  },
  data: () => ({
    storageItems: {
      type: "schedule",
    },
    title: "",
    scheduleList: [],
    searchColumns: null as any,
    date: "",
    issue: false,
    totalPrice: 0,
    query: {} as any,
    sort: {
      orderColumnName: "id",
      order: "asc",
    } as any,
    listViewType: "user",
    modal: {
      sort: {
        visible: false,
        params: {
          sort: {
            orderColumnName: "id",
            order: "asc",
          },
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      listViewType: {
        visible: false,
        params: {
          listViewType: "user",
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      menu: {
        visible: false,
        params: {
          item: null as any,
        },
        updateEvent: null as any as UpdateEvent | null,
      },
      userMemo: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 1000,
          },
          title: "사용자 메모",
          type: "add",
          text: "",
          item: null as any,
          enableDeleteButton: false,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    viewType: ScheduleViewType.ALL,
    filterViewType: FilterScheduleViewType.ALL,
    updateEvent: null as any as UpdateEvent | null,
    myUser: {} as UserModel,
  }),
  computed: {
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.myUser = await this.$store.getters["auth/user"]();

      const query = (this.query = core.utils.deepCopy(this.$route.query));
      if (query.date != null && core.date.instance(query.date as string).isValid()) {
        this.date = query.date as string;
        const searchColumns = core.utils.deepCopy(query);
        delete searchColumns.date;
        delete searchColumns.stackKey;
        this.viewType = searchColumns.viewType;
        delete searchColumns.viewType;
        this.filterViewType = searchColumns.filterViewType;
        delete searchColumns.filterViewType;

        this.searchColumns = searchColumns;
      } else {
        await this.notFound();
      }

      const moment = core.date.instance(this.date);
      if (moment.isValid()) {
        this.title = moment.format("YYYY년 MM월 DD일(ddd)");

        // storage
        await this.storageWait();

        const storage = this.getStorageItems();
        if (storage) {
          if (storage.sort) {
            this.sort = storage.sort;
          }

          if (storage.listViewType) {
            this.listViewType = storage.listViewType;
          }
        }

        const searchColumns = this.searchColumns;
        //console.log("searchColumns : ", searchColumns);
        searchColumns.startDate = moment.format("YYYY-MM-DD");
        searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
        await this.getScheduleList();
      }
    });
  },
  async activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      //console.log("event : ", event);
      if (event.result === UpdateEventType.RELOAD) {
        await this.getScheduleList();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } else if (event.result === UpdateEventType.DELETE) {
        await this.getScheduleList();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },
  watch: {
    async "modal.userMemo.updateEvent"() {
      const modal = this.modal.userMemo;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          const schedule = modal.params.item;
          if (modal.params.type === "add") {
            try {
              const params = {
                date: this.date,
                userId: schedule.mainUserId,
                memo: item,
              };
              console.log("add params : ", params);
              const userMemo = (await ScheduleUserMemoService.create(params)) as any;
              console.log("userMemo : ", userMemo);
              schedule.userMemo = userMemo;
            } catch (e) {
              console.log(e);
            }
          } else if (modal.params.type === "edit") {
            try {
              const params = {
                memo: item,
              };
              console.log("edit params : ", params);
              const userMemo = (await ScheduleUserMemoService.update(
                schedule.userMemo.id,
                params
              )) as any;
              console.log("userMemo : ", userMemo);
              schedule.userMemo = userMemo;
            } catch (e) {
              console.log(e);
            }
          } else {
            console.log("unknown params, ", modal.params);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          try {
            const schedule = modal.params.item;
            const result = (await ScheduleUserMemoService.delete(schedule.userMemo.id)) as any;
            schedule.userMemo = null;
            console.log("result : ", result);
          } catch (e) {
            console.log(e);
          }
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    "modal.sort.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.sort);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          this.sort = item;
          this.setStorageItem("sort", item);
          this.drawScheduleList();
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    "modal.listViewType.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.listViewType);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          this.listViewType = item.listViewType;
          this.setStorageItem("listViewType", this.listViewType);
          this.drawScheduleList();
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    "modal.menu.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.menu);
      if (event != null) {
        //console.log("event : ", event);
        if (event.result === UpdateEventType.RELOAD) {
          this.getScheduleList();
          this.updatePreVmEvent(UpdateEventType.RELOAD);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    // "longPress.event"(val) {
    //   this.longPress.event = null;
    //   if (val != null) {
    //     this.showMenuModal(val);
    //   }
    // },
  },
  methods: {
    async updateIssue(issue) {
      try {
        core.loader.show();

        if (issue) {
          await ScheduleService.createIssue(this.date, issue);
        } else {
          await ScheduleService.removeIssue(this.date);
        }

        this.issue = issue;

        this.updatePreVmEvent(UpdateEventType.RELOAD);

        if (issue) {
          await core.alert.show({
            title: "알림",
            body: "이슈 날짜로 등록되었습니다.",
          });
        } else {
          await core.alert.show({
            title: "알림",
            body: "이슈 날짜 등록이 해제되었습니다.",
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    getTitle(item: any) {
      return ScheduleUtils.getScheduleTitle(this.myUser, this.viewType, item);
    },
    getTime(item: any) {
      if (item.allDay) {
        let text = "";
        const startMoment = core.date.instance(item.startAt);
        if (this.date != startMoment.format("YYYY-MM-DD")) {
          text += startMoment.format("D일") + " 종일";
        }

        const endMoment = core.date.instance(item.endAt);
        if (this.date != endMoment.format("YYYY-MM-DD")) {
          if (text.length > 0) text += "<br>";
          else {
            text += "종일<br>";
          }
          text += endMoment.format("~ D일") + " 종일";
        } else {
          if (text.length > 0) text += "<br>~ 종일";
        }

        if (text.length == 0) text += "종일";
        return text;
      }

      //console.log("date : ", this.date);

      const startMoment = core.date.instance(item.startAt);
      //console.log("view start at : ", item.viewStartAt);
      // const startMoment = core.date.instance(item.startAt);
      let strStartAt = "";
      if (this.date != startMoment.format("YYYY-MM-DD")) {
        strStartAt = startMoment.format("D일 a hh:mm");
      } else {
        strStartAt = startMoment.format("a hh:mm");
      }

      const endMoment = core.date.instance(item.endAt);
      //console.log("view end at : ", item.viewEndAt);
      // const endMoment = core.date.instance(item.endAt);
      let strEndAt = "";
      if (this.date != endMoment.format("YYYY-MM-DD")) {
        strEndAt = endMoment.format("~ D일 a hh:mm");
      } else {
        strEndAt = endMoment.format("~ a hh:mm");
      }
      //console.log("strEndAt : ", strEndAt);

      return `${strStartAt}<br>${strEndAt}`;
    },
    isNewSchedule(item: any) {
      const minutes = core.date.instance().diff(core.date.instance(item.createdAt), "minutes");
      return minutes < 60;
    },
    getColorStyle(item: any) {
      const hex = ScheduleUtils.colorToHex(item.color);
      let style = "";
      if (hex != null) {
        style = `color: ${hex}`;
      }
      //console.log("style : ", style);
      return style;
    },
    swipe(direction: string) {
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
    next() {
      const moment = core.date.instance(this.date).add(1, "days");
      this.date = moment.format("YYYY-MM-DD");
      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      this.searchColumns.startDate = moment.format("YYYY-MM-DD");
      this.searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
      const urlParams = core.utils.deepCopy(this.query);
      urlParams.date = this.date;
      this.replaceState(urlParams);
      this.getScheduleList();
      document.documentElement.scrollTop = 0;
    },
    prev() {
      const moment = core.date.instance(this.date).add(-1, "days");
      this.date = moment.format("YYYY-MM-DD");
      this.title = core.date.instance(`${this.date}T00:00:00`).format("YYYY년 MM월 DD일(ddd)");

      this.searchColumns.startDate = moment.format("YYYY-MM-DD");
      this.searchColumns.endDate = moment.add(1, "days").format("YYYY-MM-DD");
      const urlParams = core.utils.deepCopy(this.query);
      urlParams.date = this.date;
      this.replaceState(urlParams);
      this.getScheduleList();
      document.documentElement.scrollTop = 0;
    },
    showUserMemoModal(type, schedule) {
      // console.log("schedule : ", schedule);
      if (schedule.mainUserId) {
        //console.log("params: ", params);
        const modal = this.modal.userMemo;
        modal.params.type = type;
        modal.params.item = schedule;
        if (type === "add") {
          modal.params.enableDeleteButton = false;
        } else {
          modal.params.enableDeleteButton = true;
          if (schedule.userMemo) {
            modal.params.text = schedule.userMemo.memo;
          }
        }
        modal.visible = true;
      }
    },
    showSortModal() {
      const modal = this.modal.sort;
      modal.params.sort = this.sort;
      modal.visible = true;
    },
    showListViewTypeModal() {
      const modal = this.modal.listViewType;
      modal.params.listViewType = this.listViewType;
      modal.visible = true;
    },
    showAddPage() {
      const query = {
        viewType: this.viewType,
        startDate: this.date,
        endDate: this.date,
      } as any;

      if (this.isNotBlank(this.$route.query.categoryId)) {
        query.categoryId = this.$route.query.categoryId;
      } else if (this.isNotBlank(this.$route.query.categoryIdList)) {
        const categoryIdList = String(this.$route.query.categoryIdList).split(",");
        if (categoryIdList.length === 1) {
          query.categoryId = categoryIdList[0];
        }
      }

      if (this.isNotBlank(this.$route.query.teamId)) {
        query.teamId = this.$route.query.teamId;
      }

      if (this.isNotBlank(this.$route.query.estimateId)) {
        query.estimateId = this.$route.query.estimateId;
      }

      this.$router.push({
        path: "/schedule/add",
        query: query,
      });
    },
    showViewPage(item: any) {
      if (item.isTemp) {
        this.$router.push({ path: "/schedule/temp/" + item.id });
      } else {
        const query = {
          viewType: this.viewType,
        } as any;
        if (this.isNotBlank(this.$route.query.categoryId)) {
          query.categoryId = this.$route.query.categoryId;
        }
        if (this.isNotBlank(this.$route.query.teamId)) {
          query.teamId = this.$route.query.teamId;
        }
        this.$router.push({ path: "/schedule/" + item.id, query: query });
      }
    },
    textToHtml(text) {
      return core.utils.format.textToHtml(text);
    },
    async getScheduleList() {
      core.loader.show();

      const scheduleList = (await ScheduleService.getSearchColumnsAndViewType(
        this.searchColumns,
        this.filterViewType
      )) as any;

      const issueList = (await ScheduleService.getIssueList(
        this.searchColumns.startDate,
        this.searchColumns.endDate
      )) as any;
      //console.log(this.date);
      issueList.forEach((issue) => {
        if (issue.date == this.date) {
          this.issue = true;
        }
      });

      // console.log("userMemoList : ", userMemoList);

      if (
        this.searchColumns.workType != "V" &&
        (this.searchColumns.workTypeList == null ||
          this.searchColumns.workTypeList.indexOf("V") === -1)
      ) {
        if (this.isCompanyManagerRoleHigher) {
          const vacationScheduleList = (await ScheduleService.getSearchColumnsAndViewType(
            {
              workType: "V",
              startDate: this.searchColumns.startDate,
              endDate: this.searchColumns.endDate,
            },
            this.filterViewType
          )) as any;
          //console.log("vacationScheduleList : ", vacationScheduleList);
          vacationScheduleList.forEach((schedule) => {
            scheduleList.push(schedule);
          });
          await ScheduleService.convertScheduleList(this.searchColumns, scheduleList);
        } else {
          const teamUserMap = new Map();
          for (const team of this.myUser.company.teamList) {
            const teamUserList = (await TeamService.getUserList(team.id)) as any;
            teamUserList.forEach((user) => {
              teamUserMap.set(user.id, user);
            });
          }
          const vacationScheduleList = (await ScheduleService.getSearchColumnsAndViewType(
            {
              workType: "V",
              startDate: this.searchColumns.startDate,
              endDate: this.searchColumns.endDate,
            },
            this.filterViewType
          )) as any;
          //console.log("vacationScheduleList : ", vacationScheduleList);
          vacationScheduleList.forEach((schedule) => {
            if (schedule.userList != null) {
              let addSchedule = false;
              schedule.userList.forEach((user) => {
                if (teamUserMap.has(user.id)) {
                  addSchedule = true;
                }
              });
              if (addSchedule) {
                scheduleList.push(schedule);
              }
            }
          });
          await ScheduleService.convertScheduleList(this.searchColumns, scheduleList);
        }
      }
      let totalPrice = 0;
      scheduleList.forEach((schedule: any) => {
        totalPrice += schedule.totalPrice as number;
        if (this.listViewType === "default") {
          schedule.showMainTitle = false;
        }
      });

      const userMemoMap = {} as any;
      const userMemoList = (await ScheduleUserMemoService.getByDate(this.date)) as any;
      userMemoList.forEach((userMemo) => {
        userMemoMap[userMemo.userId] = userMemo;
      });
      // this.userMemoList = userMemoList;
      scheduleList.forEach((schedule) => {
        if (schedule.mainUserId) {
          const userId = Number(schedule.mainUserId);
          // console.log("schedule userId : ", userId);
          schedule.userMemo = userMemoMap[userId];
        }
      });
      this.scheduleList = scheduleList;
      this.totalPrice = totalPrice;
      core.loader.hide();
    },
    drawScheduleList() {
      let orderColumnName = undefined as string | undefined;
      let order = undefined as string | undefined;
      const sort = this.sort;
      if (sort != null) {
        orderColumnName = sort.orderColumnName;
        order = sort.order;
      }
      const listViewType = this.listViewType;
      ScheduleService.sortScheduleList(
        this.searchColumns,
        this.scheduleList,
        orderColumnName,
        order,
        listViewType
      );
    },
  },
});
