import moment from "moment";
import core from "@/core";

export enum EstimateMoreViewType {
  WAIT_PAYMENT = "waitPayment", // 결제 대기
  OLD_WAIT_PAYMENT = "oldWaitPayment", // 결제 대기
  ISSUE = "issue", // 이슈견적
  WAIT_CONTRACT = "waitContract", // 계약 대기
  EXPECTED_REMAINING_DATE = "expectedRemainingDate", // 남은 예정일(30일),
  LAST_SCHEDULED_DATE = "lastScheduledDate", // 지난 예정일
  WORK_STATUS = "workStatus", // 일정 미정
}

class EstimateUtils {
  readonly moreViewTypeList = [] as string[];
  readonly moreViewTypeTableMap = {} as any;

  constructor() {
    for (const key in EstimateMoreViewType) {
      const value = EstimateMoreViewType[key];
      this.moreViewTypeList.push(value);
    }

    {
      // 결제대기 견적 목록
      this.moreViewTypeTableMap[EstimateMoreViewType.WAIT_PAYMENT] = {
        title: "결제 대기중 견적",
        content: "모든 작업 일정이 완료되어, 결제를 대기하고 있는 견적 목록입니다.",
        orderColumnName: "updatedAt",
        order: "desc",
        searchColumns: {
          contractStatus: "COMPLETE",
          paymentYn: "N",
          workStatus: "C",
          updatedAtGreaterThanEqual: moment().add(-30, "days").format("YYYY-MM-DD"),
        },
      };
    }

    {
      // 결제대기 견적 목록, 30일 이상 경과된 결제 대기 목록
      this.moreViewTypeTableMap[EstimateMoreViewType.OLD_WAIT_PAYMENT] = {
        title: "오래된 결제 대기중 견적",
        content: "30일 이상 결제를 대기하고 있는 견적 목록입니다.",
        orderColumnName: "updatedAt",
        order: "desc",
        searchColumns: {
          contractStatus: "COMPLETE",
          paymentYn: "N",
          workStatus: "C",
          updatedAtLessThan: moment().add(-30, "days").format("YYYY-MM-DD"),
        },
      };
    }

    {
      // 이슈 견적 목록
      this.moreViewTypeTableMap[EstimateMoreViewType.ISSUE] = {
        title: "이슈 견적",
        orderColumnName: "updatedAt",
        order: "desc",
        searchColumns: {
          issue: true,
        },
      };
    }
    {
      // 계약대기 견적 목록
      this.moreViewTypeTableMap[EstimateMoreViewType.WAIT_CONTRACT] = {
        title: "계약 대기중 견적",
        orderColumnName: "updatedAt",
        order: "desc",
        searchColumns: {
          contractStatus: "WAIT",
        },
      };
    }

    {
      // 남은 입주 예정일(30일) 견적 목록
      this.moreViewTypeTableMap[EstimateMoreViewType.EXPECTED_REMAINING_DATE] = {
        title: "입주예정일 30일 이내 견적",
        content: "30일내에 입주 예정이며, 일정 관리 필요한 목록입니다.",
        orderColumnName: "expectedDate",
        order: "asc",
        searchColumns: {
          contractStatus: "COMPLETE",
          scheduleYn: "N",
          expectedDateGreaterThan: moment().format("YYYY-MM-DD"),
          expectedDateLessThan: moment().add(30, "days").format("YYYY-MM-DD"),
        },
      };
    }

    {
      // 지난 입주 예정일 견적 목록
      this.moreViewTypeTableMap[EstimateMoreViewType.LAST_SCHEDULED_DATE] = {
        title: "입주예정일이 지난 견적",
        content: "입주예정일이 지난 견적이며, 확인이 필요한 목록입니다.",
        orderColumnName: "expectedDate",
        order: "asc",
        searchColumns: {
          scheduleYn: "N",
          contractStatus: "COMPLETE",
          expectedDateLessThan: moment().add(1, "days").format("YYYY-MM-DD"),
        },
      };
    }

    {
      // 일정 하나라도 존재하면서 일정 미정인 견적 목록
      this.moreViewTypeTableMap[EstimateMoreViewType.WORK_STATUS] = {
        title: "일정 미정 견적",
        content: "일정이 정해지지 않은 견적이며, 확인이 필요한 목록입니다.",
        orderColumnName: "updatedAt",
        order: "desc",
        searchColumns: {
          contractStatus: "COMPLETE",
          expectedDateIsNull: true,
          scheduleYn: "N",
        },
      };
    }
    // console.log("estimateMoreViewTypeList : ", this.moreViewTypeList);
    // console.log("this.moreViewTypeTableMap : ", this.moreViewTypeTableMap);
  }

  getMoreViewType(viewType: string): EstimateMoreViewType | null {
    for (const value of this.moreViewTypeList) {
      if (value === viewType) {
        return value as EstimateMoreViewType;
      }
    }
    return null;
  }

  getTableParams(viewType: string): any {
    return this.moreViewTypeTableMap[viewType];
  }

  contractStatusToHtml(estimate: any) {
    let html = "";
    const value = estimate.contractStatus;
    if (value === "WAIT") html = "<span class='red--text'>계약 대기</span>";
    else if (value === "COMPLETE") html = "<span class='green--text'>계약 완료</span>";
    else if (value === "CANCEL") html = "<span class='black--text'>계약 취소</span>";
    else if (value === "SPECIAL_PRICE") html = "<span class='orange--text'>특가표</span>";
    else if (value === "TEMPORARY") html = "<span class='orange--text'>임시 계약</span>";
    else html = "알 수 없음";

    return html;
  }

  contractStatusToText(estimate: any) {
    let text = "";
    const value = estimate.contractStatus;
    if (value === "WAIT") text = "계약 대기";
    else if (value === "COMPLETE") text = "계약 완료";
    else if (value === "CANCEL") text = "계약 취소";
    else if (value === "SPECIAL_PRICE") text = "특가표";
    else if (value === "TEMPORARY") text = "임시 계약";
    else text = "알 수 없음";

    return text;
  }

  scheduleToHtml(estimate: any) {
    if (estimate.contractStatus === "COMPLETE") {
      if (estimate.scheduleYn === "Y") {
        return "<span class='green--text'>일정 확정</span>";
      } else {
        return "<span class='orange--text'>일정 미정</span>";
      }
    }
    return "";
  }

  titleToHtml(estimate: any) {
    let title = estimate.place.name;
    if (core.utils.validate.isNotBlank(estimate.dong)) {
      title += ` ${estimate.dong}동`;
    }
    if (core.utils.validate.isNotBlank(estimate.ho)) {
      title += ` ${estimate.ho}호`;
    }
    return title;
  }
}

export default new EstimateUtils();
