
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll from "@/mixins/single/scroll";
import { AppBarMenuItem, UpdateEventType } from "@/types";
import core from "@/core";
import EstimateExpectedDateModal from "@/modals/estimate/EstimateExpectedDateModal.vue";
import UpdateEvent from "@/models";
import EstimateService from "@/services/work/estimate.service";
import EstimateUtils, { EstimateMoreViewType } from "@/models/estimate";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";

export default mixins(MixinsPage, MixinsTable, MixinsScroll, MixinsAppBarMenu).extend({
  name: "DashboardEstimateMore",
  components: { EstimateExpectedDateModal },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: ["selected"],
      firstLoading: true,
      loading: false,
    },
    title: "",
    viewType: "",
    visible: {
      dDay: false,
      lastPaymentSms: false,
    },
    content: null as any,
    modal: {
      estimateExpectedDate: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    listGroup: null as any,
    edit: {
      visible: false,
      date: null as any,
      content: "",
    },
  }),
  mounted() {
    this.initTable({
      service: EstimateService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const viewType = EstimateUtils.getMoreViewType(this.$route.query.viewType as string);
      if (viewType == null) {
        await this.notFound();
        return;
      }
      this.viewType = viewType;

      const tableParams = EstimateUtils.getTableParams(viewType);

      this.title = tableParams.title;
      this.table.request.order = tableParams.order;
      this.table.request.orderColumnName = tableParams.orderColumnName;
      this.table.request.searchColumns = tableParams.searchColumns;
      if (viewType === EstimateMoreViewType.EXPECTED_REMAINING_DATE) {
        this.visible.dDay = true;
      } else if (viewType === EstimateMoreViewType.WAIT_PAYMENT) {
        this.visible.lastPaymentSms = true;
      }
      this.content = tableParams.content;

      if (
        viewType === EstimateMoreViewType.WORK_STATUS ||
        viewType == EstimateMoreViewType.EXPECTED_REMAINING_DATE ||
        viewType == EstimateMoreViewType.LAST_SCHEDULED_DATE
      ) {
        this.edit.visible = true;
      }

      this.changedTopButton();

      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "modal.estimateExpectedDate.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.estimateExpectedDate);
      if (event?.result === UpdateEventType.CONFIRM) {
        const date = event?.item;
        this.edit.date = event?.item;
        this.edit.content = `선택된 견적서의 입주예정일을 "${this.edit.date}" 로 변경합니다.`;
        console.log("event : ", event);
      }
    },
    "table.response.data"(list) {
      if (list != null) {
        list.forEach((data) => {
          if (data.selected == null) {
            data.selected = false;
          }
        });
      }
      console.log("list : ", list);
    },
    "edit.date"() {
      this.changedTopButton();
    },
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
  },
  async activated() {
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (
        event.result === UpdateEventType.RELOAD ||
        event.result === UpdateEventType.UPDATE ||
        event.result === UpdateEventType.DELETE
      ) {
        this.reloadTable();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } else {
        console.log("unknown result : ", event.result);
      }
    }
  },
  methods: {
    appBarMenuEvent(menu: AppBarMenuItem) {
      console.log("appBarMenuEvent : ", menu);
      if (menu.id === "edit") {
        this.showEstimateExpectedDateModal();
      } else if (menu.id === "cancel") {
        this.cancel();
      } else if (menu.id === "complete") {
        this.submit();
      } else if (menu.id === "download") {
        console.log("download excel");
        this.downloadExcel();
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    changedTopButton() {
      if (this.edit.visible) {
        if (this.edit.date == null) {
          this.appBarChangeMenuVisible("edit", true);
          this.appBarChangeMenuVisible("cancel", false);
          this.appBarChangeMenuVisible("complete", false);
          this.appBarChangeMenuVisible("download", true);
        } else {
          this.appBarChangeMenuVisible("edit", false);
          this.appBarChangeMenuVisible("cancel", true);
          this.appBarChangeMenuVisible("complete", true);
          this.appBarChangeMenuVisible("download", false);
        }
      } else {
        this.appBarChangeMenuVisible("edit", false);
        this.appBarChangeMenuVisible("cancel", false);
        this.appBarChangeMenuVisible("complete", false);
        this.appBarChangeMenuVisible("download", true);
      }
    },
    cancel() {
      this.edit.date = null;
      this.tableDataList.forEach((data) => {
        data.selected = false;
      });
    },
    async downloadExcel() {
      const searchColumns = this.table.request.searchColumns;

      // const params = {} as any;
      core.loader.show("파일 생성중...");

      try {
        console.log("searchColumns : ", searchColumns);
        const response = (await EstimateService.downloadExcel(searchColumns)) as any;
        // response.downloadUri;
        //console.log("response : ", response);
        core.loader.show("다운로드중...");
        await core.http.downloadFile(response.downloadUri);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async submit() {
      core.loader.show("입주예정일 변경중...");
      const dataList = this.tableDataList;
      const length = dataList.length;
      for (let i = 0; i < length; i++) {
        const data = dataList[i];
        if (data.selected) {
          try {
            const estimate = await EstimateService.updateExpectedDate(data.id, this.edit.date);
            core.utils.copy(estimate, data);
          } catch (e) {
            console.log(e);
          } finally {
            data.selected = false;
          }
        }
      }
      this.edit.date = null;
      this.clearTable();
      this.reloadTable();
      core.loader.hide();
    },
    showEstimateExpectedDateModal() {
      const modal = this.modal.estimateExpectedDate;
      modal.visible = true;
    },
    getRemainingDays(estimate: any) {
      const curDate = core.date.instance();
      const expectedDate = core.date.instance(estimate.expectedDate);
      return expectedDate.diff(curDate, "days");
    },
    showViewPage(estimate: any) {
      if (this.edit.date == null) {
        this.$router.push({ path: `/estimate/${estimate.id}` });
      } else {
        estimate.selected = !estimate.selected;
        console.log("estimate : ", estimate);
        console.log(this.listGroup);
      }
    },
    scheduleText(yn: string) {
      if (yn === "Y") {
        return "<span class='green--text'>일정 확정</span>";
      } else {
        return "<span class='orange--text'>일정 미정</span>";
      }
    },
    contractStatusText(value: string) {
      if (value === "WAIT") return "<span class='red--text'>계약 대기</span>";
      else if (value === "COMPLETE") return "<span class='green--text'>계약 완료</span>";
      else if (value === "CANCEL") return "<span class='black--text'>계약 취소</span>";
      return "알 수 없음";
    },
  },
});
