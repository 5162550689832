import core from "@/core";
import { TableRequest } from "@/types";

class UnitPriceService {
  get(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/unit-price/${id}`, null)
        .then((data: any) => {
          if (data.unitPrice != null) {
            resolve(data.unitPrice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getTable(params: TableRequest) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/unit-price/table`, null, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  create(params) {
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .postJson(`/api/unit-price`, strJson)
        .then((data: any) => {
          if (data.unitPrice != null) {
            resolve(data.unitPrice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  update(id: number, params) {
    return new Promise((resolve, reject) => {
      const strJson = JSON.stringify(params);
      core.http
        .putJson(`/api/unit-price/${id}`, strJson)
        .then((data: any) => {
          if (data.unitPrice != null) {
            resolve(data.unitPrice);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  delete(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .delete(`/api/unit-price/${id}`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getByPlaceId(id: number, ignoreAlert?: boolean): Promise<unknown> {
    if (ignoreAlert == undefined) {
      ignoreAlert = false;
    }
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/unit-price/place/${id}`, { ignoreAlertModal: ignoreAlert })
        .then((data: any) => {
          if (typeof data === "object") {
            const item = data["unitPrice"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  convertExcel(formData: FormData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/unit-price/convert-excel`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  convertExcelV2(formData: FormData) {
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/unit-price/convert-excel-v2`, formData, {
          headers: core.http.headers.form,
        })
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new UnitPriceService();
