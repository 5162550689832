
import StatusService from "@/services/work/status.service";
import core from "@/core";
import ScheduleViewInfo from "@/components/schedule/ScheduleViewInfo.vue";
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsComponent from "@/mixins/component";
import ScheduleService from "@/services/work/schedule.service";
import ScheduleUtils from "@/models/schedule";
import { TableRequest, UpdateEventType } from "@/types";
import UpdateEvent from "@/models";
import TransactionAddEditModal from "@/modals/inventory/TransactionAddEditModal.vue";
import ProductService from "@/services/inventory/product.service";
import TransactionService from "@/services/inventory/transaction.service";
import WorkScopeConfirmModal from "@/modals/schedule/WorkScopeConfirmModal.vue";

export default mixins(MixinsComponent, MixinsPage).extend({
  name: "ScheduleStatusComponent",
  components: {
    WorkScopeConfirmModal,
    TransactionAddEditModal,
    ScheduleViewInfo,
  },
  props: {
    schedule: {
      type: Object,
      default: () => {
        return {
          category: {},
        };
      },
    },
  },
  data: () => ({
    formProcessing: false,
    updateViewType: null as string | null,
    step: 1,
    status: {
      before: null,
      after: null,
      defect: null,
    },
    visible: {
      complete: false,
    },
    inventoryTransactionList: [] as any,
    modal: {
      workScopeConfirm: {
        visible: false,
        params: {
          checkList: [] as string[],
        },
        updateEvent: null as UpdateEvent | null,
      },
      addStatus: {
        visible: false,
        params: {
          type: "B",
          scheduleId: 0,
          estimateId: 0,
        },
        watchClose: {
          result: "",
        },
      },
      viewStatus: {
        visible: false,
        params: {
          type: "B",
          statusId: 0,
        },
        watchClose: {
          result: "",
        },
      },
      inventoryTransaction: {
        visible: false,
        params: {
          type: "",
          productList: [] as any,
          transactionType: "",
          item: null as any,
          scheduleId: null as any,
          estimateId: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(() => {
      this.getStatusList();
      this.getInventoryTransactionList();
    });
  },
  watch: {
    "modal.workScopeConfirm.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.workScopeConfirm);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          this.updateScheduleComplete(true);
        }
      }
    },
    async "properties.updateEvent"() {
      // 이벤트 발생
      const event = this.getPropsUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.RELOAD) {
          await this.getStatusList();
          const path = event.path;
          console.log("path : ", path);
          if (this.status.after != null && path === "/status/add") {
            this.showWorkScopeConfirmModal();
          }
        }
      }
    },
    "modal.addStatus.watchClose"() {
      const result = this.modal.addStatus.watchClose.result;
      if (result === "confirm") {
        this.getStatusList();
      }
    },
    "modal.inventoryTransaction.updateEvent"() {
      const modal = this.modal.inventoryTransaction;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          const item = event.item;
          if (modal.params.type === "add") {
            this.inventoryTransactionList.push(item);
            this.inventoryTransactionList.sort((a: any, b: any) => {
              const aMoment = core.date.instance(a.createdAt);
              const bMoment = core.date.instance(b.createdAt);

              const aTime = aMoment.toDate().getTime();
              const bTime = bMoment.toDate().getTime();

              if (aTime < bTime) return -1;
              else if (aTime > bTime) return 1;
              return 0;
            });
          } else {
            this.inventoryTransactionList.some((transaction) => {
              if (transaction.id === item.id) {
                core.utils.copy(item, transaction);
                return true;
              }
            });
          }

          // modal.params.
        } else if (event.result === UpdateEventType.DELETE) {
          // console.log("result : ", event.result);
          const item = event.item;
          this.inventoryTransactionList.some((transaction, index) => {
            if (transaction.id === item.id) {
              this.inventoryTransactionList.splice(index, 1);
              return true;
            }
          });
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    showWorkScopeConfirmModal() {
      // updateScheduleComplete(true)
      // 작업범위 모달 추가
      const modal = this.modal.workScopeConfirm;
      const schedule = this.schedule;
      const checkList = [] as string[];
      schedule.detailList.forEach((detail) => {
        checkList.push(detail.workScope);
      });
      if (checkList.length > 0) {
        modal.params.checkList = checkList;
        modal.visible = true;
      } else {
        this.updateScheduleComplete(true);
      }
    },
    getProductName(item) {
      let name = "";
      const modal = this.modal.inventoryTransaction;
      modal.params.productList.some((product) => {
        if (item.productId === product.id) {
          name = product.name;
          return true;
        }
      });
      return name;
    },
    async getInventoryTransactionList() {
      const modal = this.modal.inventoryTransaction;
      if (modal.params.productList.length == 0) {
        modal.params.productList = await this.getProductList();
      }
      const request = {
        draw: 0,
        start: 0,
        length: 1000,
        orderColumnName: "createdAt",
        order: "desc",
        searchColumns: {
          type: "OUTGOING",
          scheduleId: this.schedule.id,
        } as any,
      } as TableRequest;
      const response = (await TransactionService.getTable(request)) as any;
      // console.log("response : ", response);
      this.inventoryTransactionList = response.data;
      // console.log("inventoryTransactionList : ", this.inventoryTransactionList);
    },
    async showInventoryTransactionModal() {
      // console.log("show inventory transaction modal");
      const modal = this.modal.inventoryTransaction;
      modal.params.type = "add";
      modal.params.transactionType = "OUTGOING";
      modal.params.item = null;
      modal.params.scheduleId = this.schedule.id;
      modal.params.estimateId = this.schedule.estimateId;
      modal.visible = true;
    },
    async showEditInventoryTransactionModal(item) {
      // console.log("show inventory transaction modal");
      const modal = this.modal.inventoryTransaction;
      modal.params.type = "edit";
      modal.params.transactionType = "OUTGOING";
      modal.params.item = item;
      modal.params.scheduleId = this.schedule.id;
      modal.params.estimateId = this.schedule.estimateId;
      modal.visible = true;
    },
    async getProductList() {
      const request = {
        draw: 0,
        start: 0,
        length: 1000,
        orderColumnName: "name",
        order: "asc",
        searchColumns: {} as any,
      } as TableRequest;
      const response = (await ProductService.getTable(request)) as any;
      return response.data;
    },
    async updateScheduleComplete(complete: boolean) {
      try {
        if (complete) {
          if (this.status.after == null) {
            const result = await core.alert.show({
              title: "확인",
              body: "시공 사진 없이 완료 처리 하시겠습니까?",
              showCancelButton: true,
              confirmButtonText: "완료",
              cancelButtonText: "취소",
            });
            if (result !== "confirm") {
              return;
            }
          }
        } else if (this.schedule.complete) {
          const result = await core.alert.show({
            title: "확인",
            body: "시공이 <span class='green--text'>완료</span>된 상태입니다.<br>시공 완료를 <span class='red--text'>취소</span>하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "예",
            cancelButtonText: "아니오",
          });
          if (result !== "confirm") {
            return;
          }
        }

        core.loader.show();
        const schedule = await ScheduleService.updateScheduleComplete(this.schedule.id, complete);
        core.utils.copy(schedule, this.schedule);
        // 이전 페이지 이벤트 전송
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async getStatusList() {
      // 일정 상태 정보 조회
      //console.log("schedule : ", this.schedule);
      if (ScheduleUtils.isStartAtBeforeToday(this.schedule)) {
        this.visible.complete = true;
      }

      const statusList: any = await StatusService.getStatusListByScheduleId(this.schedule.id);
      statusList.forEach((status: any) => {
        if (status.type === "B") {
          if (this.step < 2) this.step = 2;
          this.status.before = status;
        } else if (status.type === "A") {
          if (this.step < 3) this.step = 3;
          this.status.after = status;
        } else if (status.type === "F") {
          this.status.defect = status;
        }
      });
      this.updateViewType = "statusList";
      if (this.status.after != null && !this.schedule.complete) {
        const schedule = await ScheduleService.get(this.schedule.id);
        core.utils.copy(schedule, this.schedule);
      }
    },
    showAddModal(type: string) {
      console.log("showAddModal : ", type);
      // this.step++;
      if (type === "B" || type === "A" || type === "F") {
        // 시공전, 시공후 사진 등록
        const query = {
          type: type,
          scheduleId: this.schedule.id,
        };
        this.$router.push({ path: "/status/add", query: query });
        // this.modal.addStatus.params.type = type;
        // this.modal.addStatus.params.scheduleId = this.schedule.id;
        // this.modal.addStatus.params.estimateId = this.schedule.estimate.id;
        // this.modal.addStatus.visible = true;
      } else {
        console.log("Unknown type : ", type);
      }
    },
    showViewPage(type: string) {
      //console.log("showViewPage : ", type);
      if (type === "B" || type === "A" || type === "F") {
        let status: any = null;
        if (type === "F") status = this.status.defect;
        else if (type === "B") status = this.status.before;
        else status = this.status.after;

        this.$router.push({ path: `/status/${status.id}` });

        // // 시공전, 시공후 사진 등록
        // this.modal.viewStatus.params.type = type;
        // this.modal.viewStatus.params.statusId = status.id;
        // this.modal.viewStatus.visible = true;
        // console.log(this.modal.viewStatus);
      } else {
        console.log("Unknown type : ", type);
      }
    },

    fileSizeFormat(value: string) {
      return core.utils.format.filesize(true, parseInt(value), 2);
    },
  },
});
