import CoreHttp from "@/core/core-http";
import CoreUtils from "@/core/core-utils";
import CoreDate from "@/core/core-date";
import CoreLoader from "@/core/core-loader";
import CoreAlert from "@/core/core-alert";
import CoreMobile from "@/core/core-mobile";
import CoreWebSocket from "@/core/core-web-socket";
import CoreLocation from "@/core/core-location";
import CoreToast from "@/core/core-toast";
import CorePreLoader from "@/core/core-preloader";

class Core {
  http: CoreHttp;
  utils: CoreUtils;
  date: CoreDate;
  preloader: CorePreLoader;
  loader: CoreLoader;
  location: CoreLocation;
  alert: CoreAlert;
  toast: CoreToast;
  mobile: CoreMobile;
  webSocket: CoreWebSocket;

  init(store, router, toast) {
    this.preloader = new CorePreLoader(
      "linear-gradient(90deg,#151515 0%,#2ec2cd 50%,#151515 100%)"
    );
    this.loader = new CoreLoader();
    this.mobile = new CoreMobile();
    this.alert = new CoreAlert(store.state.app.modal.alert, this.loader);
    this.toast = new CoreToast(toast);

    this.http = new CoreHttp(
      [
        {
          key: "x-scc-user-role",
          value: "manager",
        },
      ],
      true,
      store,
      router,
      this.alert,
      this.mobile
    );
    this.utils = new CoreUtils();
    this.location = new CoreLocation(this.alert);
    this.date = new CoreDate("ko");
    this.webSocket = new CoreWebSocket(store);
  }
}

export default new Core();
