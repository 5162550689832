
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import store from "@/store";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import CoreOpenlayersImageMap, {
  DrawingType,
  FeatureType,
  PolygonParam,
} from "@/core/core-openlayers-image-map";
import { Feature } from "ol";
import { UpdateEventType } from "@/types";
import { Chrome } from "vue-color";
import SelectColorModal from "@/modals/core/SelectColorModal.vue";
import UpdateEvent from "@/models";
import core from "@/core";
import TextFieldModal, { TextType } from "@/modals/core/TextFieldModal.vue";
import {
  DrawImageRequest,
  DrawPolygon,
  DrawText,
  Position,
} from "@/models/floor-plan/floor-plan.model";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    eventName: "이벤트명 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "DrawImageModal",
  components: {
    TextFieldModal,
    SelectColorModal,
    "chrome-picker": Chrome,
  },
  data: () => ({
    style: {
      image: "max-width: 100%",
    },
    app: store.state.app,
    openLayersMap: null as CoreOpenlayersImageMap | null,
    selectedPolygon: null as any,
    drawing: false,
    featureList: [] as Feature[],
    changedFeature: false,
    modal: {
      color: {
        params: {
          title: "색상 선택",
          color: null,
        },
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      text: {
        visible: false,
        params: {
          textType: TextType.TEXT,
          length: {
            min: 1,
            max: 100,
          },
          type: "edit",
          title: "텍스트",
          enableDeleteButton: true,
          text: "",
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  watch: {
    async "modal.color.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.color);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const color = event.item;
          if (color != null) {
            const confirmResult = await core.alert.show({
              title: "확인",
              body: "선택된 항목 색상을 변경하시겠습니까?",
              showCancelButton: true,
              confirmButtonText: "예",
              cancelButtonText: "아니오",
            });
            if (confirmResult === "confirm") {
              // console.log("color : ", color);
              const openLayersMap = this.openLayersMap;
              if (openLayersMap != null) {
                openLayersMap.updatePolygonColor(this.selectedPolygon, color.hex8);
                openLayersMap.deselectFeature();
                this.selectedPolygon = null;
              }
            }
          }
        }
      }
    },
    async "modal.text.visible"(visible) {
      const modal = this.modal.text;
      if (!visible && modal.updateEvent == null && modal.params.type === "add") {
        // console.log("text visible : ", visible);
        const feature = this.modal.text.params.item;
        if (feature != null) {
          const openLayersMap = this.openLayersMap;
          if (openLayersMap != null) {
            openLayersMap.removeFeature(feature);
          }
        }
      }
    },
    async "modal.text.updateEvent"() {
      const modal = this.modal.text;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        // console.log("event : ", event);
        const feature = modal.params.item;
        modal.params.item = null;

        if (event.result === UpdateEventType.CONFIRM) {
          let text = event.item;
          if (text != null && text.length < 1) {
            text = " ";
          }
          // console.log("text : ", text);
          if (feature != null) {
            const openLayersMap = this.openLayersMap;
            if (openLayersMap != null) {
              this.changedFeature = true;
              openLayersMap.updateText(feature, text);
              if (modal.params.type === "add") {
                this.featureList.push(feature);
                // console.log("featureList : ", this.featureList);
              }
            }
          }
        } else if (event.result === UpdateEventType.DELETE) {
          if (feature != null) {
            const openLayersMap = this.openLayersMap;
            if (openLayersMap != null) {
              this.changedFeature = true;
              openLayersMap.removeFeature(feature);
              this.featureList.some((_feature, index) => {
                if (feature === _feature) {
                  this.featureList.splice(index, 1);
                  return true;
                }
              });
            }
          }
        }
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      if (!this.app.isMobile) {
        if (this.app.size.width > 700) {
          this.style.image = `max-width: 50%`;
        }
      }

      const params = this.properties.params;

      const image = new Image();
      image.src = params.dataUri;
      image.onload = () => {
        let width = image.width;
        let height = image.height;
        // console.log("width : ", width, ", height : ", height);

        const imageDetail = params.item;
        //console.log("imageDetail : ", imageDetail);
        this.initMap({
          uri: params.dataUri,
          width: width,
          height: height,
          ol: imageDetail.ol,
        });
      };
    });
  },
  methods: {
    initMap(item: any) {
      // let width = item.width;
      // if (this.app.size.width < width) {
      //   width = this.app.size.width;
      // }
      const width = item.width;
      const height = item.height;

      let zoom = 1;
      if (width > height) {
        const bodyWidth = (this.$refs.body as any).clientWidth - 24;
        // console.log("bodyWidth : ", bodyWidth);
        zoom = bodyWidth / width;
        // console.log("zoom : ", zoom);
      } else {
        zoom = 400 / height;
      }
      zoom = zoom - zoom / 5;
      if (zoom > 0.6) {
        zoom = 0.6;
      }
      console.log("zoom : ", zoom);

      const openLayersMap = (this.openLayersMap = new CoreOpenlayersImageMap({
        map: {
          element: this.$refs.map,
          image: {
            url: item.uri,
            size: {
              width: item.width,
              height: item.height,
            },
          },
          zoom: zoom,
          minZoom: 0,
          maxZoom: 10,
          enable: {
            rotate: false,
            zoom: true,
          },
        },
        callback: {
          click: this.mapClickEvent,
        },
      }));

      const ol = item.ol;
      if (ol != null) {
        if (ol.polygonList != null) {
          // console.log("item : ", item);
          ol.polygonList.forEach((polygon: DrawPolygon) => {
            this.addPolygon({
              name: "_" + item.id,
              color: polygon.color,
              positionList: polygon.positionList,
            });
          });
        }
        if (ol.textList != null) {
          ol.textList.forEach((text: DrawText) => {
            this.addText(text.text, text.position);
          });
        }
      }
      // if (item.x && item.y) {
      //   this.marker = openLayersMap.addMarker({
      //     name: "position",
      //     position: {
      //       x: item.x,
      //       y: item.y,
      //     },
      //   });
      // }
      // openLayersMap.addText(" ", { x: 10, y: 10 });
    },
    addPolygon(param: PolygonParam) {
      const openLayersMap = this.openLayersMap;
      if (openLayersMap != null) {
        const feature = openLayersMap.addPolygon({
          name: param.name,
          color: param.color,
          positionList: param.positionList,
        });
        this.featureList.push(feature);
      }
    },
    addText(text: string, position: Position) {
      const openLayersMap = this.openLayersMap;
      if (openLayersMap != null) {
        const feature = openLayersMap.addText(text, position);
        this.featureList.push(feature);
      }
    },
    changeColor() {
      // console.log("change color");
      const modal = this.modal.color;
      if (this.selectedPolygon != null) {
        modal.params.color = this.selectedPolygon.hex8Color;
      }
      modal.visible = true;
    },
    removePolygon() {
      const openLayersMap = this.openLayersMap;
      if (openLayersMap != null) {
        const selectedPolygon = this.selectedPolygon;
        if (selectedPolygon != null) {
          this.featureList.some((feature, index) => {
            if (feature === this.selectedPolygon) {
              this.featureList.splice(index, 1);
              return true;
            }
          });
          openLayersMap.removeFeature(this.selectedPolygon);
          this.selectedPolygon = null;
          this.changedFeature = true;
        }
      }
    },
    drawText() {
      const openLayersMap = this.openLayersMap;
      if (openLayersMap != null) {
        this.drawing = true;
        openLayersMap.startDrawing(DrawingType.POINT, (feature) => {
          // console.log("done drawing");
          this.drawing = false;
          openLayersMap.removeFeature(feature);
          const coordinates = feature.getGeometry().getCoordinates();
          // console.log("coordinates : ", coordinates);
          const textFeature = openLayersMap.addText(" ", { x: coordinates[0], y: coordinates[1] });
          const modal = this.modal.text;
          modal.params.type = "add";
          modal.params.text = textFeature.text;
          modal.params.item = textFeature;
          modal.params.enableDeleteButton = false;
          modal.visible = true;
          this.changedFeature = true;
        });
      }
    },
    drawPolygon() {
      // console.log("drawPolygon");
      const openLayersMap = this.openLayersMap;
      if (openLayersMap != null) {
        this.drawing = true;
        openLayersMap.startDrawing(DrawingType.POLYGON, (feature) => {
          // console.log("done drawing");
          this.drawing = false;
          this.featureList.push(feature);
          this.changedFeature = true;
        });
      }
    },
    stopDrawing() {
      const openLayersMap = this.openLayersMap;
      if (openLayersMap != null) {
        openLayersMap.stopDrawing();
        this.drawing = false;
      }
    },
    mapClickEvent(e) {
      const openLayersMap = this.openLayersMap;
      if (openLayersMap) {
        if (openLayersMap.drawing.active) {
          console.log("is drawing");
          return;
        }

        // const latLng = openLayersMap.toLatLng(e.coordinate);
        // const coordinate = toLonLat(e.coordinate);
        // const latLng = { latitude: coordinate[1], longitude: coordinate[0] };
        // console.log("latLng : ", latLng);
        // console.log("lat : " + lat + ", lng : " + lng);
        const pixel = openLayersMap.map.getEventPixel(e.originalEvent);
        const hit = openLayersMap.map.hasFeatureAtPixel(pixel);
        if (hit) {
          const feature = openLayersMap.map.forEachFeatureAtPixel(e.pixel, (feature) => {
            return feature;
          }) as any;

          if (feature == null) {
            return;
          } else {
            if (
              feature.featureType === FeatureType.EDITABLE_POLYGON ||
              feature.featureType === FeatureType.POLYGON
            ) {
              const coordinateList = [] as any;
              const positionList = [] as any;
              const keyMap = {} as any;
              const coordinates = feature.getGeometry().getCoordinates()[0];
              coordinates.forEach((coordinate) => {
                const position = {
                  x: coordinate[0],
                  y: coordinate[1],
                };
                const key = coordinate[0] + "_" + coordinate[1];
                if (!keyMap[key]) {
                  coordinateList.push(coordinate);
                  positionList.push(position);
                  keyMap[key] = coordinate;
                }
              });
              // console.log("positionList : ", positionList);
              // console.log("coordinateList : ", coordinateList);
              if (openLayersMap.isSelectedFeature(feature)) {
                openLayersMap.deselectFeature();
                this.selectedPolygon = null;
              } else {
                openLayersMap.selectFeature(feature);
                this.selectedPolygon = feature;
              }
            } else if (feature.featureType === FeatureType.TEXT) {
              // console.log("feature : ", feature);
              const modal = this.modal.text;
              modal.params.type = "edit";
              modal.params.text = feature.text;
              modal.params.item = feature;
              modal.params.enableDeleteButton = true;
              modal.visible = true;
            } else if (feature.featureType === FeatureType.MARKER) {
              // console.log("feature : ", feature);
              // const marker = feature as Marker;
              // if (marker.selected) {
              //   this.marker.disease.selected = null;
              // } else {
              //   this.marker.disease.selected = marker;
              // }
            }
            // else {
            //   console.log("unknown featureType : ", feature.featureType);
            // }
            //
            // console.log("feature : ", feature);
          }
        }
      }
    },
    // saveImage() {
    //   return new Promise((resolve: any, reject) => {
    //     const openLayersMap = this.openLayersMap;
    //     if (openLayersMap != null) {
    //       const map = openLayersMap.map;
    //       map.once("rendercomplete", function () {
    //         const mapCanvas = document.createElement("canvas");
    //         const size = map.getSize() as any;
    //         mapCanvas.width = size[0];
    //         mapCanvas.height = size[1];
    //         const mapContext = mapCanvas.getContext("2d") as any;
    //         Array.prototype.forEach.call(
    //           map.getViewport().querySelectorAll(".ol-layer canvas, canvas.ol-layer"),
    //           function (canvas) {
    //             if (canvas.width > 0) {
    //               const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
    //               mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
    //               let matrix;
    //               const transform = canvas.style.transform;
    //               if (transform) {
    //                 // Get the transform parameters from the style's transform matrix
    //                 matrix = transform
    //                   .match(/^matrix\(([^\(]*)\)$/)[1]
    //                   .split(",")
    //                   .map(Number);
    //               } else {
    //                 matrix = [
    //                   parseFloat(canvas.style.width) / canvas.width,
    //                   0,
    //                   0,
    //                   parseFloat(canvas.style.height) / canvas.height,
    //                   0,
    //                   0,
    //                 ];
    //               }
    //               // Apply the transform to the export map context
    //               CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
    //               const backgroundColor = canvas.parentNode.style.backgroundColor;
    //               if (backgroundColor) {
    //                 mapContext.fillStyle = backgroundColor;
    //                 mapContext.fillRect(0, 0, canvas.width, canvas.height);
    //               }
    //               mapContext.drawImage(canvas, 0, 0);
    //             }
    //           }
    //         );
    //         mapContext.globalAlpha = 1;
    //         mapContext.setTransform(1, 0, 0, 1, 0, 0);
    //
    //         const dataURI = mapCanvas.toDataURL();
    //         const blob = core.utils.image.dataURItoBlob(dataURI);
    //         // console.log("blob : ", blob);
    //         let ext = "";
    //         if (blob.type == "image/png") {
    //           ext = "png";
    //         } else if (blob.type == "image/jpeg") {
    //           ext = "jpg";
    //         } else if (blob.type == "image/gif") {
    //           ext = "gif";
    //         } else if (blob.type == "image/avif") {
    //           ext = "avif";
    //         } else if (blob.type == "image/apng") {
    //           ext = "apng";
    //         } else if (blob.type == "image/svg+xml") {
    //           ext = "svg";
    //         } else if (blob.type == "image/webp") {
    //           ext = "webp";
    //         }
    //         resolve({ dataUri: dataURI, blob: blob, ext: ext });
    //         // console.log("mapCanvas : ", mapCanvas);
    //         // const link = me.$refs.imageDownload;
    //         // console.log("link : ", link);
    //         // link.href = mapCanvas.toDataURL();
    //         // link.click();
    //       });
    //       map.renderSync();
    //     }
    //   });
    // },
    async submit() {
      // console.log("changedFeature : ", this.changedFeature);
      // // console.log("submit");
      // let image = null as any;
      // if (this.changedFeature) {
      //   image = (await this.saveImage()) as any;
      //   console.log("image : ", image);
      // }

      const params = {
        polygonList: [],
        textList: [],
      } as DrawImageRequest;
      this.featureList.forEach((feature: any) => {
        if (
          feature.featureType === FeatureType.EDITABLE_POLYGON ||
          feature.featureType === FeatureType.POLYGON
        ) {
          const coordinateList = [] as any;
          const positionList = [] as Position[];
          const keyMap = {} as any;
          const coordinates = feature.getGeometry().getCoordinates()[0];
          coordinates.forEach((coordinate) => {
            const position = {
              x: Number(coordinate[0].toFixed(2)),
              y: Number(coordinate[1].toFixed(2)),
            };
            const key = coordinate[0] + "_" + coordinate[1];
            if (!keyMap[key]) {
              coordinateList.push(coordinate);
              positionList.push(position);
              keyMap[key] = coordinate;
            }
          });
          const color = feature.hex8Color;
          // console.log("color : ", color);
          params.polygonList.push({
            color: color,
            positionList: positionList,
          });
        } else if (feature.featureType === FeatureType.TEXT) {
          // console.log("feature : ", feature);
          const coordinates = feature.getGeometry().getCoordinates();
          const position = {
            x: Number(coordinates[0].toFixed(2)),
            y: Number(coordinates[1].toFixed(2)),
          } as Position;
          const text = feature.text;
          // console.log("text : ", text, ", position : ", position);
          params.textList.push({
            text: text,
            position: position,
          });
        }
      });
      // console.log("params : ", params);
      this.close(UpdateEventType.UPDATE, params);
    },
  },
});
