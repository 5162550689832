
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import { cloneDeep } from "lodash";
import TransactionService from "@/services/inventory/transaction.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    name: "제품명",
    productId: "제품",
    quantity: "수량",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "TransactionAddEditModal",
  data: () => ({
    type: "",
    form: {
      productId: "",
      quantity: "",
      date: "",
      scheduleId: null as any,
      estimateId: null as any,
    },
    transactionType: "",
    transactionTypeName: "",
    productList: [] as any,
    datePicker: {
      visible: false,
    },
  }),
  async created() {
    // 생성 후 호출
    const properties = this.properties;
    this.type = properties.params.type;
    this.transactionType = properties.params.transactionType;
    this.transactionTypeName = this.transactionType === "INCOMING" ? "입고" : "출고";
    if (this.type !== "add" && this.type !== "edit") {
      await this.notFound();
    }

    if (this.type === "add") {
      this.form.date = core.date.instance().format("YYYY-MM-DD");
    } else if (this.type === "edit") {
      if (properties.params.item == null || properties.params.item.id == null) {
        await this.notFound();
      }
    }
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const properties = this.properties;
      this.productList = properties.params.productList;
      this.form.scheduleId = properties.params.scheduleId;
      this.form.estimateId = properties.params.estimateId;

      if (this.type === "edit") {
        const item = properties.params.item;
        this.form.productId = item.productId;
        this.form.quantity = item.quantity;
        this.form.date = item.date;
      }
    });
  },
  watch: {
    "form.quantity"(val) {
      this.form.quantity = core.utils.format.onlyNumber(val);
    },
  },
  methods: {
    validationAt() {
      return this.transactionTypeName + "일";
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const form = cloneDeep(this.form) as any;
          form.type = this.transactionType;
          const params = this.properties.params;
          const type = this.type;
          let item = null as any;
          if (type === "add") {
            console.log("type : ", type);
            item = await TransactionService.create(form);
            console.log("create transaction : ", item);
          } else if (type === "edit") {
            console.log("type : ", type);
            item = await TransactionService.update(params.item.id, form);
            console.log("update transaction : ", item);
          } else {
            console.log("invalid type : ", type);
            return;
          }
          core.loader.hide();
          console.log("close");
          this.close(UpdateEventType.UPDATE, item);
        } catch (e) {
          console.log(e);
          core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const result = await TransactionService.delete(item.id);
          // console.log("result : ", result);
          core.loader.hide();
          this.close(UpdateEventType.DELETE, item);
        } catch (e) {
          console.log(e);
          this.cancel();
        }
      }
    },
  },
});
