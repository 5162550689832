
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import EstimateAddEditComponent from "@/components/estimate/AddEditComponent.vue";
import core from "@/core";
import EstimateService from "@/services/work/estimate.service";
import EstimateAddEditV2Component from "@/components/estimate/AddEditV2Component.vue";
import UnitPriceAddEditComponent from "@/components/unit-price/AddEditComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "EstimateAddEdit",
  components: { UnitPriceAddEditComponent, EstimateAddEditV2Component, EstimateAddEditComponent },
  data: () => ({
    ready: false,
    detailVersion: "V2",
    changeDetailVersion: "",
    estimateId: null as any,
    place: null as any,
    properties: {
      params: {
        place: null,
      },
    },
    // changePlace: null as any,
  }),
  watch: {
    changeDetailVersion(val, preVal) {
      if (this.isNotBlank(val)) {
        this.detailVersion = this.changeDetailVersion;
        core.alert.hide();
      }
    },
    // changePlace(val, preVal) {
    //   if (val != null && preVal == null) {
    //     this.place = this.changePlace;
    //   }
    //   console.log("place : ", val);
    // },
  },
  mounted() {
    this.$nextTick(async () => {
      core.loader.show();
      try {
        const params = this.$route.params as any;
        const type = params.type;
        const query = this.$route.query as any;
        if (type === "edit") {
          this.estimateId = Number(query.id);
          if (this.estimateId <= 0) {
            await this.notFound();
            return;
          }

          const detailVersion = await EstimateService.getDetailVersion(this.estimateId);
          if (this.isNotBlank(detailVersion)) {
            this.detailVersion = String(detailVersion);
          } else {
            this.detailVersion = "V1";
          }
          console.log("detailVersion : ", this.detailVersion);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.ready = true;
      }
      core.loader.hide();
    });
  },
});
