import core from "@/core";
import { TableRequest } from "@/types";

class FloorPlanService {
  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/floor-plan/${id}`, null)
        .then((data: any) => {
          if (data.floorPlan != null) {
            resolve(data.floorPlan);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/floor-plan`, strParams)
        .then((data: any) => {
          if (data.floorPlan != null) {
            resolve(data.floorPlan);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/floor-plan/${id}`, strParams)
        .then((data: any) => {
          if (data.floorPlan != null) {
            resolve(data.floorPlan);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createImage(formData: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .post(`/api/work/floor-plan/image`, formData, {
          headers: core.http.headers.form,
        })
        .then((data: any) => {
          if (data.fileDetailId != null) {
            resolve(data.fileDetailId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/floor-plan/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new FloorPlanService();
