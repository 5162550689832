
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import { UpdateEventType } from "@/types";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import EstimateService from "@/services/work/estimate.service";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    type: "종류 ",
    price: "금액 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "PaymentAddEditModal",
  data: () => ({
    type: "",
    typeItems: [
      { text: "현금", value: "CACHE" },
      { text: "현금(영수증)", value: "CACHE_RECEIPT" },
      { text: "카드", value: "CARD" },
      { text: "쿠폰", value: "COUPON" },
      { text: "후기(캐시백)", value: "REVIEW" },
    ],
    balance: 0, // 잔금
    estimate: null as any,
    estimateId: 0,
    payment: null as any,
    dateVisible: false,
    form: {
      price: "" as string | number,
      date: "",
      type: "CACHE",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      // 생성 후 호출
      const properties = this.properties;
      this.type = properties.params.type;
      this.estimateId = properties.params.estimateId;
      this.estimate = properties.params.estimate;
      this.balance = this.estimate.totalPrice - this.estimate.payment;
      if (this.estimateId <= 0) {
        await this.notFound();
      }

      if (this.type !== "add" && this.type !== "edit") {
        await this.notFound();
      }

      if (this.type === "edit") {
        this.payment = this.properties.params.payment;
        this.form.date = this.payment.date;
        this.form.price = this.payment.price / 10000;
        this.form.type = this.payment.type;
      } else {
        this.form.date = core.date.instance().format("YYYY-MM-DD");
      }
    });
  },
  methods: {
    getBalance() {
      if (this.type === "edit") {
        return (this.balance + this.payment.price) / 10000;
      }
      return this.balance / 10000;
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const params = core.utils.deepCopy(this.form);
          params.price = Number(params.price) * 10000;
          let payment = null as any;
          const type = this.properties.params.type;
          if (type === "add") {
            payment = await EstimateService.createPayment(this.estimateId, params);
          } else if (type === "edit") {
            const paymentId = this.payment.id;
            payment = await EstimateService.updatePayment(this.estimateId, paymentId, params);
          } else {
            console.log("Unknown type : ", type);
            return;
          }
          core.loader.hide();
          this.close(UpdateEventType.RELOAD);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        }
      }
    },
    async deleteItem() {
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      const item = this.properties.params.payment;
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          await EstimateService.deletePayment(this.estimateId, item.id);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
        this.close(UpdateEventType.RELOAD, item);
      }
    },
  },
});
