
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import { AppBarMenuItem, TableRequest, UpdateEventType } from "@/types";
import MixinsAppBarMenu from "@/mixins/single/app-bar-menu";
import AppBar from "@/components/core/AppBar.vue";
import { cloneDeep } from "lodash";
import { LayoutType } from "@/router";
import TransactionService from "@/services/inventory/transaction.service";
import UpdateEvent from "@/models";
import InventoryProductAddEditModal from "@/modals/inventory/ProductAddEditModal.vue";
import ProductService from "@/services/inventory/product.service";
import core from "@/core";
import TransactionAddEditModal from "@/modals/inventory/TransactionAddEditModal.vue";

export default mixins(MixinsPage, MixinsTable, MixinsScroll, MixinsAppBarMenu).extend({
  name: "MgmtInventory",
  components: { TransactionAddEditModal, InventoryProductAddEditModal, AppBar },
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
    },
    productList: [] as any,
    filter: {
      selected: null as any,
      selectedItem: null as any,
      items: [] as any,
    },
    modal: {
      product: {
        visible: false,
        params: {
          type: "",
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
      transaction: {
        visible: false,
        params: {
          type: "",
          productList: [] as any,
          transactionType: "",
          item: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    prop: null as any,
  }),
  mounted() {
    this.initTable({
      service: TransactionService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.filter.items = [
        { id: "currentStock", text: "재고" },
        { id: "incoming", text: "입고" },
        { id: "outgoing", text: "출고" },
      ];
      const meta = this.$route.meta as any;
      const layoutList = cloneDeep(meta.layoutList) as any;
      layoutList.push(LayoutType.APP_BAR_NAV);
      this.prop = {
        layoutList: layoutList,
        appBarMenu: meta.appBarMenu,
      };
      this.changedFilter(0);

      // 테이블 정보 가져오기
      this.loadTable();

      // this.loadProductTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
    "filter.selectedItem"(selectedItem) {
      console.log("selectedItem : ", selectedItem);
      if (selectedItem) {
        if (selectedItem.id === "currentStock") {
          // 재고
          this.loadProductTable();
        } else if (selectedItem.id === "incoming") {
          // 입고
        } else if (selectedItem.id === "outgoing") {
          // 출고
        } else {
        }
      }
    },
    "filter.selected"(selected) {
      this.changedFilter(selected);
    },
    "modal.transaction.updateEvent"() {
      const modal = this.modal.transaction;
      const event = this.getComponentUpdateEvent(modal);
      if (event) {
        if (event.result === UpdateEventType.UPDATE) {
          const item = event.item;
          // console.log("item : ", item);
          if (modal.params.type === "edit") {
            this.updateTableItem("id", item);
          } else {
            this.addTableItem("id", item);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
    "modal.product.updateEvent"() {
      const modal = this.modal.product;
      const event = this.getComponentUpdateEvent(modal);
      if (event) {
        if (event.result === UpdateEventType.UPDATE) {
          const item = event.item;
          // console.log("item : ", item);
          if (modal.params.type === "edit") {
            this.productList.some((product) => {
              if (product.id === item.id) {
                core.utils.copy(item, product);
                return true;
              }
            });
            this.productList.sort((a: any, b: any) => {
              if (a.name < b.name) return -1;
              else if (a.name > b.name) return 1;
              return 0;
            });
          } else {
            this.productList.push(item);
            this.productList.sort((a: any, b: any) => {
              if (a.name < b.name) return -1;
              else if (a.name > b.name) return 1;
              return 0;
            });
          }
        } else if (event.result === UpdateEventType.DELETE) {
          const item = event.item;
          this.productList.some((product, index) => {
            if (product.id === item.id) {
              this.productList.splice(index, 1);
              return true;
            }
          });
        } else {
          console.log("unknown event : ", event);
        }
      }
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
    });
  },
  methods: {
    getProductName(item) {
      let name = "";
      this.productList.some((product) => {
        if (item.productId === product.id) {
          name = product.name;
          return true;
        }
      });
      return name;
    },
    async appBarMenuEvent(menu: AppBarMenuItem) {
      // console.log("appBarMenuEvent : ", menu);
      if (menu.id === "download") {
        console.log("excel download");
        try {
          core.loader.show();
          const result = (await ProductService.downloadExcel()) as any;
          await core.http.downloadFile(result.downloadUri);
        } catch (e) {
          console.log(e);
        } finally {
          core.loader.hide();
        }
      } else {
        console.log("unknown id : ", menu.id);
      }
    },
    showEditPage(item: any) {
      const filter = this.filter;
      // console.log("filter : ", filter);
      if (filter.selectedItem) {
        const id = filter.selectedItem.id;
        if (id === "currentStock") {
          // 재고 - 수정
          const modal = this.modal.product;
          modal.params.type = "edit";
          modal.params.item = item;
          modal.visible = true;
          console.log("modal : ", modal);
        } else if (id === "incoming") {
          // 입고
          const modal = this.modal.transaction;
          modal.params.type = "edit";
          modal.params.productList = this.productList;
          modal.params.transactionType = "INCOMING";
          modal.params.item = item;
          modal.visible = true;
          // console.log("modal : ", modal);
        } else if (id === "outgoing") {
          // 출고
          const modal = this.modal.transaction;
          modal.params.type = "edit";
          modal.params.productList = this.productList;
          modal.params.transactionType = "OUTGOING";
          modal.params.item = item;
          modal.visible = true;
        }
      }
    },
    showAddPage() {
      const filter = this.filter;
      // console.log("filter : ", filter);
      if (filter.selectedItem) {
        const id = filter.selectedItem.id;
        if (id === "currentStock") {
          // 재고 - 추가
          const modal = this.modal.product;
          modal.params.type = "add";
          modal.visible = true;
          console.log("modal : ", modal);
        } else if (id === "incoming") {
          // 입고
          const modal = this.modal.transaction;
          modal.params.type = "add";
          modal.params.productList = this.productList;
          modal.params.transactionType = "INCOMING";
          modal.visible = true;
        } else if (id === "outgoing") {
          // 출고
          const modal = this.modal.transaction;
          modal.params.type = "add";
          modal.params.productList = this.productList;
          modal.params.transactionType = "OUTGOING";
          modal.visible = true;
        }
      }
    },
    changedFilter(selected) {
      if (selected != null) {
        const selectedItem = this.filter.items[selected];
        if (selectedItem) {
          // console.log("selectedItem : ", selectedItem);
          this.filter.selectedItem = selectedItem;
          if (selectedItem.id === "incoming") {
            this.loadTable({ type: "INCOMING" });
          } else if (selectedItem.id === "outgoing") {
            this.loadTable({ type: "OUTGOING" });
          }
          // this.type = selectedItem.id;
          // this.properties.visible = true;
        } else {
          console.log("not found item : ", selected);
        }
      }
    },
    async loadProductTable() {
      const request = {
        draw: 0,
        start: 0,
        length: 1000,
        orderColumnName: "name",
        order: "asc",
        searchColumns: {} as any,
      } as TableRequest;
      const response = (await ProductService.getTable(request)) as any;
      const dataList = response.data;
      this.productList = dataList;
      console.log("productList : ", this.productList);
    },
  },
});
