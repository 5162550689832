
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import core from "@/core";
import store from "@/store";
import MixinsStorage from "@/mixins/single/storage";
import EstimateService from "@/services/work/estimate.service";
import SmsService from "@/services/sms.service";
import { UpdateEventType } from "@/types";
import { UserCompanyModel } from "@/models/company/userCompanyModel";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    bankAccount: "계좌 ",
    price: "금액 ",
  },
});

export default mixins(MixinsModalForm, MixinsStorage).extend({
  name: "PaymentSendSmsModal",
  data: () => ({
    storageItems: {
      type: "company",
    },
    list: [] as any,
    estimate: null as any,
    selectedCompanyDetail: null,
    form: {
      companyDetailId: "",
      price: 5,
      priceType: "잔금",
      content: "",
    },
    app: store.state.app,
    bodyMinHeight: "100px",
  }),
  mounted() {
    this.$nextTick(async () => {
      this.resize();

      await this.storageWait();
      const storageItem = this.getStorageItems();

      this.estimate = this.properties.params.estimate;
      if (this.estimate.payment === 0) {
        this.form.priceType = "계약금";
        this.form.price = 5;
      } else {
        this.form.priceType = "잔금";
        this.form.price = this.getBalance();
      }

      // 생성 후 호출
      const user = await this.$store.getters["auth/user"]();
      const company = user.company as UserCompanyModel;

      if (company.detailList != null) {
        // console.log("company : ", company);
        let companyDetailId = storageItem.selectedCompanyDetailId;
        company.detailList.forEach((detail) => {
          if (detail.type === "DEFAULT_BANK_ACCOUNT" || detail.type === "BANK_ACCOUNT") {
            const array = detail.value.split(",");
            if (array.length === 3) {
              const item = {
                id: detail.id,
                bankName: array[0],
                bankAccount: array[1],
                accountHolder: array[2],
              } as any;
              item.text = `${item.bankName}, ${item.bankAccount}, ${item.accountHolder}`;
              this.list.push(item);

              if (detail.type === "DEFAULT_BANK_ACCOUNT" && companyDetailId == null) {
                companyDetailId = item.id;
              }
            }
          }
        });
        if (this.estimate.place.bankAccountId != null) {
          companyDetailId = this.estimate.place.bankAccountId;
        }
        //console.log("companyDetailId : ", companyDetailId);
        this.form.companyDetailId = companyDetailId;

        if (this.estimate.id === 6767) {
          this.list.some((item) => {
            if (item.accountHolder.indexOf("이상철") > -1) {
              this.form.companyDetailId = item.id;
              return true;
            }
          });
        }
      } else {
        // TODO : 업체 계좌번호 등록 안내
      }
    });
  },
  watch: {
    "form.companyDetailId"(val: any) {
      if (val != null) {
        this.list.some((item: any) => {
          if (val === item.id) {
            this.selectedCompanyDetail = item;

            const storageItem = this.getStorageItems();
            if (storageItem.selectedCompanyDetailId != val) {
              storageItem.selectedCompanyDetailId = val;
              this.saveStorageItems();
            }
            return true;
          }
        });
        this.updateContent();
      }
    },
    "form.price"(val) {
      if (val != null) {
        this.updateContent();
      }
    },
    "form.priceType"(val) {
      if (val != null) {
        this.updateContent();
      }
    },
    "app.size"() {
      this.resize();
    },
  },
  methods: {
    resize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 200;
      } else {
        if (this.app.size.width < 965) {
          offset = 215;
        } else {
          offset = 220;
        }
      }
      this.bodyMinHeight = this.app.size.height - offset + "px";
    },
    updateContent() {
      const item = this.selectedCompanyDetail as any;
      if (item != null) {
        const form = this.form;
        const price = this.getPrice(form.price);
        let msg = "";
        msg += "결제요청";
        msg += `\n\n은행: ${item.bankName}`;
        msg += `\n계좌번호: ${item.bankAccount}`;
        msg += `\n예금주: ${item.accountHolder}`;
        msg += `\n금액: ${price}(${form.priceType})`;
        msg += `\n\n☆받는통장표시 이름에 동호수(000-000)로 입력해서 부탁드립니다.`;
        if (form.priceType === "계약금") {
          msg += `\n☆입주일 확정시 최소 3주전에 대표번호 1660-2021로 연락주시면 일정관리 해드리겠습니다.`;
        }
        msg += `\n\n☆견적서, 결제금액, A/S 진행은 아래 링크로 확인하실 수 있습니다.`;
        msg += `\nhttps://customer.kongkongmat.com/login?v=${btoa(
          this.estimate.customerPhone + "," + this.estimate.authCode
        )}`;
        this.form.content = msg;
      }
    },
    getPrice(price) {
      price = price * 10000;
      const temp = price / 10000;
      if (temp == parseInt(String(temp))) {
        return parseInt(String(temp)) + "만원";
      }
      const tmp = price % 10000;

      return parseInt(String(temp)) + "만 " + tmp + "원";
    },
    getBalance() {
      if (this.estimate != null) {
        return (this.estimate.totalPrice - this.estimate.payment) / 10000;
      }
      return 0;
    },
    async submit() {
      console.log("submit");
      if (await this.validate()) {
        try {
          core.loader.show("문자 전송중...");
          const smsPushId = await EstimateService.sendSms(this.estimate.id, {
            type: "CONTENT",
            content: this.form.content,
          });
          const vm = this as any;
          const lastPayment = this.getBalance() === vm.form.price;
          console.log("lastPayment : ", lastPayment);
          const interval = setInterval(async () => {
            try {
              if (vm._isDestroyed) {
                clearInterval(interval);
              } else {
                const apiResult = (await SmsService.getSmsResult(smsPushId as string)) as any;
                if (apiResult.result) {
                  clearInterval(interval);

                  // 최종 결제 문자 전송 id 업데이트
                  if (lastPayment) {
                    try {
                      await EstimateService.updateLastPaymentSms(vm.estimate.id, smsPushId);
                    } catch (e) {
                      console.log(e);
                    }
                  }

                  core.loader.hide();
                  await core.alert.show({
                    title: "알림",
                    body: "문자 전송 완료",
                  });
                  vm.close(UpdateEventType.RELOAD);
                } else if (apiResult.code < 0) {
                  clearInterval(interval);
                  core.loader.hide();
                  await core.alert.show({
                    title: "알림",
                    body: apiResult.message,
                  });
                  vm.close(UpdateEventType.RELOAD);
                }
              }
            } catch (e) {
              console.log(e);
              vm.close(UpdateEventType.RELOAD);
            }
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
});
