
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import UpdateEvent from "@/models";
import core from "@/core";
import { UpdateEventType } from "@/types";
import store from "@/store";
import FloorPlanService from "@/services/work/floor-plan.service";
import PlaceService from "@/services/work/place.service";
import DrawImageModal from "@/modals/map/DrawImageModal.vue";
import CoreOpenlayersImageMap from "@/core/core-openlayers-image-map";
import { DrawPolygon, DrawText } from "@/models/floor-plan/floor-plan.model";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    placeId: "아파트 ",
  },
});

export default mixins(MixinsPageForm).extend({
  name: "MgmtFloorPlanAddEdit",
  components: { DrawImageModal },
  data: () => ({
    title: "",
    type: "",
    isReady: false,
    readyPromiseResolve: null as any,
    floorPlan: null as any,
    floorPlanId: 0,
    form: {
      placeId: "" as string | null,
      floorPlanList: [] as any,
    },
    selectedType: "",
    imageIndex: 0,
    selectedPlace: null as any,
    placeList: [] as any,
    view: {
      placeName: "",
    },
    modal: {
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      drawImage: {
        visible: false,
        params: {
          item: null as any,
          dataUri: "",
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    app: store.state.app,
    style: {
      image: "max-width: 100%",
    },
    olMap: {},
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.type = params.type;
      if (this.type === "add") {
        this.title = "평면도 추가";
      } else if (this.type === "edit") {
        this.title = "평면도 수정";
        const query = this.$route.query as any;
        this.floorPlanId = Number(query.id);
        if (this.floorPlanId <= 0) {
          this.notFound();
          return;
        }
      }
      if (!this.app.isMobile) {
        if (this.app.size.width > 700) {
          this.style.image = `max-width: 50%`;
        }
      }
      const maxHeight = parseInt(String(this.app.size.height / 2));
      // console.log("maxHeight : ", maxHeight);
      this.style.image += `; max-height: ${maxHeight}px`;
      await this.getPlaceList();

      const type = this.type;
      if (type === "edit") {
        try {
          this.floorPlan = (await FloorPlanService.get(this.floorPlanId)) as any;
        } catch (e) {
          console.log(e);
          await this.notFound();
          return;
        }
        const item = this.floorPlan;

        const promise = new Promise((resolve) => {
          this.readyPromiseResolve = resolve;
        });

        this.form.placeId = item.place.id;
        this.view.placeName = item.place.name;
        this.placeList.some((place) => {
          if (place.id === item.place.id) {
            this.selectedPlace = place;
            return true;
          }
        });
        await promise;

        //console.log("selected place : ", this.selectedPlace);
        if (item.detailList != null) {
          const typeDetailMap = {};
          item.detailList.forEach((detail) => {
            if (!typeDetailMap[detail.type]) {
              typeDetailMap[detail.type] = { type: detail.type, detailList: [] };
            }
            typeDetailMap[detail.type].detailList.push({
              index: this.imageIndex++,
              visible: true,
              id: detail.id,
              fileDetailId: detail.fileDetailId,
              dataUri: detail.imageUri,
              parent: typeDetailMap[detail.type],
              ol: detail.ol,
            });
          });
          this.form.floorPlanList.forEach((floorPlan) => {
            const type = floorPlan.type;
            if (typeDetailMap[type]) {
              floorPlan.detailList = typeDetailMap[type].detailList;
            }
          });
        }
      }
      this.isReady = true;
    });
  },
  watch: {
    type(val) {
      if (val === "add") {
        this.title = "평면도 추가";
      } else if (val === "edit") {
        this.title = "평면도 수정";
      }
    },
    "form.placeId"(val, prev) {
      console.log("changed placeId : ", val);

      let place = this.selectedPlace;
      this.form.floorPlanList = [];
      if (place != null && place.typeList != null) {
        place.typeList.forEach((type) => {
          const item = {
            type: type,
            detailList: [],
          };
          this.form.floorPlanList.push(item);
        });
      }
      if (!this.isReady) {
        this.readyPromiseResolve();
        return;
      }
    },
    title() {
      this.$store.dispatch("topToolbar/changeTitle", { title: this.title, ignoreCheck: true });
    },
    async "modal.drawImage.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.drawImage);
      if (event != null) {
        // console.log("event : ", event);
        if (event.result === UpdateEventType.UPDATE) {
          const detail = this.modal.drawImage.params.item;
          const params = event.item;
          // console.log("drawImage params : ", params);
          // console.log("detail : ", detail);
          detail.ol = params;

          this.updateOlMap(detail);
          // const image = params.image;
          // let filename = "map";
          // if (image.ext != null) {
          //   filename += image.ext;
          // }
          // // 이미지 업로드
          // const formData = new FormData();
          // formData.append("imageFile", image.blob, filename);
          //
          // try {
          //   const fileDetailId = await FloorPlanService.createImage(formData);
          //   console.log("upload image");
          // } catch (e) {
          //   console.log(e);
          // }
        }
      }
    },
  },
  methods: {
    editImage(detail) {
      console.log("detail : ", detail);
      const modal = this.modal.drawImage;
      modal.params.item = detail;
      modal.params.dataUri = detail.dataUri;
      modal.visible = true;
    },
    moveImage(direction, image, imageList) {
      let pos = 0;
      imageList.some((_image, index) => {
        if (image.index === _image.index) {
          pos = index;
          return true;
        }
      });
      if (direction === "down") {
        if (pos + 1 < imageList.length) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos + 1, 0, fromItem[0]);
        }
      } else if (direction === "up") {
        if (0 < pos) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos - 1, 0, fromItem[0]);
        }
      }
      // console.log("image : ", image);
      // console.log("imageList : ", imageList);
    },
    imageHeight(item) {
      console.log("item : ", item);
      const size = this.app.size;

      return 20;
      // let fileDetail = item.fileDetail;
      // if (fileDetail.imageWidth && fileDetail.imageHeight) {
      //   const size = this.app.size;
      //   if (item.isSelfComment) {
      //     const cal = (size.width - 87) / fileDetail.imageWidth;
      //     return fileDetail.imageHeight * cal;
      //   } else {
      //     const cal = (size.width - 110) / fileDetail.imageWidth;
      //     return fileDetail.imageHeight * cal;
      //   }
      // }
    },
    async onChangeImageFile() {
      console.log("onChangeImageFile");
      const el = this.$refs.imageFile as any;
      const files = el.files;
      const length = files.length;
      if (length === 0) {
        await core.alert.show({
          title: "알림",
          body: "선택된 파일이 없습니다",
        });
      } else {
        const type = this.selectedType;
        for (let i = 0; i < length; i++) {
          try {
            const file = files[i];
            const imageFile = (await core.utils.image.getImageBlob(file)) as any;
            imageFile.index = this.imageIndex++;
            imageFile.type = "upload";

            //console.log("new image : ", imageFile);
            this.form.floorPlanList.some((floorPlan) => {
              if (floorPlan.type === this.selectedType) {
                floorPlan.detailList.push(imageFile);
                imageFile.parent = floorPlan;
                return true;
              }
            });
            // this.imageList.push(imageFile);
            // this.changedImage = true;
          } catch (e: any) {
            core.http.sendError(e);
            console.log(e);
            await core.alert.show({
              title: "알림",
              body: e.message,
            });
          }
        }
        (this.$refs.imageFile as any).value = "";
        // this.scrollControl.updatedPosition = ScrollPosition.BOTTOM;
      }
    },

    removeImage(detail) {
      const floorPlan = detail.parent;
      const detailList = floorPlan.detailList;
      for (let i = 0; i < detailList.length; i++) {
        const _detail = detailList[i];
        if (_detail.index === detail.index) {
          detailList.splice(i, 1);
          break;
        }
      }
    },

    showImageSelectView(type) {
      this.selectedType = type;
      (this.$refs.imageFile as any).click();
    },

    async getPlaceList() {
      try {
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      } catch (e) {
        console.log(e);
      }
    },
    async createPlace(keyword, itemList) {
      try {
        if (itemList != null && itemList.length > 0) {
          let placeName = "";
          itemList.forEach((item) => {
            placeName += item.name + ",";
          });
          placeName = placeName.substr(0, placeName.length - 1);
          const result = await core.alert.show({
            title: "확인",
            body: `비슷한 이름의 [<span class="red--text">${placeName}</span>] 아파트가 존재합니다.<br>새로 생성하시겠습니까?`,
            showCancelButton: true,
            cancelButtonText: "취소",
            confirmButtonText: "생성",
          });
          if (result !== "confirm") return;
        }
        const item: any = await PlaceService.create({ name: keyword });
        await this.$store.dispatch("app/waitForPlaceUpdate", item.id);
        return item;
      } catch (e: any) {
        this.placeList = await this.$store.getters["app/getPlaceList"];
      }
    },
    loadImage(detail) {
      console.log("initMap : ", detail);
      detail.visible = false;
      const image = new Image();
      image.src = detail.dataUri;
      image.onload = () => {
        let width = image.width;
        let height = image.height;
        // console.log("width : ", width, ", height : ", height);

        //console.log("imageDetail : ", imageDetail);
        console.log("index : ", detail.index);
        const el = document.getElementById(`map_${detail.index}`);
        this.initMap({
          el: el,
          uri: detail.dataUri,
          width: width,
          height: height,
          ol: detail.ol,
          floorPlanDetail: detail,
        });
      };
    },
    initMap(item: any) {
      // let width = item.width;
      // if (this.app.size.width < width) {
      //   width = this.app.size.width;
      // }
      const floorPlanDetail = item.floorPlanDetail;
      const width = item.width;
      const height = item.height;

      let zoom = 1;
      if (width > height) {
        const bodyWidth = (this.$refs.body as any).$el.clientWidth - 24;
        // console.log("bodyWidth : ", bodyWidth);
        zoom = bodyWidth / width;
        // console.log("zoom : ", zoom);
      } else {
        zoom = 300 / height;
      }
      zoom = zoom - zoom / 5;
      if (zoom > 0.4) {
        zoom = 0.4;
      }
      //console.log("zoom : ", zoom);

      //console.log("el : ", item.el);
      const openLayersMap = new CoreOpenlayersImageMap({
        map: {
          element: item.el,
          image: {
            url: item.uri,
            size: {
              width: item.width,
              height: item.height,
            },
          },
          zoom: zoom,
          minZoom: 0,
          maxZoom: 10,
          enable: {
            rotate: false,
            zoom: false,
          },
        },
        callback: {
          click: null,
        },
      });

      const featureList = [] as any;
      const ol = item.ol;
      if (ol != null) {
        if (ol.polygonList != null) {
          // console.log("item : ", item);
          ol.polygonList.forEach((polygon: DrawPolygon) => {
            const feature = openLayersMap.addPolygon({
              name: "",
              color: polygon.color,
              positionList: polygon.positionList,
            });
            featureList.push(feature);
          });
        }
        if (ol.textList != null) {
          ol.textList.forEach((text: DrawText) => {
            const feature = openLayersMap.addText(text.text, text.position);
            featureList.push(feature);
          });
        }
      }

      // console.log("floorPlanDetail : ", floorPlanDetail);
      this.olMap[floorPlanDetail.index] = {
        floorPlanDetail: floorPlanDetail,
        openLayersMap: openLayersMap,
        featureList: featureList,
      };
      // console.log("olMap : ", this.olMap);
    },
    updateOlMap(floorPlanDetail) {
      const olMap = this.olMap[floorPlanDetail.index];
      // console.log("olMap : ", olMap);
      if (olMap != null) {
        const openLayersMap = olMap.openLayersMap;
        olMap.featureList.forEach((feature) => {
          openLayersMap.removeFeature(feature);
        });

        const featureList = [] as any;
        const ol = floorPlanDetail.ol;
        if (ol != null) {
          if (ol.polygonList != null) {
            // console.log("item : ", item);
            ol.polygonList.forEach((polygon: DrawPolygon) => {
              const feature = openLayersMap.addPolygon({
                name: "",
                color: polygon.color,
                positionList: polygon.positionList,
              });
              featureList.push(feature);
            });
          }
          if (ol.textList != null) {
            ol.textList.forEach((text: DrawText) => {
              const feature = openLayersMap.addText(text.text, text.position);
              featureList.push(feature);
            });
          }
        }
        olMap.featureList = featureList;
      }
    },
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const type = this.type;
          const floorPlanList = this.form.floorPlanList;
          const params = {
            placeId: this.form.placeId,
            detailList: [] as any,
          };
          for (let i = 0; i < floorPlanList.length; i++) {
            const floorPlan = floorPlanList[i];
            const detailList = floorPlan.detailList;
            if (detailList.length > 0) {
              //console.log("floorPlan : ", floorPlan);
              for (let j = 0; j < detailList.length; j++) {
                const imageFile = detailList[j];
                console.log("imageFile : ", imageFile);
                if (imageFile.id == null) {
                  // 이미지 업로드
                  const formData = new FormData();
                  formData.append("imageFile", imageFile.blob, imageFile.filename);

                  let fileDetailId = null as any;
                  try {
                    fileDetailId = await FloorPlanService.createImage(formData);
                  } catch (e) {
                    console.log(e);
                  }
                  if (fileDetailId != null) {
                    const detail = {
                      type: floorPlan.type,
                      fileDetailId: fileDetailId,
                      ol: imageFile.ol,
                    } as any;
                    params.detailList.push(detail);
                  }
                } else {
                  const detail = {
                    id: imageFile.id,
                    type: floorPlan.type,
                    fileDetailId: imageFile.fileDetailId,
                    ol: imageFile.ol,
                  } as any;
                  params.detailList.push(detail);
                }
              }
            }
          }
          const floorPlan =
            type === "add"
              ? await FloorPlanService.create(params)
              : await FloorPlanService.update(this.floorPlanId, params);
          console.log("floorPlan : ", floorPlan);
          this.goBack(UpdateEventType.UPDATE, floorPlan);
        } catch (e: any) {
          console.log(e);
          if (e.errorFieldName === "detailList") {
            await core.alert.show({
              title: "알림",
              body: "이미지가 1개 이상 선택되어야합니다.",
              confirmButtonText: "확인",
            });
          } else {
            this.errorSubmit(e);
          }
        } finally {
          core.loader.hide();
        }
      } else {
        console.log("validate error");
      }
    },
  },
});
