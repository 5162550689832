
import Vue from "vue";
import mixins from "vue-typed-mixins";
import core from "@/core";
import ScheduleService from "@/services/work/schedule.service";
import MixinsPage from "@/mixins/page";
import ScheduleDaySelectModal from "@/modals/schedule/ScheduleDaySelectModal.vue";
import store from "@/store";
import ScheduleUtils, { FilterScheduleViewType, ScheduleViewType } from "@/models/schedule";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPage).extend({
  name: "Schedule",
  components: {
    ScheduleDaySelectModal,
  },
  data: () => ({
    title: "일정 선택",
    ready: false,
    viewType: "",
    calendar: {
      model: core.date.instance().format("YYYY-MM-DD"),
      title: "",
      items: [] as any,
      totalPrice: 0,
      dateMap: {} as any,
      reloadFlag: false,
      type: "month",
      viewTotalPrice: "",
    },
    searchColumns: {} as any,

    modal: {
      day: {
        visible: false,
        params: {
          viewType: ScheduleViewType.ALL,
          searchColumns: {},
        },
        // 업데이트 이벤트 처리용 변수
        updateEvent: null as any as UpdateEvent | null,
      },
    },
    returnType: "",
    updated: false,
    updatedType: "",
    issueDateMap: {} as any,
    ////////////
    windowResizeDate: null as Date | null,
  }),
  computed: {
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // month
      const query = this.$route.query;
      if (query.month != null) {
        const moment = core.date.instance(query.month as string);
        if (moment.isValid()) {
          this.calendar.model = moment.format("YYYY-MM-DD");
        }
      }
      this.returnType = String(query.returnType);
      if (this.isNotBlank(query.categoryId)) {
        this.searchColumns.categoryId = String(query.categoryId);
      }
      if (this.isNotBlank(query.teamId)) {
        this.searchColumns.teamId = String(query.teamId);
      }
      if (this.isNotBlank(query.estimateId)) {
        this.searchColumns.estimateId = String(query.estimateId);
      }

      let viewType = ScheduleUtils.getViewType(String(query.viewType));
      if (viewType == null) {
        viewType = ScheduleViewType.ALL;
      }
      this.modal.day.params.viewType = viewType;
      this.viewType = viewType;
      if (viewType == ScheduleViewType.DEFAULT) {
        this.searchColumns.estimateId = "null";
      } else if (viewType == ScheduleViewType.VACATION) {
        this.searchColumns.workType = "V";
      }

      // 생성 후 호출
      this.windowResize();

      //console.log("query : ", query);

      this.ready = true;
      this.calendar.reloadFlag = true;
    });
  },
  updated() {
    const updatedType = this.updatedType;
    this.updatedType = "";
    if (updatedType === "schedule") {
      this.windowResize();
    }
  },
  watch: {
    "app.size"() {
      console.log("changed app.size : ", this.app.size);
      if (this.windowResizeDate == null) {
        this.windowResizeDate = new Date();
      }
      setTimeout(() => {
        if (this.windowResizeDate != null) {
          const elapsedTimeMillis = new Date().getTime() - this.windowResizeDate.getTime();
          if (elapsedTimeMillis > 500) {
            this.windowResizeDate = null;
            this.windowResize();
          }
        } else {
          console.log("ignore window resize event");
        }
      }, 500);
    },
    "calendar.reloadFlag"(reloadFlag) {
      this.calendar.reloadFlag = false;
      if (reloadFlag && this.ready) {
        this.getScheduleList(this.searchColumns);
      }
    },
    "modal.day.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.day);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = {
            returnType: this.returnType,
            date: event.item,
          };
          this.goBack(UpdateEventType.UPDATE, item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.getScheduleList(this.searchColumns);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    windowResize() {
      console.log("changed window size");
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 105;
      } else {
        if (this.app.size.width < 965) {
          offset = 110;
        } else {
          offset = 115;
        }
      }
      // this.calendar.height = this.app.size.height - offset;

      {
        const elList = document.querySelectorAll(".v-event-more-custom");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-issue");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      {
        const elList = document.querySelectorAll(".v-event-more-custom-text");
        if (elList != null) {
          elList.forEach((el) => {
            el.remove();
          });
        }
      }
      const elList = document.querySelectorAll(".v-calendar-weekly__week .v-calendar-weekly__day");
      if (elList != null) {
        const eventElList = document.querySelectorAll(
          ".v-calendar-weekly__week .v-calendar-weekly__day .v-event"
        );

        if (eventElList.length > 0) {
          eventElList.forEach((el: any) => {
            if (el.style.display == "none") {
              el.style.display = "block";
            }
          });
        }

        elList.forEach((el) => {
          if (el.clientHeight < el.scrollHeight) {
            const eventList = el.querySelectorAll("[data-date]");
            let height = 21; // label 제외
            let viewCount = 0;
            let addMoreCount = 0;
            let date = "";

            eventList.forEach((eventEl: any) => {
              if (eventEl.dataset.date != null) {
                date = eventEl.dataset.date;
              }
              height += eventEl.clientHeight;
              if (eventEl.classList.contains("v-event")) {
                height += 1;
              }
              if (height > el.clientHeight) {
                eventEl.style.display = "none";
                addMoreCount++;
              } else {
                viewCount++;
              }
            });
            if (this.issueDateMap[date]) {
              let moreEl = el.querySelector(".v-event-more-custom-issue");
              if (moreEl == null) {
                moreEl = document.createElement("div");
                moreEl.classList.add("v-event-more-custom-issue");
                el.appendChild(moreEl);
              }
            }
            if (addMoreCount > 0) {
              //console.log("date : ", date, ", addMoreCount : ", addMoreCount);
              const list = this.calendar.dateMap[date];
              //console.log("list : ", list);
              addMoreCount = list.length - viewCount;
              //console.log("date : ", date);
              // this.calendar.dateMap[date];

              let moreEl = el.querySelector(".v-event-more-custom");
              if (moreEl == null) {
                moreEl = document.createElement("div");
                moreEl.classList.add("v-event-more-custom");
                el.appendChild(moreEl);
              }
              let moreTextEl = el.querySelector(".v-event-more-custom-text");
              if (moreTextEl == null) {
                moreTextEl = document.createElement("div");
                moreTextEl.classList.add("v-event-more-custom-text");
                el.appendChild(moreTextEl);
              }
              moreTextEl.innerHTML = String(addMoreCount);
            }
          }
        });
      }
    },
    viewDay({ date }: any) {
      // this.showScheduleDayModal(date);
      const searchColumns: any = {};
      for (const key of Object.keys(this.searchColumns)) {
        if (key !== "startDate" && key !== "endDate") {
          const value = this.searchColumns[key];
          if (value != null) {
            searchColumns[key] = value;
          } else if (value === null) {
            searchColumns[key] = "null";
          }
        }
      }
      searchColumns.date = date;
      //console.log("searchColumns : ", searchColumns);
      this.showDayModal(searchColumns);
    },
    getEventColor(event: any) {
      return event.color;
    },
    setToday() {
      this.calendar.model = "";
    },
    getCalendarInstance() {
      return this.$refs.calendar as Vue & {
        prev: () => void;
        next: () => void;
        checkChange: () => void;
        getFormatter: (format: any) => any;
      };
    },
    prev() {
      this.getCalendarInstance().prev();
      this.replaceState();
    },
    next() {
      this.getCalendarInstance().next();
      this.replaceState();
    },
    replaceState() {
      const params = core.utils.getUrlParams();
      params.month = core.date.instance(this.calendar.model).format("YYYY-MM");
      const url = this.$route.path + "?" + core.http.objToUrlParams(params);
      history.replaceState("", "", url);
    },
    showEvent({ day, nativeEvent, event }: any) {
      const startMoment = core.date.instance(event.start);
      const endMoment = core.date.instance(event.end);
      const diff = endMoment.diff(startMoment, "days");
      if (diff === 0) {
        const searchColumns: any = {};
        for (const key of Object.keys(this.searchColumns)) {
          if (key !== "startDate" && key !== "endDate") {
            const value = this.searchColumns[key];
            if (value != null) {
              searchColumns[key] = value;
            } else if (value === null) {
              searchColumns[key] = "null";
            }
          }
        }
        searchColumns.date = startMoment.format("YYYY-MM-DD");
        //console.log("searchColumns : ", searchColumns);
        this.showDayModal(searchColumns);
      }
    },
    async updateRange({ start, end }: any) {
      //console.log("updateRange : ", start.date);

      const events = [] as any;
      const startMoment = core.date.instance(`${start.date}T00:00:00`);
      const endMoment = core.date.instance(`${start.date}T23:59:59`);

      // 타이틀 변경
      this.calendar.title = startMoment.format("YYYY년 MM월");
      this.title = this.calendar.title;

      //console.log(`start ${startMoment.format("YYYY-MM-DD HH:mm:ss")}`);
      //console.log(`end ${endMoment.format("YYYY-MM-DD HH:mm:ss")}`);

      this.searchColumns.startDate = startMoment.format("YYYY-MM-DD");
      this.searchColumns.endDate = startMoment.add(1, "months").format("YYYY-MM-DD");

      console.log(
        `startDate : ${this.searchColumns.startDate}, endDate : ${this.searchColumns.endDate}`
      );

      // change range
      if (this.ready) {
        this.calendar.reloadFlag = true;
      }
    },
    swipe(direction: string) {
      //console.log("swipe : ", direction);
      if (direction === "Left") {
        this.next();
      } else if (direction === "Right") {
        this.prev();
      } else {
        return;
      }
    },
    moneyFormat(value: string) {
      return core.utils.format.moneyKor(value);
    },
    async getScheduleList(searchColumns: any) {
      try {
        core.loader.show();
        // const items = [] as any;
        const items = (this.calendar.items = [] as any);

        let totalPrice = 0;

        const params = core.utils.deepCopy(searchColumns);
        // console.log("filterViewType : ", this.filterViewType);

        // 이슈 목록 조회
        this.issueDateMap = {};
        if (this.isCompanyManagerRoleHigher) {
          const issueList = (await ScheduleService.getIssueList(
            params.startDate,
            params.endDate
          )) as any;
          issueList.forEach((issue) => {
            this.issueDateMap[issue.date] = true;
          });
        }

        // 일정 목록 조회
        const scheduleList = (await ScheduleService.getSimpleBySearchColumnsAndViewType(
          params,
          FilterScheduleViewType.DEFAULT
        )) as any;

        const startDate = new Date();
        if (scheduleList != null) {
          console.log("schedule size : ", scheduleList.length);

          // 날짜별 정렬
          const newScheduleList = [] as any;
          {
            const dateMap = {} as any;
            scheduleList.forEach((schedule) => {
              const startDateMoment = core.date.instance(schedule.startAt);
              const strDate = startDateMoment.format("YYYY-MM-DD");
              if (dateMap[strDate] == null) {
                dateMap[strDate] = [];
              }
              const list = dateMap[strDate];
              list.push(schedule);

              if (schedule.workType === "D") {
                totalPrice += schedule.totalPrice;
                // console.log("add totalPrice : ", schedule.totalPrice);
                // if (schedule.totalPrice < 0) {
                //   console.log("schedule : ", schedule);
                // }
              }
            });
            this.calendar.dateMap = dateMap;

            const keyList = Object.keys(dateMap);
            keyList.forEach((strDate) => {
              const list = dateMap[strDate];
              const sortList = list.sort((a, b) => {
                const aStartDateMoment = core.date.instance(a.startAt);
                // const endDateMoment = core.date.instance(a.endAt, true);
                const bStartDateMoment = core.date.instance(b.startAt);
                if (aStartDateMoment.format("YYYYMMDD") !== bStartDateMoment.format("YYYYMMDD"))
                  return -1;
                else if (aStartDateMoment.isBefore(bStartDateMoment)) return -1;
                else if (bStartDateMoment.isBefore(aStartDateMoment)) return 1;
                return 0;
                // if (a.name > b.name) return 1;
                // else if (a.name < b.name) return -1;
                // return 0;
              });
              // console.log("sortList : ", sortList);
              let count = 0;
              const maxCount = this.getMaxDaySize();
              sortList.some((schedule) => {
                //console.log("startAt : ", schedule.startAt);
                newScheduleList.push(schedule);
                count++;
                if (count >= maxCount) {
                  return true;
                }
              });
            });
          }

          const completeScheduleList = [] as any;
          const paymentScheduleList = [] as any;

          const curMoment = core.date.instance();
          const ignoreScheduleUser = this.myUser.username === "user";

          // 날짜, 시간별 정렬
          newScheduleList.forEach((schedule: any) => {
            if (schedule.color.length == 0) schedule.color = "grey lighten-1";
            const title = ScheduleUtils.getScheduleTitle(this.myUser, this.viewType, schedule);

            const startDateMoment = core.date.instance(schedule.startAt, true);
            if (ignoreScheduleUser) {
              const diffDays = startDateMoment.diff(curMoment, "days");
              if (diffDays > 7) {
                return;
              }
            }
            const startDate = startDateMoment.toDate();
            let endDate = null as Date | null;
            {
              const endMoment = core.date.instance(schedule.endAt);
              if (schedule.allDay) {
                endDate = core.date.instance(endMoment, true).toDate();
              } else {
                const endDateMoment = core.date.instance(endMoment, true);
                const diffDays = endDateMoment.diff(startDate, "days");
                if (diffDays === 1 && endMoment.format("HH:mm:ss") === "00:00:00") {
                  endDate = core.date.instance(endMoment, true).add(-1, "days").toDate();
                } else {
                  endDate = core.date.instance(endMoment, true).toDate();
                }
              }
            }
            const color = ScheduleUtils.getScheduleColor(this.myUser, schedule);

            const item = {
              id: schedule.id,
              name: `${title}`,
              start: startDate,
              end: endDate,
              color: color,
              timed: false,
            };

            if (schedule.estimate != null && schedule.estimate.paymentYn === "Y") {
              paymentScheduleList.push(item);
            } else if (schedule.complete) {
              completeScheduleList.push(item);
            } else {
              items.push(item);
            }
          });

          completeScheduleList.forEach((item) => {
            items.push(item);
          });
          paymentScheduleList.forEach((item) => {
            items.push(item);
          });
        }
        this.calendar.totalPrice = totalPrice;
        this.calendar.viewTotalPrice = this.moneyFormat(String(this.calendar.totalPrice));
        //console.log("this.calendar.viewTotalPrice : ", this.calendar.viewTotalPrice);
        this.updatedType = "schedule";
        const elapsedTimeMillis = new Date().getTime() - startDate.getTime();
        console.log("elapsedTimeMillis : ", elapsedTimeMillis);
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    // async drawSchedule(searchColumns: any) {
    //   //console.log("draw schedule");
    //   core.loader.show();
    //   try {
    //     const items = (this.calendar.items = [] as any);
    //     let totalPrice = 0;
    //
    //     const curMoment = core.date.instance();
    //     const ignoreScheduleUser = this.myUser.username === "user";
    //
    //     const scheduleList = (await ScheduleService.getSimpleBySearchColumnsAndViewType(
    //       searchColumns,
    //       FilterScheduleViewType.DEFAULT
    //     )) as any;
    //     if (scheduleList != null) {
    //       console.log("schedule size : ", scheduleList.length);
    //
    //       // 날짜별 정렬
    //       const newScheduleList = [] as any;
    //       {
    //         const dateMap = {} as any;
    //         scheduleList.forEach((schedule) => {
    //           const startDateMoment = core.date.instance(schedule.startAt);
    //           const strDate = startDateMoment.format("YYYY-MM-DD");
    //           if (dateMap[strDate] == null) {
    //             dateMap[strDate] = [];
    //           }
    //           const list = dateMap[strDate];
    //           list.push(schedule);
    //
    //           if (schedule.workType === "D") {
    //             totalPrice += schedule.totalPrice;
    //             // console.log("add totalPrice : ", schedule.totalPrice);
    //             // if (schedule.totalPrice < 0) {
    //             //   console.log("schedule : ", schedule);
    //             // }
    //           }
    //         });
    //         this.calendar.dateMap = dateMap;
    //
    //         const keyList = Object.keys(dateMap);
    //         keyList.forEach((strDate) => {
    //           const list = dateMap[strDate];
    //           const sortList = list.sort((a, b) => {
    //             const aStartDateMoment = core.date.instance(a.startAt);
    //             // const endDateMoment = core.date.instance(a.endAt, true);
    //             const bStartDateMoment = core.date.instance(b.startAt);
    //             if (aStartDateMoment.format("YYYYMMDD") !== bStartDateMoment.format("YYYYMMDD"))
    //               return -1;
    //             else if (aStartDateMoment.isBefore(bStartDateMoment)) return -1;
    //             else if (bStartDateMoment.isBefore(aStartDateMoment)) return 1;
    //             return 0;
    //             // if (a.name > b.name) return 1;
    //             // else if (a.name < b.name) return -1;
    //             // return 0;
    //           });
    //           // console.log("sortList : ", sortList);
    //           let count = 0;
    //           const maxCount = this.getMaxDaySize();
    //           sortList.some((schedule) => {
    //             //console.log("startAt : ", schedule.startAt);
    //             newScheduleList.push(schedule);
    //             count++;
    //             if (count >= maxCount) {
    //               return true;
    //             }
    //           });
    //         });
    //       }
    //
    //       const completeScheduleList = [] as any;
    //       const paymentScheduleList = [] as any;
    //
    //       const curMoment = core.date.instance();
    //       const ignoreScheduleUser = this.myUser.username === "user";
    //
    //       // 날짜, 시간별 정렬
    //       newScheduleList.forEach((schedule: any) => {
    //         if (schedule.color.length == 0) schedule.color = "grey lighten-1";
    //         const title = ScheduleUtils.getScheduleTitle(this.myUser, this.viewType, schedule);
    //
    //         const startDateMoment = core.date.instance(schedule.startAt, true);
    //         if (ignoreScheduleUser) {
    //           const diffDays = startDateMoment.diff(curMoment, "days");
    //           if (diffDays > 7) {
    //             return;
    //           }
    //         }
    //         const startDate = startDateMoment.toDate();
    //         let endDate = null as Date | null;
    //         {
    //           const endMoment = core.date.instance(schedule.endAt);
    //           if (schedule.allDay) {
    //             endDate = core.date.instance(endMoment, true).toDate();
    //           } else {
    //             const endDateMoment = core.date.instance(endMoment, true);
    //             const diffDays = endDateMoment.diff(startDate, "days");
    //             if (diffDays === 1 && endMoment.format("HH:mm:ss") === "00:00:00") {
    //               endDate = core.date.instance(endMoment, true).add(-1, "days").toDate();
    //             } else {
    //               endDate = core.date.instance(endMoment, true).toDate();
    //             }
    //           }
    //         }
    //         const color = ScheduleUtils.getScheduleColor(this.myUser, schedule);
    //
    //         const item = {
    //           id: schedule.id,
    //           name: `${title}`,
    //           start: startDate,
    //           end: endDate,
    //           color: color,
    //           timed: false,
    //         };
    //
    //         if (schedule.estimate != null && schedule.estimate.paymentYn === "Y") {
    //           paymentScheduleList.push(item);
    //         } else if (schedule.complete) {
    //           completeScheduleList.push(item);
    //         } else {
    //           items.push(item);
    //         }
    //       });
    //
    //       completeScheduleList.forEach((item) => {
    //         items.push(item);
    //       });
    //       paymentScheduleList.forEach((item) => {
    //         items.push(item);
    //       });
    //     }
    //     this.calendar.totalPrice = totalPrice;
    //     this.updatedType = "schedule";
    //   } catch (e) {
    //     console.log(e);
    //   }
    //   core.loader.hide();
    // },
    getMaxDaySize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 105;
      } else {
        if (this.app.size.width < 965) {
          offset = 110;
        } else {
          offset = 115;
        }
      }
      let height = this.app.size.height - offset - 610;
      // 610 - 6개
      if (height > 0) {
        return parseInt(String(height / 105)) + 9;
      } else {
        return 9;
      }
    },
    showDayModal(searchColumns: any) {
      const modal = this.modal.day;

      modal.params.searchColumns = searchColumns;
      modal.visible = true;
    },
  },
});
