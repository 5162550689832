import core from "@/core";
import { Page } from "@/models/page-type";
import ColorItems from "@/data/color-items";
import moment from "moment";
import { hasCompanyRole } from "@/store/modules/auth";
import { CompanyRole } from "@/types";

export enum ScheduleMoreViewType {
  NOT_COMPLETE_SCHEDULE = "notCompleteSchedule", // 완료되지 않은 일정 목록
}

export enum ScheduleViewType {
  ALL = "all", // 전체일정
  DEFAULT = "default", // 기본일정
  VACATION = "vacation", // 휴무일정
  CATEGORY = "category", // 품목별 일정
  TEAM = "team", // 팀별 일정
  ESTIMATE = "estimate", // 견적 일정
  ATTENDANCE = "attendance", // 출근 일정
}

export enum FilterScheduleViewType {
  ALL = "all", // 전체일정
  DEFAULT = "default", // 일반일정
  TEMP = "temp", // 입주예정 일정
}

export enum FilterVisibleBit {
  viewType = 1 << 0,
  category = 1 << 1,
  team = 1 << 2,
  place = 1 << 3,
  dong = 1 << 4,
  ho = 1 << 5,
  customerPhone = 1 << 6,
  workType = 1 << 7,
  title = 1 << 8,
  estimate = 1 << 9,
}

export enum FilterColumns {
  categoryId = "categoryId",
  categoryIdList = "categoryIdList",
  placeId = "placeId",
  placeIdList = "placeIdList",
  dong = "dong",
  dongList = "dongList",
  ho = "ho",
  hoList = "hoList",
  customerPhone = "customerPhone",
  customerPhoneList = "customerPhoneList",
  workType = "workType",
  workTypeList = "workTypeList",
  title = "title",
  userId = "userId",
  teamId = "teamId",
  estimateIdIsNull = "estimateIdIsNull",
}

class ScheduleUtils {
  readonly moreViewTypeList = [] as string[];
  readonly moreViewTypeTableMap = {} as any;
  readonly scheduleViewTypeList = [] as string[];
  readonly filterScheduleViewTypeList = [] as string[];
  readonly filterVisibleBitMap = {} as any;
  readonly filterColumns = [] as any;
  readonly colorItems = [] as any;
  readonly colorHexItems = {} as any;

  constructor() {
    for (const key in ScheduleMoreViewType) {
      const value = ScheduleMoreViewType[key];
      this.moreViewTypeList.push(value);
    }

    {
      // 시공완료하지 않은 일정
      this.moreViewTypeTableMap[ScheduleMoreViewType.NOT_COMPLETE_SCHEDULE] = {
        title: "시공완료되지 않은 일정",
        content: "일정이 지났지만 시공이 완료되지 않은 목록 입니다.",
        orderColumnName: "updatedAt",
        order: "desc",
        searchColumns: {
          estimateIdIsNull: false,
          complete: false,
          endAtLessThan: moment().format("YYYY-MM-DD 00:00:00"),
        },
      };
    }

    for (const key in ScheduleViewType) {
      const value = ScheduleViewType[key];
      this.scheduleViewTypeList.push(value);
    }
    for (const key in FilterScheduleViewType) {
      const value = FilterScheduleViewType[key];
      this.filterScheduleViewTypeList.push(value);
    }
    for (const key in FilterVisibleBit) {
      const value = FilterVisibleBit[key];
      if (typeof value !== "number") {
        continue;
      }
      this.filterVisibleBitMap[key] = value;
    }
    for (const key in FilterColumns) {
      const value = FilterColumns[key];
      this.filterColumns.push(value);
    }

    ColorItems.forEach((item) => {
      this.colorItems.push(item);
    });
    this.colorItems.push({ color: "grey", hex: "#9E9E9E", text: "그레이" });
    this.colorItems.push({ color: "grey darken-1", hex: "#757575", text: "그레이" });

    this.colorItems.forEach((item: any) => {
      this.colorHexItems[item.color] = item.hex;
    });
  }

  getBit(viewType: ScheduleViewType): number {
    if (viewType === ScheduleViewType.ALL) {
      return (
        FilterVisibleBit.viewType |
        FilterVisibleBit.category |
        FilterVisibleBit.team |
        FilterVisibleBit.place |
        FilterVisibleBit.dong |
        FilterVisibleBit.ho |
        FilterVisibleBit.customerPhone |
        FilterVisibleBit.workType |
        FilterVisibleBit.title |
        FilterVisibleBit.estimate
      );
    } else if (viewType === ScheduleViewType.DEFAULT) {
      return FilterVisibleBit.category | FilterVisibleBit.title;
    } else if (viewType === ScheduleViewType.CATEGORY) {
      return (
        FilterVisibleBit.viewType |
        FilterVisibleBit.team |
        FilterVisibleBit.place |
        FilterVisibleBit.dong |
        FilterVisibleBit.ho |
        FilterVisibleBit.customerPhone |
        FilterVisibleBit.workType |
        FilterVisibleBit.title |
        FilterVisibleBit.estimate
      );
    } else if (viewType === ScheduleViewType.TEAM) {
      return (
        FilterVisibleBit.viewType |
        FilterVisibleBit.place |
        FilterVisibleBit.dong |
        FilterVisibleBit.ho |
        FilterVisibleBit.customerPhone |
        FilterVisibleBit.workType |
        FilterVisibleBit.title |
        FilterVisibleBit.estimate
      );
    } else if (viewType === ScheduleViewType.VACATION) {
      return FilterVisibleBit.title;
    } else if (viewType === ScheduleViewType.ATTENDANCE) {
      return FilterVisibleBit.title;
    } else {
      console.log("Unknown viewType : ", viewType);
    }
    return 0;
  }

  hasViewTypeFilter(viewType: ScheduleViewType) {
    const bit = this.getBit(viewType);
    return (bit & FilterVisibleBit.viewType) != 0;
  }

  getStorageKey(item: any) {
    if (item.viewType == ScheduleViewType.ALL) {
      // 전체
      return `${item.viewType}`;
    } else if (item.viewType == ScheduleViewType.DEFAULT) {
      // 기본
      return `${item.viewType}`;
    } else if (item.viewType == ScheduleViewType.CATEGORY) {
      // 품목별
      if (item.categoryId == null) {
        console.log("not found categoryId, ", item.categoryId);
        return "unknown";
      }
      return `${item.viewType}_${item.categoryId}`;
    } else if (item.viewType == ScheduleViewType.TEAM) {
      // 팀별
      if (item.teamId == null) {
        console.log("not found teamId, ", item.teamId);
        return "unknown";
      }
      return `${item.viewType}_${item.teamId}`;
    } else if (item.viewType == ScheduleViewType.VACATION) {
      // 휴무
      return `${item.viewType}`;
    } else if (item.viewType == ScheduleViewType.ATTENDANCE) {
      // 출근
      return `${item.viewType}`;
    } else {
      console.log("Unknown viewType : ", item.viewType);
      return "unknown";
    }
  }

  getFilterViewType(viewType: ScheduleViewType, filters: any) {
    if (this.hasViewTypeFilter(viewType)) {
      if (filters.viewType != null) {
        const filterViewType = filters.viewType;
        delete filters.viewType;
        //console.log("filterViewType : ", filterViewType);
        if (this.filterScheduleViewTypeList.indexOf(filterViewType) > -1) {
          return filterViewType;
        } else {
          console.log("Unknown filterViewType : ", filterViewType);
        }
      }
      return FilterScheduleViewType.DEFAULT;
    } else {
      return FilterScheduleViewType.DEFAULT;
    }
  }

  getFilterColumns(viewType: ScheduleViewType, searchColumns: any): any {
    const filters = {};
    for (const key of Object.keys(searchColumns)) {
      if (this.filterColumns.indexOf(key) > -1) {
        if (key === "categoryIdList") {
          const value = searchColumns[key];
          if (typeof value === "object") {
            searchColumns[key] = value.join(",");
          }
        }
        filters[key] = searchColumns[key];
      } else {
        console.log("not support column : ", key);
      }
    }
    return filters;
  }

  updateFilterVisibleView(viewType: ScheduleViewType, visible: any): any {
    const bit = this.getBit(viewType);
    // console.log("updateVisibleView : ", viewType, ", bit : ", bit);
    for (const key of Object.keys(this.filterVisibleBitMap)) {
      const value = FilterVisibleBit[key];
      visible[key] = (bit & value) != 0;
    }
  }

  updateButtonVisible(schedule: any, myUser: any, visible: any) {
    // 견적정보에 포함된 일정일 경우 A/S 일정만 삭제 버튼 표시
    if (schedule.estimateId != null) {
      visible.delete = schedule.workType === "A";
      visible.estimate = true;
      visible.userEdit = true;
    } else {
      visible.delete = true;
      visible.estimate = false;
      visible.userEdit = false;
    }
  }

  getViewType(viewType: string): ScheduleViewType | null {
    for (const value of this.scheduleViewTypeList) {
      if (value === viewType) {
        return value as ScheduleViewType;
      }
    }
    return null;
  }

  // 동일 메뉴 여부
  isSameMenu(item1: any, item2: any) {
    if (item1.page === Page.SCHEDULE && item1.page === item2.page) {
      return (
        item1.viewType == item2.viewType &&
        item1.categoryId == item2.categoryId &&
        item1.estimateId == item2.estimateId &&
        item1.teamId == item2.teamId
      );
    }
    return false;
  }

  // 메뉴 URL param
  getStringUrlParamsFromMenu(item: any) {
    return core.http.objToUrlParams(this.getUrlParamsFromMenu(item));
  }

  getUrlParamsFromMenu(item: any) {
    const urlParams = { page: item.page } as any;
    if (core.utils.validate.isNotBlank(item.viewType)) {
      urlParams.viewType = item.viewType;
    }
    if (core.utils.validate.isNotBlank(item.categoryId)) {
      urlParams.categoryId = item.categoryId;
    }
    if (core.utils.validate.isNotBlank(item.teamId)) {
      urlParams.teamId = item.teamId;
    }
    if (core.utils.validate.isNotBlank(item.estimateId)) {
      urlParams.estimateId = item.estimateId;
    }
    return urlParams;
  }

  getScheduleDate(schedule: any) {
    // console.log(`${schedule.startAt}, ${schedule.endAt}`);
    const startMoment = core.date.instance(schedule.startAt);
    const endMoment = core.date.instance(schedule.endAt);
    let strDate = "";
    // console.log(startMoment.format("YYYY-MM-DD"));
    // console.log(endMoment.format("YYYY-MM-DD"));
    if (startMoment.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      if (schedule.allDay) {
        strDate = startMoment.format("YYYY년 MM월 DD일(ddd)");
      } else {
        strDate =
          startMoment.format("YYYY년 MM월 DD일(ddd) a hh:mm") + " ~ " + endMoment.format("a hh:mm");
      }
    } else {
      if (schedule.allDay) {
        strDate =
          startMoment.format("YYYY년 MM월 DD일(ddd)") +
          " ~ " +
          endMoment.format("YYYY년 MM월 DD일(ddd)");
      } else {
        strDate =
          startMoment.format("YYYY년 MM월 DD일(ddd) a hh:mm") +
          " ~ " +
          endMoment.format("YYYY년 MM월 DD일(ddd) a hh:mm");
      }
    }
    return strDate;
  }

  isStartAtBeforeToday(schedule: any) {
    const moment = core.date.instance();
    const endAtMoment = core.date.instance(schedule.startAt);
    return moment.isAfter(endAtMoment);
  }

  isEndAtBeforeToday(schedule: any) {
    const moment = core.date.instance();
    const endAtMoment = core.date.instance(schedule.endAt);
    return moment.isAfter(endAtMoment);
  }

  getScheduleColor(myUser: any, schedule: any) {
    let color = schedule.color;
    if (hasCompanyRole(myUser, CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER)) {
      if (this.isEndAtBeforeToday(schedule)) {
        if (schedule.estimate != null && schedule.estimate.paymentYn === "Y") {
          color = "grey";
        }
        // else if (schedule.complete) {
        //   color = "grey darken-1";
        // }
      }
    }
    return color;
    // return schedule.color;
  }

  getScheduleColorHex(myUser: any, schedule: any) {
    return this.colorHexItems[this.getScheduleColor(myUser, schedule)];
  }

  colorToHex(color: string) {
    return this.colorHexItems[color];
  }

  getScheduleTitle(myUser: any, viewType: string, schedule: any) {
    let moreText = "";
    let addKerapoxyText = "";
    if (schedule.hasKerapoxy) {
      addKerapoxyText = "[케라]";
    }
    if (hasCompanyRole(myUser, CompanyRole.OWNER, CompanyRole.ADMIN, CompanyRole.MANAGER)) {
      // if (schedule.estimate != null) {
      //   if (schedule.estimate.paymentYn === "Y") {
      //     moreText += "[결제]";
      //   }
      // }

      if (viewType !== ScheduleViewType.TEAM) {
        if (schedule.totalPrice > 0) {
          moreText += `[${schedule.totalPrice / 10000}]`;
        }
      } else if (schedule.team) {
        // 팀 일정일 경우
        const titleViewType = schedule.team.titleViewType;
        if (titleViewType === "PRICE_LEADER" || titleViewType === "PRICE_ALL") {
          if (schedule.totalPrice > 0) {
            if (schedule.adjustPrice == null) schedule.adjustPrice = 0;
            moreText += `[${(schedule.totalPrice + schedule.adjustPrice) / 10000}]`;
          }
        } else if (schedule.team.titleViewType === "TYPE") {
          if (schedule.estimate != null) {
            if (core.utils.validate.isNotBlank(schedule.estimate.type)) {
              moreText = `[${schedule.estimate.type}]`;
            }
          }
        } else if (schedule.team.titleViewType !== "NONE") {
          console.log("Unknown Title View Type : ", titleViewType);
        }
      }
    } else {
      if (
        schedule.team != null &&
        schedule.totalPrice > 0 &&
        schedule.team.titleViewType !== "NONE"
      ) {
        let visiblePrice = false;
        if (schedule.team.titleViewType === "PRICE_LEADER") {
          if (myUser.company.teamList != null) {
            myUser.company.teamList.some((team) => {
              if (team.id == schedule.teamId) {
                if (team.role === "LEADER") {
                  visiblePrice = true;
                }
                return true;
              }
            });
          }
        } else if (schedule.team.titleViewType === "PRICE_ALL") {
          visiblePrice = true;
        } else if (schedule.team.titleViewType === "TYPE") {
          if (schedule.estimate != null) {
            if (core.utils.validate.isNotBlank(schedule.estimate.type)) {
              moreText = `[${schedule.estimate.type}]`;
            }
          }
        }

        if (visiblePrice) {
          moreText = `[${(schedule.totalPrice + schedule.adjustPrice) / 10000}]`;
        }
      }
    }
    return `${moreText}${addKerapoxyText}${schedule.title}`;
  }

  getMoreViewType(viewType: string): ScheduleMoreViewType | null {
    for (const value of this.moreViewTypeList) {
      if (value === viewType) {
        return value as ScheduleMoreViewType;
      }
    }
    return null;
  }

  getTableParams(viewType: string): any {
    return this.moreViewTypeTableMap[viewType];
  }
}

export default new ScheduleUtils();
