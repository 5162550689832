import AbstractTableService from "@/services/core/abstract-table.service";
import core from "@/core";

class ProductService extends AbstractTableService {
  constructor() {
    super("/api/inventory/product", "product");
  }

  downloadExcel() {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/inventory/excel`, null, null)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ProductService();
