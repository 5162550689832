
import Vue from "vue";
import store from "@/store";
import core from "@/core";
import { cloneDeep } from "lodash";
import { LayoutType, RouteMeta } from "@/router";
import AppBar from "@/components/core/AppBar.vue";
import AlertModal from "@/modals/core/AlertModal.vue";
import NavigationDrawer from "@/components/core/NavigationDrawer.vue";
import { AppBarMenu } from "@/types";
import { defaultMobileTopToolbar, defaultTopToolbar } from "@/store/modules/top-toolbar";

export default Vue.extend({
  name: "App",
  components: { NavigationDrawer, AlertModal, AppBar },
  data: () => ({
    app: store.state.app,
    auth: store.state.auth,
    prop: {
      layoutList: null as LayoutType[] | null,
      appBarMenu: null as AppBarMenu | null,
    },

    //////////////////////////////////
    transitionName: "forward",
    redirectPath: null as string | null,
    interval: null as number | null,
    visible: {
      appBar: false,
      nav: false,
    },
  }),

  computed: {
    LayoutType() {
      return LayoutType;
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
  },
  mounted() {
    (window as any).updateMobileSystemProperties = this.updateMobileSystemProperties;
    (window as any).updateMobileSettings = this.updateMobileSettings;
    (window as any).updatePaymentSmsSettings = this.updatePaymentSmsSettings;
    (window as any).socialLogin = this.socialLogin;
    (window as any).mobileRedirectPath = this.mobileRedirectPath;
    (window as any).showModal = this.showModal;
    (window as any).showToast = this.showToast;
    (window as any).occurNotification = this.occurNotification;

    const windowResize = this.windowResize;
    window.addEventListener("resize", () => {
      windowResize();
    });
    windowResize();
    document.documentElement.style.setProperty("--toolbar-height", `0px`);

    const platform = core.utils.platform();
    if (platform === "android" || platform === "ios") {
      this.app.isMobile = true;
    }
    this.app.isApp = core.mobile.isApp();
    this.app.platform = platform;

    if (this.app.isMobile) {
      const keys = Object.keys(defaultMobileTopToolbar);
      keys.forEach((key) => {
        defaultTopToolbar[key] = defaultMobileTopToolbar[key];
      });
      this.$store.dispatch("topToolbar/changeOrgStyle");
    }

    this.app.showNav = !this.app.isMobileSize;
    if (this.app.isMobile) {
      document.documentElement.classList.add("mobile");
    } else {
      document.documentElement.classList.add("pc");
    }

    this.changeMeta(this.$route.meta as RouteMeta);
    this.app.path = this.$route.path;
  },
  watch: {
    $route(to: any, from: any) {
      if (to.params["stackKey-dir"] === "forward") {
        this.transitionName = "forward";
      } else {
        this.transitionName = "back";
      }

      const meta = to.meta as RouteMeta;
      // console.log("meta : ", meta);
      this.changeMeta(meta);
      this.app.path = this.$route.path;

      this.windowResize();
    },
    redirectPath() {
      if (this.interval == null && this.redirectPath != null) {
        const auth = this.$store.state.auth;
        const router = this.$router;

        this.interval = setInterval(() => {
          if (auth.user != null) {
            const path = this.redirectPath as string;
            this.redirectPath = null;
            console.log("redirect : ", path);
            try {
              router.push({
                path: path,
              });
            } catch (e) {
              console.log(e);
            }
          }
          if (this.redirectPath == null) {
            clearInterval(this.interval as number);
            this.interval = null;
          }
        }, 200);
      }
    },
  },
  methods: {
    hasLayout(...paramLayoutTypes) {
      if (this.prop.layoutList != null) {
        let exists = false;
        paramLayoutTypes.some((paramLayoutType) => {
          (this.prop.layoutList as LayoutType[]).some((layoutType) => {
            if (paramLayoutType === layoutType) {
              exists = true;
              return exists;
            }
          });
          if (exists) {
            return exists;
          }
        });
        return exists;
      }
      return false;
    },
    changeMeta(meta: any) {
      this.prop.layoutList = meta.layoutList;
      this.prop.appBarMenu = meta.appBarMenu;

      this.visible.appBar = this.hasLayout(
        LayoutType.APP_BAR,
        LayoutType.APP_BAR_NAV,
        LayoutType.APP_BAR_BACK
      );
      // if (this.visible.appBar) {
      //   if (this.app.size.width < 960) {
      //     document.documentElement.style.setProperty("--scc-app-bar-height", `56px`);
      //   } else {
      //     document.documentElement.style.setProperty("--scc-app-bar-height", `64px`);
      //   }
      // } else {
      //   document.documentElement.style.removeProperty("--scc-app-bar-height");
      // }
      this.visible.nav = this.hasLayout(LayoutType.NAV);
    },
    windowResize() {
      // console.log(`resize width '${window.innerWidth}', '${window.innerHeight}'`);
      // console.log(`resize width '${window.outerWidth}', '${window.outerHeight}'`);

      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
        bodyWidth: window.innerWidth,
      };

      // document.body.style.minHeight = this.app.size.height + "px";
      document.documentElement.style.setProperty("--vh", `${size.height * 0.01}px`);
      if (size.width < 960) {
        document.documentElement.style.setProperty("--app-bar-height", `56px`);
      } else {
        document.documentElement.style.setProperty("--app-bar-height", `64px`);
      }

      this.app.isMobileSize = size.width < 1264;
      if (!this.app.isMobileSize) {
        // 현재 페이지에 nav 존재 여부
        if (
          this.app.showNav &&
          this.prop &&
          this.prop.layoutList &&
          this.prop.layoutList.indexOf(LayoutType.NAV) > -1
        ) {
          size.bodyWidth = window.innerWidth - 300;
        }
      }
      this.app.size = size;
    },
    //////////////////////////////////////////////////////////////////////////////////
    occurNotification: function (item: any) {
      const path = this.$route.path;

      const notificationList = store.state.app.data.notificationList;
      let exists = false;
      let existsIndex = 0;
      for (let i = 0; i < notificationList.length; i++) {
        const _item = notificationList[i];
        if (_item.id === item.id) {
          exists = true;
          existsIndex = i;
          break;
        }
      }
      if (!exists) {
        notificationList.push(item);
        console.log("occur notification : ", item);
      } else if (item.confirmedAt != null) {
        notificationList.splice(existsIndex, 1);
        // console.log("list : ", notificationList);
        console.log("notification length : ", notificationList.length);
      } else if (item.confirmedAt == null) {
        this.showToast(item.content);
      }
    },
    showModal: function (params: any) {
      return core.alert.show(params);
    },
    showToast: function (content: string) {
      this.$toast.info(content);
    },
    mobileRedirectPath: function (path: string) {
      console.log("mobileRedirectPath : ", path);
      if (path != null && path.length > 0) {
        this.redirectPath = path;
      }
    },
    updateMobileSystemProperties: function (strSystemProperties: string) {
      const systemProperties = JSON.parse(strSystemProperties);

      this.$store.dispatch("auth/updateMobileSystemProperties", systemProperties);
    },
    updateMobileSettings: function (strSettings: string) {
      const mobileSettings = JSON.parse(strSettings);

      this.$store.dispatch("auth/updateMobileSetting", mobileSettings);
    },
    updatePaymentSmsSettings: function (strSettings: string) {
      const settings = JSON.parse(strSettings);

      this.$store.dispatch("auth/updatePaymentSmsSettings", settings);
    },
    socialLogin: function (strParam: string, addProvider: boolean | null) {
      //console.log(strParam);
      if (addProvider == null) addProvider = false;
      const token = JSON.parse(strParam);
      console.log("token : ", token);
      const provider = token.provider != null ? token.provider.toLowerCase() : "kakao";
      core.loader.show("로그인 중...");
      const params: any = {
        registrationId: provider,
        accessToken: token.accessToken,
        rememberMe: true,
      };

      if (token.idToken != null) {
        params.idToken = token.idToken;
      }
      if (token.name != null) {
        params.name = token.name;
      }

      if (addProvider) {
        params.addProvider = addProvider;
      }

      this.$store
        .dispatch("auth/socialLogin", params)
        .then(async (data) => {
          if (data.result) {
            console.log("call reload");
            window.location.reload();
          } else {
            //console.log(data);
            if (data.type === "addProvider") {
              core.loader.hide();
              const result = await core.alert.show({
                title: "확인",
                body: "이미 가입된 계정이 존재합니다.<br>소셜 로그인 정보를 추가하시겠습니까?",
                showCancelButton: true,
                cancelButtonText: "아니오",
                confirmButtonText: "예",
              });

              if (result === "confirm") {
                this.socialLogin(strParam, true);
              } else {
                core.alert.show({
                  title: "알림",
                  body: "가입된 계정 정보로 로그인 해주세요",
                });
              }
            } else {
              core.loader.hide();

              // 가입되지 않은 계정 회원가입 페이지로 이동
              const params = cloneDeep(data.socialUser);
              params.token = data.token;
              //console.log("params : ", params);
              this.$router.push({
                path: "/register",
                query: params,
              });
            }
          }
        })
        .catch((reason) => {
          core.loader.hide();
        });
    },
  },
});
