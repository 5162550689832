
import Vue from "vue";
import core from "@/core";
import ScheduleUtils from "@/models/schedule";
import PlaceService from "@/services/work/place.service";

export default Vue.extend({
  name: "ScheduleViewInfo",
  props: {
    schedule: {
      type: Object,
      default: () => {
        return {
          title: "",
          estimateId: null,
          category: {},
          caption: "",
          memoView: "",
        };
      },
    },
  },
  data: () => ({
    mapLink: "",
    visible: {
      mapMaker: false,
    },
  }),
  mounted() {
    this.visible.mapMaker = false;
    if (this.schedule != null) {
      this.changedSchedule();
    }
  },
  watch: {
    schedule(schedule) {
      if (schedule != null) {
        this.changedSchedule();
      }
    },
  },
  methods: {
    addressText(place) {
      if (place.address1 != null) {
        if (core.utils.validate.isBlank(place.address2)) {
          return `${place.address1}`;
        } else {
          return `${place.address1} ${place.address2}`;
        }
      } else return place.name;
    },
    getAddressText() {
      if (
        this.schedule != null &&
        this.schedule.estimate != null &&
        this.schedule.estimate.place != null
      ) {
        return `${this.addressText(this.schedule.estimate.place)} ${
          this.schedule.estimate.dong
        }동 ${this.schedule.estimate.ho}호`;
        // {{ addressText(estimate.place) }}
        // {{ estimate.dong }}동 {{ estimate.ho }}호
      }
      return "";
    },
    async changedSchedule() {
      this.visible.mapMaker = false;
      const schedule = this.schedule;
      if (schedule != null) {
        const estimate = this.schedule.estimate;
        if (estimate != null && estimate.place != null) {
          let isMobile = false;
          const platform = core.utils.platform();
          if (platform === "android" || platform === "ios") {
            isMobile = true;
          }

          const place = (await PlaceService.getPlace(estimate.place.id)) as any;
          estimate.place = place;

          if (isMobile) {
            if (platform === "android") {
              this.visible.mapMaker = true;
              let address = "";

              // const place = estimate.place;
              let location = "";
              if (place.latitude != null && place.longitude != null) {
                location = place.latitude + "," + place.longitude;
              }
              if (this.isNotBlank(place.address1)) {
                address = place.address1;
                if (this.isNotBlank(place.address2)) {
                  address += " " + place.address2;
                }
              } else {
                address = place.name;
              }
              this.mapLink = "geo:" + location + "?q=" + address;
            }
          }
        }
      }
    },
    getCustomerPhone(schedule: any) {
      if (schedule.estimate != null) {
        return core.utils.format.textToHtml(
          core.utils.format.hyphenPhone(schedule.estimate.customerPhone)
        );
      }
      return "";
    },
    isNotBlank(val) {
      return core.utils.validate.isNotBlank(val);
    },
    getScheduleUserText() {
      const schedule = this.schedule;
      if (schedule.userList == null || schedule.userList.length === 0) return "없음";
      else {
        const mainUserNameList = [] as any;
        const subUserNameList = [] as any;
        schedule.userList.forEach((user: any) => {
          if (user.type === "MAIN") {
            mainUserNameList.push(user.name);
          } else {
            subUserNameList.push(user.name);
          }
        });
        return (
          mainUserNameList.join(",") +
          (subUserNameList.length > 0 ? "(" + subUserNameList.join(",") + ")" : "")
        );
      }
    },
    getScheduleDate(schedule: any) {
      return ScheduleUtils.getScheduleDate(schedule);
    },
  },
});
