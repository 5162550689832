
import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import core from "@/core";
import EstimateService from "@/services/work/estimate.service";
import PaymentAddEditModal from "@/modals/estimate/PaymentAddEditModal.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export default mixins(MixinsComponent).extend({
  name: "EstimatePaymentComponent",

  components: { PaymentAddEditModal },

  data: () => ({
    title: "결제",
    estimateId: 0,
    // estimate: null as any,
    paymentList: [] as any,
    modal: {
      addEdit: {
        visible: false,
        params: {
          type: "",
          estimateId: null as number | null,
          estimate: null as any,
          payment: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
  }),
  mounted() {
    this.$nextTick(() => {
      this.estimateId = this.properties.params.estimateId;
      this.getPaymentList();
    });
  },
  watch: {
    "modal.addEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.addEdit);
      if (event != null) {
        if (event.result === UpdateEventType.RELOAD) {
          this.getPaymentList();
          this.setPropsUpdateEvent(UpdateEventType.RELOAD);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    showAddModal() {
      //console.log("showAddModal");
      const modal = this.modal.addEdit;
      modal.params.type = "add";
      modal.params.estimateId = this.estimateId;
      modal.params.estimate = this.properties.params.estimate;
      modal.visible = true;
    },
    showEditModal(payment) {
      //console.log("showEditModal");
      const modal = this.modal.addEdit;
      modal.params.type = "edit";
      modal.params.estimateId = this.estimateId;
      modal.params.estimate = this.properties.params.estimate;
      modal.params.payment = payment;
      modal.visible = true;
    },
    async getPaymentList() {
      // 결제 목록 조회
      core.loader.show();
      try {
        this.paymentList = await EstimateService.getPaymentList(this.estimateId);
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async deletePayment(payment) {
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        console.log("deletePayment : ", payment);
        // 결제 목록 조회
        core.loader.show();
        try {
          await EstimateService.deletePayment(this.estimateId, payment.id);
          this.getPaymentList();
          this.setPropsUpdateEvent(UpdateEventType.RELOAD);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    paymentTypeFormat(type) {
      if (type === "CACHE") {
        return "현금";
      } else if (type === "CACHE_RECEIPT") {
        return "현금(영수증)";
      } else if (type === "CARD") {
        return "카드";
      } else if (type === "COUPON") {
        return "쿠폰";
      } else if (type === "REVIEW") {
        return "후기(캐시백)";
      }
      return "";
    },
  },
});
