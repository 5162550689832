
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { Chrome } from "vue-color";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "SelectColorModal",
  components: {
    "chrome-picker": Chrome,
  },
  data: () => ({
    type: "",
    colors: "#1e90ff73",
    updateColor: null as any,
    visible: true,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (this.properties.params.color != null) {
        this.colors = this.properties.params.color;
      }
    });
  },
  watch: {
    visible(visible) {
      if (!visible) {
        this.submit();
      }
    },
  },
  methods: {
    submit() {
      const updateColor = this.updateColor;
      // console.log("updateColor : ", updateColor);
      this.close(UpdateEventType.CONFIRM, updateColor);
    },
    updateValue(value) {
      // console.log("update value : ", value);
      this.updateColor = value;
    },
  },
});
