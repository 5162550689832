import "ol/ol.css";
import Projection from "ol/proj/Projection";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import View from "ol/View";
import { getCenter } from "ol/extent";
import { Fill, Icon, Stroke, Style, Text } from "ol/style";
import { Feature } from "ol";
import { Point, Polygon } from "ol/geom";
import { defaults as ControlDefaults } from "ol/control";
import { defaults as InteractionDefaults, Draw } from "ol/interaction";
import { unByKey } from "ol/Observable";
import { toLonLat } from "ol/proj";

export interface OpenLayersParam {
  map: {
    element: any;
    image: {
      url: string;
      size: {
        width: number;
        height: number;
      };
    };
    zoom: number;
    minZoom: number;
    maxZoom: number;
    enable: {
      rotate: boolean;
      zoom: boolean;
    };
  };
  callback: {
    click: any;
  };
}

export enum DrawingType {
  POLYGON = "polygon",
  BOX = "box",
  POINT = "point",
}

export enum FeatureType {
  MARKER = "marker",
  EDITABLE_POLYGON = "editable_polygon",
  POLYGON = "polygon",
  EDITABLE_POINT = "editable_point",
  TEXT = "text",
  // BOX = "box",
}

export interface Position {
  x: number;
  y: number;
}

export interface MarkerParam {
  name: string;
  position: Position;
}

export interface PolygonParam {
  name: string;
  color: string | null;
  positionList: Position[];
}

export default class CoreOpenlayersImageMap {
  options: any = null;
  map: Map;
  centerChangeRequest: Date = new Date(0);
  vectorSource: any;
  vectorLayer: any;

  drawing = {
    index: 0,
    // 그리기 모드 활성화
    active: false,
    // 그리기 객체
    feature: null,
    drawItem: null,
    type: null as DrawingType | null,
    selectedFeature: null,
    color: {
      white: "rgba(216,216,216, 1)",
      black: "rgba(0, 0, 0, 0.45)",
      blue: "rgba(30,144,255, 0.45)",
      pink: "rgba(255,20,147, 0.45)",
    },
    style: {
      default: null,
      selected: null,
      drawing: null,
    },
    callback: null,
  } as any;

  drawItem = {
    polygon: null,
    box: null,
    point: null,
  } as any;

  constructor(params: OpenLayersParam) {
    if (params == null) return;
    else if (params.callback == null) params.callback = {} as any;

    const me = this as any;

    this.drawing.style.default = new Style({
      stroke: new Stroke({
        color: this.drawing.color.white,
        width: 2,
      }),
      fill: new Fill({
        color: this.drawing.color.black,
      }),
    });
    this.drawing.style.selected = new Style({
      stroke: new Stroke({
        color: this.drawing.color.white,
        width: 2,
      }),
      fill: new Fill({
        color: this.drawing.color.pink,
      }),
    });

    this.drawing.style.drawing = new Style({
      stroke: new Stroke({
        color: this.drawing.color.white,
        width: 2,
      }),
      fill: new Fill({
        color: this.drawing.color.blue,
      }),
    });

    const options = (this.options = {
      map: {
        element: params.map.element,
        image: params.map.image,
        zoom: params.map.zoom,
        minZoom: params.map.minZoom,
        maxZoom: params.map.maxZoom,
      },
      callback: params.callback,
    }) as OpenLayersParam;

    const vectorSource = (this.vectorSource = new VectorSource({}));

    const vectorLayer = (this.vectorLayer = new VectorLayer({
      source: vectorSource,
    }));

    const extent = [0, 0, options.map.image.size.width, options.map.image.size.height];
    const projection = new Projection({
      code: "xkcd-image",
      units: "pixels",
      extent: extent,
    });
    const enable = params.map.enable;

    const map = (this.map = new Map({
      controls: ControlDefaults({ rotate: enable.rotate, zoom: enable.zoom }),
      interactions: InteractionDefaults({
        altShiftDragRotate: enable.rotate,
        doubleClickZoom: enable.zoom,
        keyboard: enable.zoom,
        mouseWheelZoom: enable.zoom,
        shiftDragZoom: enable.zoom,
        dragPan: enable.zoom,
        pinchRotate: enable.rotate,
        pinchZoom: enable.zoom,
      }),
      layers: [
        new ImageLayer({
          source: new Static({
            url: options.map.image.url,
            projection: projection,
            imageExtent: extent,
          }),
        }),
        vectorLayer,
      ],
      target: options.map.element,
      view: new View({
        projection: projection,
        center: getCenter(extent),
        zoom: options.map.zoom,
        minZoom: options.map.minZoom,
        maxZoom: options.map.maxZoom,
      }),
    }));

    map.getView().on("change:center", function (e) {
      // console.log("e : ", e);
      const position = e.oldValue;
      // console.log("position : ", position);
    });

    // map.on("dblclick", function (e) {
    // });
    map.on("click", function (e) {
      if (options.callback && options.callback.click) {
        options.callback.click(e);
      }
    });

    {
      this.drawItem.point = new Draw({
        source: vectorSource,
        type: "Point",
      });

      const point = this.drawItem.point;
      point.listener = null;

      const me = this as any;

      // 포인트 그리기 종료
      this.drawItem.point.on("drawend", function (event) {
        const feature = event.feature;
        feature.index = ++me.drawing.index;
        feature.featureType = FeatureType.EDITABLE_POINT;

        point.isStartDrawing = false;
        // me.drawing.callback.polygonComplete(feature);
        // console.log("end. ", feature);

        // const coordinate = me.toLatLng(feature.getGeometry().getCoordinates());
        me.drawing.active = false;
        me.map.removeInteraction(me.drawing.drawItem);
        me.drawing.drawItem = null;
        // console.log("coordinateList : ", coordinateList);

        if (point.listener) {
          unByKey(point.listener);
          point.listener = null;
        }
        const callback = me.drawing.callback;
        me.drawing.callback = null;
        if (callback) {
          setTimeout(() => {
            callback(feature);
          }, 1);
        }
      });
    }
    {
      this.drawItem.polygon = new Draw({
        source: vectorSource,
        type: "Polygon",
      });

      const polygon = this.drawItem.polygon;
      polygon.listener = null;

      const me = this as any;

      // 다각형 그리기 시작
      this.drawItem.polygon.on("drawstart", function (event) {
        const feature = event.feature;
        polygon.isStartDrawing = true;
        (event.feature as Feature).setStyle(me.drawing.style.drawing);
        // console.log("start");

        if (polygon.listener != null) {
          unByKey(polygon.listener);
          polygon.listener = null;
        }

        // polygon.listener = feature.getGeometry().on("change", (evt) => {
        //   console.log("evt : ", evt);
        //   const geometry = evt.target;
        //   console.log("last coordinate : ", geometry.getLastCoordinate());
        //
        //   const length = me.getGeometryLength(geometry);
        //   console.log("length : ", length);
        //
        //   // var length = geometryLength(geom);
        //   // var output = length + ' m';
        //   // tooltipCoord = geom.getLastCoordinate();
        //   // feature.tooltip.measureTooltipElement.innerHTML = output;
        //   // feature.distance = length;
        //   // feature.tooltip.measureTooltip.setPosition(tooltipCoord);
        // });
      });

      // 다각형 그리기 종료
      this.drawItem.polygon.on("drawend", function (event) {
        const feature = event.feature;
        feature.hex8Color = "#1e90ff73";
        feature.orgStyle = me.drawing.style.blue;
        feature.index = ++me.drawing.index;
        feature.featureType = FeatureType.EDITABLE_POLYGON;

        polygon.isStartDrawing = false;
        // me.drawing.callback.polygonComplete(feature);
        // console.log("end. ", feature);

        // const coordinateList = [] as any;
        // const keyMap = {} as any;
        // const lngLatArray = feature.getGeometry().getCoordinates()[0];
        // console.log("lngLatArray : ", lngLatArray);
        // lngLatArray.forEach((lngLat) => {
        //   const key = lngLat[0] + "_" + lngLat[1];
        //   if (!keyMap[key]) {
        //     const coordinate = me.toLatLng(lngLat);
        //     coordinateList.push(coordinate);
        //     keyMap[key] = coordinate;
        //   }
        // });
        me.drawing.active = false;
        me.map.removeInteraction(me.drawing.drawItem);
        me.drawing.drawItem = null;
        // console.log("coordinateList : ", coordinateList);

        if (polygon.listener) {
          unByKey(polygon.listener);
          polygon.listener = null;
        }
        const callback = me.drawing.callback;
        me.drawing.callback = null;
        if (callback) {
          setTimeout(() => {
            callback(feature);
          }, 1);
        }
      });
    }

    // const feature = {
    //   geometry: new Point([-8000000, 6095000]),
    //   textAlign: "right",
    // };

    // const createStyle = (text) => {
    //   const textAlign = "center";
    //   const justify = undefined;
    //   return new Style({
    //     // image: new CircleStyle({
    //     //   radius: 10,
    //     //   fill: new Fill({ color: "rgba(255, 0, 0, 0.1)" }),
    //     //   stroke: new Stroke({ color: "red", width: 1 }),
    //     // }),
    //     text: new Text({
    //       font: "16px sans-serif",
    //       textAlign: textAlign,
    //       justify: justify,
    //       text: text,
    //       fill: new Fill({
    //         color: [255, 255, 255, 1],
    //       }),
    //       backgroundFill: new Fill({
    //         color: [0, 0, 0, 0.6],
    //       }),
    //       padding: [2, 5, 2, 5],
    //     }),
    //   });
    // };
    //
    // const feature = new Feature({
    //   geometry: new Point([0, 0]),
    // });
    // feature.setStyle(createStyle(" "));
    // this.vectorSource.addFeature(feature);
  }

  addText(text: string, position: Position) {
    const textAlign = "center";
    const justify = undefined;
    const style = new Style({
      // image: new CircleStyle({
      //   radius: 10,
      //   fill: new Fill({ color: "rgba(255, 0, 0, 0.1)" }),
      //   stroke: new Stroke({ color: "red", width: 1 }),
      // }),
      text: new Text({
        font: "16px sans-serif",
        textAlign: textAlign,
        justify: justify,
        text: text,
        fill: new Fill({
          color: [255, 255, 255, 1],
        }),
        backgroundFill: new Fill({
          color: [0, 0, 0, 0.6],
        }),
        padding: [2, 5, 2, 5],
      }),
    });

    const feature = new Feature({
      geometry: new Point([position.x, position.y]),
    }) as any;
    feature.setStyle(style);
    feature.text = text;
    feature.featureType = FeatureType.TEXT;
    this.vectorSource.addFeature(feature);
    return feature;
  }

  updateText(feature, text: string) {
    const textAlign = "center";
    const justify = undefined;
    const style = new Style({
      // image: new CircleStyle({
      //   radius: 10,
      //   fill: new Fill({ color: "rgba(255, 0, 0, 0.1)" }),
      //   stroke: new Stroke({ color: "red", width: 1 }),
      // }),
      text: new Text({
        font: "16px sans-serif",
        textAlign: textAlign,
        justify: justify,
        text: text,
        fill: new Fill({
          color: [255, 255, 255, 1],
        }),
        backgroundFill: new Fill({
          color: [0, 0, 0, 0.6],
        }),
        padding: [2, 5, 2, 5],
      }),
    });
    feature.text = text;
    feature.setStyle(style);
  }

  destroy() {
    if (this.map != null) {
      this.map.dispose();
    }
  }

  setZoom(zoom: number) {
    if (this.map != null) {
      this.map.getView().setZoom(zoom);
    }
  }

  getZoom() {
    if (this.map != null) {
      return this.map.getView().getZoom();
    }
  }

  addMarker(param: MarkerParam): Feature {
    const markerStyle = new Style({
      image: new Icon({
        anchor: [0.5, 1],
        scale: 0.5,
        src: "/img/marker/ic_marker_red.png",
      }),
    });

    const feature = new Feature({
      geometry: new Point([param.position.x, param.position.y]),
      name: param.name,
    });
    feature.setStyle(markerStyle);
    this.vectorSource.addFeature(feature);
    return feature;
  }

  moveMarker(marker: Feature, x: number, y: number) {
    marker.setGeometry(new Point([x, y]));
  }

  startDrawing(type: DrawingType, callback?: any) {
    if (!this.drawing.active) {
      this.drawing.type = type;
      this.drawing.callback = callback;
      if (type === DrawingType.POLYGON) {
        this.drawing.active = true;
        this.drawing.drawItem = this.drawItem.polygon;
        this.map.addInteraction(this.drawing.drawItem);
      } else if (type === DrawingType.BOX) {
        this.drawing.active = true;
        this.drawing.drawItem = this.drawItem.box;
        this.map.addInteraction(this.drawing.drawItem);
      } else if (type === DrawingType.POINT) {
        this.drawing.active = true;
        this.drawing.drawItem = this.drawItem.point;
        this.map.addInteraction(this.drawing.drawItem);
      } else {
        console.log("unknown type : ", type);
      }
    } else {
      console.log("already drawing");
    }
  }

  stopDrawing() {
    if (this.drawing.drawItem != null) {
      this.drawing.active = false;
      this.map.removeInteraction(this.drawing.drawItem);
      this.drawing.drawItem = null;
    } else {
      console.log("drawItem is null");
    }
  }

  addPolygon(param: PolygonParam): Feature {
    console.log("param : ", param);

    const list = [] as any;
    param.positionList.forEach(function (position) {
      list.push([position.x, position.y]);
    });

    const feature = new Feature({
      geometry: new Polygon([list]),
      name: param.name,
    }) as any;

    if (param.color != null) {
      feature.hex8Color = param.color;
      feature.orgStyle = new Style({
        stroke: new Stroke({
          color: this.drawing.color.white,
          width: 2,
        }),
        fill: new Fill({
          color: param.color,
        }),
      });
    } else {
      feature.hex8Color = "#1e90ff73";
      feature.orgStyle = this.drawing.style.blue;
    }
    (feature as Feature).setStyle(feature.orgStyle);

    feature.featureType = FeatureType.POLYGON;

    this.vectorSource.addFeature(feature);
    return feature;
  }

  updatePolygonColor(feature, color: string) {
    // console.log("feature : ", feature);
    feature.hex8Color = color;
    feature.orgStyle = new Style({
      stroke: new Stroke({
        color: this.drawing.color.white,
        width: 2,
      }),
      fill: new Fill({
        color: color,
      }),
    });
    (feature as Feature).setStyle(feature.orgStyle);
  }

  toLatLng(_coordinate) {
    const coordinate = toLonLat(_coordinate);
    return { latitude: coordinate[1], longitude: coordinate[0] };
  }

  isSelectedFeature(feature): boolean {
    return this.drawing.selectedFeature === feature;
  }

  selectFeature(feature) {
    this.deselectFeature();
    this.drawing.selectedFeature = feature;
    (feature as Feature).setStyle(this.drawing.style.selected);
  }

  deselectFeature() {
    const feature = this.drawing.selectedFeature;
    this.drawing.selectedFeature = null;
    if (feature != null) {
      if (feature.orgStyle != null) {
        (feature as Feature).setStyle(feature.orgStyle);
      } else {
        (feature as Feature).setStyle(this.drawing.style.drawing);
      }
    }
  }

  removeFeature(feature) {
    this.vectorSource.removeFeature(feature);
  }
}
