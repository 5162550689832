import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import { UserModel } from "@/models/user/user.model";
import { NotificationModel } from "@/models/user/notification.model";
import { PlaceModel } from "@/models/place/place.model";
import { NavMenu } from "@/models/core/nav-menu.model";
import { CategoryModel } from "@/models/category/category.model";
import { TeamModel } from "@/models/team/team.model";

/**
 * interface
 */
// Store root state
export interface RootState {
  topToolbar: TopToolbarState;
  app: AppState;
  auth: AuthState;
  storage: StorageState;
}

// Store modules state
export interface TopToolbarState {
  title: string;
  dark: boolean;
  clazz: string;
  elevation: number;
  color: string;
  changedStyle: boolean;
}

export interface Filter {
  type: string;
  filter: any;
}

export interface SessionItem {
  type: string;
  items: any;
}

export interface StorageItem {
  type: string;
  items: any;
}

export interface StorageState {
  filters: Map<string, any>;
  sessionItems: Map<string, any>;
  storageItems: Map<string, any>;
}

export interface AppStateItem<T> {
  ready: boolean;
  first: boolean;
  list: T[];
}

export interface AppState {
  version: string;
  name: string;
  size: WindowSize;
  routerBeforeTask: RouterBeforeTask;
  modal: {
    alert: AlertModal;
  };
  showNav: boolean;
  bottomNav: string;
  path: string;
  platform: string;
  isApp: boolean;
  isMobile: boolean;
  isMobileSize: boolean;
  webSocketConnected: boolean;
  data: {
    place: AppStateItem<PlaceModel>;
    category: AppStateItem<CategoryModel>;
    team: AppStateItem<TeamModel>;
    supportCount: {
      ready: boolean;
      count: number;
    };
    notificationList: NotificationModel[];
  };
  navMenuList: NavMenu[];
}

export interface AuthState {
  ready: boolean;
  user: UserModel | null;
  sessionCheckTime: Date | null;
  mobileToken: string | null;
  systemProperties: MobileSystemProperties | null;
  changedCompanyDate: Date;
  mobileSetting: MobileSetting | null;
  paymentSmsSettings: any;
}

export interface PaymentSms {
  id: number;
  phone: string;
  date: string;
}

export interface MobileSetting {
  receiveCall: boolean;
  paymentSmsList: PaymentSms[];
}

export interface MobileSystemProperties {
  timezone: string;
  locale: string;
  manufacturer: string;
  model: string;
  product: string;
  hardware: string;
  os: string;
  versionSdk: number;
  versionRelease: string;
  deviceToken: string;
  packageName: string;
  versionCode: number;
  versionName: string;
}

export interface WindowSize {
  width: number;
  height: number;
  bodyWidth: number;
}

export interface AlertModal {
  visible: boolean;
  title: string;
  body: string;
  showCancelButton: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  promise: {
    resolve: any;
    reject: any;
  };
  allowBackCloseEvent: boolean;
}

export interface TableRequest {
  draw: number;
  start: number;
  length: number;
  orderColumnName: string;
  order: TableOrder;
  searchColumns?: any;
}

export interface TableResponse<T> {
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
  data: T[];
}

export interface AppBarMenu {
  icon: AppBarIconMenu | undefined | null;
  more: AppBarMoreMenu | null | undefined;
}

export interface AppBarMoreMenu extends AppBarIconMenu {
  width: number;
}

export interface AppBarIconMenu {
  visible: boolean;
  list: AppBarMenuItem[];
}

export interface AppBarMenuItem {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  clickEvent: boolean;
  badge: boolean;
  badgeContent: string | undefined;
}

/**
 * enum
 */
export enum YnType {
  YES = "Y",
  NO = "N",
}

export enum CommentType {
  IMAGE = "I",
  TEXT = "T",
  SYSTEM = "S",
  LINK = "L",
  ADMIN = "A",
}

export enum UpdateEventType {
  RELOAD = "reload",
  FILTER = "filter",
  DELETE = "delete",
  UPDATE = "update",
  CONFIRM = "confirm",
  CANCEL = "cancel",
  PAGE = "page",
  TABLE = "table",
  EVENT = "event",
  DEACTIVATE = "deactivate",
}

export enum EntityType {
  USER = "USER",
  SCHEDULE = "WORK_SCHEDULE",
  ESTIMATE = "WORK_ESTIMATE",
  ESTIMATE_SUPPORT = "WORK_ESTIMATE_SUPPORT",
  SUPPORT_AS = "SUPPORT_AS",
}

export enum ApprovalType {
  REQUEST = "REQUEST",
  ALLOW = "ALLOW",
  DENY = "DENY",
}

export enum TableOrder {
  ASC = "asc",
  DESC = "desc",
}

export enum CompanyRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  USER = "USER",
}

export const CompanyRoleList = [
  { id: CompanyRole.USER, text: "사용자" },
  { id: CompanyRole.MANAGER, text: "매니저" },
  { id: CompanyRole.ADMIN, text: "관리자" },
  { id: CompanyRole.OWNER, text: "소유주" },
];

export enum CompanyDetailType {
  DEFAULT_BANK_ACCOUNT = "DEFAULT_BANK_ACCOUNT",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  ESTIMATE_NOTE = "ESTIMATE_NOTE",
}
