
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import EstimatePrintComponent from "@/components/estimate/EstimatePrintComponent.vue";
import EstimateService from "@/services/work/estimate.service";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import EstimateV2PrintComponent from "@/components/estimate/EstimateV2PrintComponent.vue";

export default mixins(MixinsPage).extend({
  name: "EstimatePrint",

  components: {
    EstimateV2PrintComponent,
    EstimatePrintComponent,
  },

  data: () => ({
    app: store.state.app,
    user: {} as UserModel,
    first: true,
    estimateId: 0,
    estimate: null as any,
  }),

  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params as any;
      this.estimateId = params.id;
      if (this.estimateId <= 0) {
        await this.notFound();
        return;
      }
      this.user = await this.$store.getters["auth/user"]();

      await this.getEstimate();
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(this.app.isMobile ? "mobile" : "pc");
    });
  },
  destroyed() {
    document.documentElement.classList.remove("print");
    document.documentElement.classList.remove(this.app.isMobile ? "mobile" : "pc");
  },
  updated() {
    if (this.first) {
      this.first = false;
      setTimeout(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("android") > -1 && userAgent.indexOf("scc") > -1) {
          core.mobile.call({ cmd: "print" });
        } else if (userAgent.indexOf("msie") > -1 || userAgent.indexOf("trident") > -1) {
          document.execCommand("print", true);
        } else {
          window.print();
        }
      }, 500);
    }
  },

  methods: {
    async getEstimate() {
      core.loader.show();
      try {
        this.estimate = await EstimateService.get(this.estimateId);
        this.estimate.company = this.user.company;
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
  },
});
