
import core from "@/core";
import Vue from "vue";

export default Vue.extend({
  name: "EstimateV2PrintComponent",
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    showAddress: {
      type: Boolean,
      default: true,
    },
    showSign: {
      type: Boolean,
      default: true,
    },
    estimateProps: {
      type: Object,
      default: () => {
        return {
          place: {
            id: 0,
            name: "",
            address1: "",
            address2: "",
            latitude: 0,
            longitude: 0,
          },
          detailList: [],
          customerName: "",
          customerPhone: "",
          dong: "",
          ho: "",
        };
      },
    },
    scheduleListProps: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    title: "",
    address: "",
    estimate: null as any,
    view: {
      estimateNote: "",
      bankAccount: "",
    },
  }),
  mounted() {
    this.$nextTick(() => {
      console.log("mounted");

      // 생성 후 호출
      this.updateEstimate(this.estimateProps, this.scheduleListProps);
    });
  },
  watch: {
    estimateProps() {
      if (this.estimateProps != null) {
        this.updateEstimate(this.estimateProps, this.scheduleListProps);
      }
    },
    scheduleListProps() {
      if (this.estimateProps != null) {
        this.updateEstimate(this.estimateProps, this.scheduleListProps);
      }
    },
  },
  methods: {
    updateEstimate(estimateProps: any, scheduleListProps: any) {
      const estimate = core.utils.deepCopy(estimateProps);
      const scheduleList = core.utils.deepCopy(scheduleListProps);
      // console.log("scheduleList : ", scheduleList);
      // console.log("estimate : ", estimate);
      if (estimate != null) {
        const place = estimate.place;
        let address = "";
        if (place.address1 != null) {
          if (place.address2 != null) {
            address = `${place.address1} ${place.address2}`;
          } else {
            address = `${place.address1}`;
          }
        } else {
          address = `${place.name}`;
        }
        this.address = `${address}, ${estimate.dong}동 ${estimate.ho}호`;

        if (estimate.detailList != null) {
          const categoryItems = {};
          estimate.detailList.forEach((data) => {
            const categoryId = data.category.id;
            let categoryItem = categoryItems[categoryId];
            if (categoryItem == null) {
              categoryItem = categoryItems[categoryId] = data.category;
              // categoryItem.totalUnitPrice = 0;
              // categoryItem.totalDiscount = 0;
              categoryItem.totalPrice = 0;
              categoryItem.list = [];
            }
            // console.log("detail : ", data);
            categoryItem.list.push(data);

            // categoryItem.totalUnitPrice += data.price;
            // categoryItem.totalDiscount += data.discount;
            // 범주별 총합계 계산
            categoryItem.totalPrice += data.unitPrice * data.quantity;
          });
          estimate.categoryItems = categoryItems;
        }
        // console.log("estimate : ", estimate);
        this.estimate = estimate;
      }
      if (this.estimate != null && scheduleList != null) {
        const categoryItems = this.estimate.categoryItems;
        scheduleList.sort((a: any, b: any) => {
          const aMoment = core.date.instance(a.startAt);
          const bMoment = core.date.instance(b.startAt);
          if (aMoment.isBefore(bMoment)) {
            return -1;
          }
          return 1;
        });

        scheduleList.forEach((schedule: any) => {
          if (schedule.category != null) {
            const categoryId = schedule.category.id;
            const categoryItem = categoryItems[categoryId];
            if (categoryItem != null) {
              if (schedule.workType === "D") {
                if (categoryItem.scheduleText == null) {
                  categoryItem.scheduleText = this.scheduleDateText(schedule);
                } else {
                  categoryItem.scheduleText += ", " + this.scheduleDateText(schedule);
                }
              } else {
                // AS
                if (categoryItem.scheduleAsText == null) {
                  categoryItem.scheduleAsText = this.scheduleDateText(schedule);
                } else {
                  categoryItem.scheduleAsText += ", " + this.scheduleDateText(schedule);
                }
              }
            }
          }
          //console.log("schedule : ", schedule);
        });
      }

      if (
        this.estimate != null &&
        this.estimate.company != null &&
        this.estimate.company.detailList != null
      ) {
        this.estimate.company.detailList.forEach((companyDetail) => {
          if (companyDetail.type === "DEFAULT_BANK_ACCOUNT") {
            const array = companyDetail.value.split(",");
            if (array.length === 3) {
              const item = {
                bankName: array[0],
                bankAccount: array[1],
                accountHolder: array[2],
              };
              this.view.bankAccount = `${item.bankName} ${item.bankAccount} ${item.accountHolder}`;
            }
          } else if (companyDetail.type === "ESTIMATE_NOTE") {
            this.view.estimateNote = companyDetail.value;
          }
        });
      }
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY-MM-DD");
      const endDate = core.date.instance(item.endAt).format("YYYY-MM-DD");
      if (startDate == endDate) {
        return core.date.instance(item.startAt).format("YYYY년 MM월 DD일");
      } else {
        return (
          core.date.instance(item.startAt).format("YYYY년 MM월 DD일") +
          " ~ " +
          core.date.instance(item.endAt).format("YYYY년 MM월 DD일")
        );
      }
    },
    moneyFormat(value) {
      return core.utils.format.moneyKor(value);
      // return core.utils.format.money(value);
    },
    dateTimeFormat(value) {
      return core.date.instance(value).format("YYYY년 MM월 DD일 HH시 mm분");
    },
    dateFormat(value) {
      return core.date.instance(value).format("YYYY년 MM월 DD일");
    },
    hasRemark() {
      return this.estimate.remark != null && this.estimate.remark.length > 0;
    },
    getCompanyPhone() {
      if (this.estimate != null && this.estimate.company != null) {
        return core.utils.format.hyphenPhone(this.estimate.company.phone);
      }
      return "";
    },
    isNotBlank(value) {
      return core.utils.validate.isNotBlank(value);
    },
  },
});
