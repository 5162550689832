
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import core from "@/core";
import store from "@/store";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModalForm).extend({
  name: "WorkScopeConfirmModal",
  data: () => ({
    ready: false,
    checkList: [] as any,
    app: store.state.app,
    bodyMinHeight: "100px",
    isSubmit: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.resize();

      const paramCheckList = this.properties.params.checkList;
      const checkList = [] as any;
      if (paramCheckList != null) {
        paramCheckList.forEach((check) => {
          checkList.push({ selected: false, text: check });
        });
      }
      this.checkList = checkList;
      this.ready = true;
    });
  },
  watch: {
    "app.size"() {
      this.resize();
    },
  },
  methods: {
    toggleItem(item) {
      item.selected = !item.selected;
      let checkCount = 0;
      this.checkList.forEach((check) => {
        if (check.selected) checkCount++;
      });

      this.isSubmit = this.checkList.length === checkCount;
    },
    resize() {
      const platform = core.utils.platform();
      let offset = 0;
      if (platform === "android" || platform === "ios") {
        offset = 200;
      } else {
        if (this.app.size.width < 965) {
          offset = 215;
        } else {
          offset = 220;
        }
      }
      this.bodyMinHeight = this.app.size.height - offset + "px";
    },
    async submit() {
      // console.log("submit");
      this.close(UpdateEventType.CONFIRM);
      // if (await this.validate()) {
      //   try {
      //     core.loader.show("문자 전송중...");
      //   } catch (e) {
      //     console.log(e);
      //   } finally {
      //     core.loader.hide();
      //   }
      // }
    },
  },
});
