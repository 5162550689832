
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import MixinsTable from "@/mixins/single/table";
import MixinsScroll, { ScrollPosition } from "@/mixins/single/scroll";
import core from "@/core";
import { SubscribeType } from "@/core/core-web-socket";
import EstimateUtils from "@/models/estimate";
import SupportService from "@/services/support/support.service";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPage, MixinsTable, MixinsScroll).extend({
  name: "Support",
  data: () => ({
    table: {
      body: document.documentElement,
      defaultColumns: [],
      firstLoading: true,
      loading: false,
      request: {
        orderColumnName: "updatedAt",
        searchColumns: {
          commentCountGreaterThan: 0,
        },
      },
    },
  }),

  mounted() {
    this.initTable({
      service: SupportService,
      serviceFunctionKey: "getTable",
      itemHeight: 60,
    });

    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      // 테이블 정보 가져오기
      this.loadTable();
    });
  },
  watch: {
    "scroll.scrollTop"(scrollTop) {
      this.scrollTop = scrollTop;
    },
  },
  activated() {
    this.$nextTick(() => {
      const event = this.getPageUpdateEvent();
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          if (event.path.indexOf("search") > -1) {
            this.updateTableItem("id", event.item);
          } else {
            this.addTableItem("id", event.item);
          }
          this.setScrollPosition(ScrollPosition.TOP);
        } else if (event.result === UpdateEventType.DELETE) {
          this.deleteTableItem("id", event.item);
        } else if (event.result === UpdateEventType.RELOAD) {
          this.setScrollPosition(ScrollPosition.TOP);
          this.clearTable();
          this.loadTable();
        } else {
          console.log("unknown event : ", event);
        }
      }
      this.subscribe();
    });
  },
  deactivated() {
    this.unsubscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    showViewPage(id) {
      this.$router.push(`/support/${id}`);
    },
    titleToText(item) {
      return EstimateUtils.titleToHtml(item.estimate);
    },
    subscribe() {
      const vm = this;
      const user = (this.$store.state as any).auth.user;
      core.webSocket.subscribe(SubscribeType.SUPPORT, {
        companyId: user.company.id,
        callback: (event) => {
          const crud = event.crud;
          const item = event.item;
          //console.log("event : ", event);
          if (this.addTableItem("estimateId", item)) {
            this.setScrollPosition(ScrollPosition.TOP);
          }
        },
      });
    },
    unsubscribe() {
      const user = (this.$store.state as any).auth.user as any;
      core.webSocket.unsubscribe(SubscribeType.SUPPORT, { companyId: Number(user.company.id) });
    },
  },
});
