
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import MixinsStorage from "@/mixins/single/storage";
import core from "@/core";
import PlaceService from "@/services/work/place.service";
import { localize } from "vee-validate";
import Constant from "@/store/constant";
import CompanyService from "@/services/company/company.service";
import TeamService from "@/services/team/team.service";
import ScheduleUtils, { ScheduleViewType } from "@/models/schedule";
import { cloneDeep } from "lodash";
import { UpdateEventType } from "@/types";
import { TeamModel } from "@/models/team/team.model";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    viewType: "일정표시",
    placeId: "아파트 ",
    dong: "동 ",
    ho: "호 ",
    customerPhone: "휴대전화 ",
    customerName: "고객명 ",
  },
});

export default mixins(MixinsPageForm, MixinsStorage).extend({
  name: "ScheduleFilter",
  data: () => ({
    storageItems: {
      type: "schedule-filter",
    },
    storageKey: "",
    categoryList: [] as any,
    placeList: [] as any,
    userList: [] as any,
    teamList: [] as any,
    form: {
      estimateIdIsNull: null as any,
      viewType: "",
      userId: null as number | null,
      userName: "",
      placeName: "",
      placeId: "",
      dong: "",
      ho: "",
      customerPhone: "",
      workType: "",
      categoryIdList: [],
      teamId: "" as number | string,
      title: "",
    } as any,
    modal: {
      place: {
        visible: false,
      },
      user: {
        visible: false,
      },
    },
    visible: {
      viewType: false,
      category: false,
      team: false,
      place: false,
      dong: false,
      ho: false,
      customerPhone: false,
      workType: false,
      estimate: false,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const query = this.$route.query;
      const viewType = ScheduleUtils.getViewType(query.viewType as string);
      if (viewType == null) {
        await this.notFound();
        return;
      }
      this.storageKey = ScheduleUtils.getStorageKey(query);

      ScheduleUtils.updateFilterVisibleView(viewType, this.visible);
      const visible = this.visible;
      if (!visible.viewType) {
        delete this.form.viewType;
      } else {
        this.form.viewType = ScheduleUtils.getFilterViewType(viewType, {
          viewType: query.filterViewType as string,
        });
      }
      if (!visible.place) {
        delete this.form.placeName;
        delete this.form.placeId;
      }
      if (!visible.dong) {
        delete this.form.dong;
      }
      if (!visible.ho) {
        delete this.form.ho;
      }
      if (!visible.customerPhone) {
        delete this.form.customerPhone;
      }
      if (!visible.workType) {
        delete this.form.workType;
      }
      if (!visible.category) {
        delete this.form.categoryIdList;
      }
      if (!visible.team) {
        delete this.form.teamId;
      }

      console.log("storageKey : ", this.storageKey);

      await this.storageWait();
      {
        const items = this.getStorageItems(this.storageKey);

        if (items == null) {
          this.setStorageItem(this.storageKey, {});
        }
      }

      if (this.visible.place) {
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      }

      if (this.visible.category) {
        this.categoryList = cloneDeep(await this.$store.getters["app/getCategoryList"]());
        this.categoryList.splice(0, 0, { name: "없음", id: "null" });
      }

      if (this.isCompanyManagerRoleHigher) {
        const myUser = core.utils.deepCopy(this.myUser);
        myUser.text = `내 일정`;
        this.userList.push(myUser);
        (
          (await CompanyService.getUserList({
            lastApprovalType: "ALLOW",
          })) as any
        ).forEach((user) => {
          if (myUser.id != user.id) {
            this.userList.push(user);
          }
        });
      } else {
        const myUser = core.utils.deepCopy(this.myUser);
        myUser.text = `내 일정`;
        this.userList.push(myUser);

        if (this.isCompanyUserRole) {
          const strTeamId = query.teamId;
          if (strTeamId != null && this.myUser.company.teamList) {
            const teamId = Number(strTeamId);
            let isLeader = false;
            this.myUser.company.teamList.some((team) => {
              if (team.id === teamId) {
                if (team.role === "LEADER") {
                  isLeader = true;
                }
                return true;
              }
            });
            if (isLeader) {
              //console.log("isLeader !");
              const userList = (await TeamService.getUserList(teamId)) as any;
              userList.forEach((user) => {
                if (myUser.id != user.id) {
                  this.userList.push(user);
                }
              });
            }
            //console.log("teamId : ", teamId);
          }
        }
      }

      if (this.visible.team) {
        this.teamList.push({
          id: "null",
          text: "없음",
        });
        ((await this.$store.getters["app/getTeamList"]()) as any).forEach((team) => {
          let addFlag = false;
          if (viewType === ScheduleViewType.CATEGORY) {
            const categoryId = Number(query.categoryId);
            if (categoryId === team.category.id) {
              addFlag = true;
            }
          } else {
            addFlag = true;
          }
          if (addFlag) {
            team.text = `[${team.category.name}] ${team.name}팀`;
            this.teamList.push(team);
          }
        });
      }

      //console.log("form : ", JSON.stringify(this.form));
      // this.initFilter(query);
      this.loadFilter();
    });
  },
  watch: {
    "form.customerPhone"(val, prev) {
      this.form.customerPhone = core.utils.format.hyphenPhone(val);
    },
    "form.placeName"(val) {
      if (this.isBlank(val)) {
        this.form.placeId = "";
      }
    },
    "form.userName"(val) {
      if (this.isBlank(val)) {
        this.form.userId = null;
      }
    },
  },
  methods: {
    clearFilter() {
      for (const key of Object.keys(this.form)) {
        this.form[key] = "";
      }
      this.form.viewType = "default";
      this.submit();
    },
    loadFilter() {
      console.log("필터 불러오기");
      const items = this.getStorageItems(this.storageKey);
      for (const key of Object.keys(this.form)) {
        this.form[key] = "";
      }
      for (const key of Object.keys(items)) {
        this.form[key] = items[key];
      }
      if (this.isBlank(this.form.viewType)) {
        this.form.viewType = "default";
      }
    },
    saveFilter() {
      const items = this.getStorageItems(this.storageKey);
      for (const key of Object.keys(items)) {
        delete items[key];
      }
      for (const key of Object.keys(this.form)) {
        if (this.isNotBlank(this.form[key])) {
          if (key === "categoryIdList") {
            if (this.form[key].length > 0) {
              items[key] = this.form[key];
            }
          } else {
            items[key] = this.form[key];
          }
        }
      }
      this.saveStorageItems();
    },
    async submit() {
      if (await this.validate()) {
        const params = core.utils.deepCopy(this.form);
        console.log("params : ", params);
        delete params.placeName;
        delete params.userName;
        for (const key of Object.keys(params)) {
          const value = params[key];
          if (this.isBlank(value)) {
            delete params[key];
          } else if (key === "categoryIdList") {
            if (value.length > 0) {
              params[key] = value.join(",");
            } else {
              delete params[key];
            }
          } else if (key === "customerPhone") {
            params[key] = params[key].replaceAll("-", "");
          }
        }
        this.saveFilter();
        // console.log("params : ", params);
        this.goBack(UpdateEventType.FILTER, params);
      }
    },
  },
});
