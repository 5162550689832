
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import CompanyService from "@/services/company/company.service";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";
import TeamService from "@/services/team/team.service";
import { UserModel } from "@/models/user/user.model";

export enum RoleType {
  콩콩매트_기본팀 = "default",
}

export default mixins(MixinsPage).extend({
  name: "MgmtUserView",
  data: () => ({
    id: 0,
    ready: false,
    edit: false, // 편집중 여부
    changedUser: false,
    companyRoleItem: {
      visible: false,
      role: "",
      items: [
        { id: "USER", text: "사용자" },
        { id: "MANAGER", text: "매니저" },
        { id: "ADMIN", text: "관리자" },
        { id: "OWNER", text: "소유주" },
      ],
    },
    companyScheduleRoleItem: {
      visible: false,
      role: "",
      items: [
        { id: "ALL", text: "전체" },
        { id: "TEAM", text: "팀" },
        { id: "USER", text: "담당자" },
      ],
    },
    companyAttendanceItem: {
      visible: false,
      attendanceYn: "",
      items: [
        { id: "Y", text: "사용" },
        { id: "N", text: "미사용" },
      ],
    },
    user: null as any,

    teamList: null as any,

    placeList: null as any,

    accessPlaceList: null as any,

    modal: {
      team: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
        list: [],
        selectedId: null as any,
      },
      place: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
        list: [],
        selectedItem: null as any,
      },
    },
    myUser: {} as UserModel,
  }),
  mounted() {
    this.$nextTick(async () => {
      this.id = Number(this.$route.params.id);
      if (this.id < 1) {
        await this.notFound();
        return;
      }
      this.myUser = await this.$store.getters["auth/user"]();

      this.companyRoleItem.role = this.myUser.company.role;
      this.companyScheduleRoleItem.role = String(this.myUser.company.scheduleRole);
      await this.getUser();
      this.ready = true;
    });
  },
  computed: {
    RoleType() {
      return RoleType;
    },
    teams() {
      let teamText = "";
      if (this.user && this.user.company && this.user.company.teamList) {
        const teamList = this.user.company.teamList;
        teamList.forEach((team) => {
          teamText += `[${team.category.name}] ${team.name}<br>`;
        });
      }
      if (teamText.length === 0) {
        teamText = "없음";
      }
      return teamText;
    },
    userPhone() {
      return core.utils.format.hyphenPhone(this.user.phone);
    },
    userCompanyAttendanceYn() {
      const attendanceYn = this.user.company.attendanceYn;
      const items = this.companyAttendanceItem.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === attendanceYn) {
          return item.text;
        }
      }
      return "알 수 없음";
    },
    userCompanyRole() {
      const role = this.user.company.role;
      const items = this.companyRoleItem.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === role) {
          return item.text;
        }
      }
      return "알 수 없음";
    },
    userCompanyScheduleRole() {
      const role = this.user.company.scheduleRole;
      const items = this.companyScheduleRoleItem.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === role) {
          return item.text;
        }
      }
      return "알 수 없음";
    },
  },
  watch: {
    async "modal.team.selectedId"(val) {
      if (val != null) {
        //console.log("val : ", val);
        core.loader.show();
        try {
          const result = await TeamService.addUser(val, Number(this.user.id), "MEMBER");
          //console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }

        await this.getUser();

        core.loader.hide();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      }
      this.modal.team.selectedId = null;
    },

    async "modal.place.selectedItem"(item) {
      if (item != null) {
        console.log("item : ", item);
        core.loader.show();
        try {
          let options = core.utils.deepCopy(this.user.company.options) as any;
          if (options == null) {
            options = { accessPlaceList: [] };
          }
          options.accessPlaceList.push({
            id: item.id,
            name: item.name,
          });
          const params = {
            userId: this.user.id,
            options: options,
          };
          const result = await CompanyService.updateUserCompanyOptions(params);
          //console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }

        await this.getUser();

        core.loader.hide();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      }
      this.modal.place.selectedItem = null;
    },
  },
  methods: {
    async changeRole(roleType: RoleType) {
      if (roleType === RoleType.콩콩매트_기본팀) {
        core.loader.show();

        try {
          const params = {
            userId: this.user.id,
            role: "TEAM",
          };
          this.updateUser(await CompanyService.updateUserCompanyScheduleRole(params));
        } catch (e) {
          console.log(e);
        }

        try {
          const params = {
            userId: this.user.id,
            attendanceYn: "Y",
          };
          this.updateUser(await CompanyService.updateUserCompanyAttendanceYn(params));
        } catch (e) {
          console.log(e);
        }

        this.teamList = await this.$store.getters["app/getTeamList"]();
        const teamList = [] as any;
        this.teamList.forEach((team) => {
          if (this.user.company.teamList != null) {
            let exists = false;
            this.user.company.teamList.some((userTeam) => {
              if (team.id == userTeam.id) {
                exists = true;
                return true;
              }
            });
            if (!exists) {
              team.text = `[${team.category.name}] ${team.name}`;
              teamList.push(team);
            }
          }
        });
        if (teamList.length > 0) {
          let addTeam = null as any;
          teamList.some((team) => {
            console.log("team : ", team.id, team.name, team.category.name);
            if (team.category.name === "매트" && team.name === "기본") {
              addTeam = team;
              return true;
            }
          });
          if (addTeam != null) {
            const result = await TeamService.addUser(addTeam.id, Number(this.user.id), "MEMBER");
          }
        }
        await this.getUser();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
        core.loader.hide();
      } else {
        console.log("invalid role type : ", roleType);
      }
    },
    async deleteUser() {
      const result = await core.alert.show({
        title: "확인",
        body: `회원 탈퇴를 계속 진행하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "계속",
      });

      if (result === "confirm") {
        try {
          const result = (await CompanyService.deleteUser(this.id)) as any;
          console.log("result : ", result);
        } catch (e) {
          console.log(e);
        } finally {
          this.goBack(UpdateEventType.RELOAD);
        }
      }
    },
    async getUser() {
      try {
        const userList = (await CompanyService.getUserList({
          id: this.id,
        })) as any;
        if (userList.length !== 1) {
          console.log("not found user info");
          await this.notFound();
        } else {
          this.user = userList[0];
          this.updateUser(this.user, false);
          this.changedUser = false;
        }
      } catch (e) {
        await this.notFound();
      }
    },
    showEditPage() {
      this.$router.push({
        path: "/user/edit",
        query: {
          id: String(this.id),
        },
      });
    },
    phoneFormat(phone: string) {
      return core.utils.format.hyphenPhone(phone);
    },
    cancelUserCompanyScheduleRole() {
      this.companyScheduleRoleItem.visible = false;
      this.updateUser(this.user);
    },
    cancelUserCompanyRole() {
      this.companyRoleItem.visible = false;
      this.updateUser(this.user);
    },
    cancelUserCompanyAttendanceYn() {
      this.companyAttendanceItem.visible = false;
      this.updateUser(this.user);
    },
    async changeApproval(value: string) {
      let msg = "";
      if (value === "ALLOW") {
        if (this.user.company.lastApproval.type === "REQUEST") {
          msg = "승인하시겠습니까?";
        } else {
          msg = "재승인하시겠습니까?";
        }
      } else if (value === "DENY") {
        if (this.user.company.lastApproval.type === "REQUEST") {
          msg = "승인을 거절 하시겠습니까?";
        } else {
          msg = "승인을 해제 하시겠습니까?";
        }
      }
      const result = await core.alert.show({
        title: "확인",
        body: msg,
        showCancelButton: true,
        confirmButtonText: "계속",
      });

      if (result === "confirm") {
        core.loader.show();
        try {
          this.updateUser(
            await CompanyService.changeApproval({
              userId: this.user.id,
              approvalType: value,
            })
          );
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
      }
    },
    async changeUserCompanyScheduleRole() {
      const scheduleRoleItem = this.companyScheduleRoleItem;
      const result = await core.alert.show({
        title: "확인",
        body: "일정 권한 변경을 계속 진행하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "계속",
      });
      if (result === "confirm") {
        if (scheduleRoleItem.role !== this.user.company.scheduleRole) {
          core.loader.show();
          const params = {
            userId: this.user.id,
            role: scheduleRoleItem.role,
          };

          try {
            this.updateUser(await CompanyService.updateUserCompanyScheduleRole(params));
            scheduleRoleItem.visible = false;
          } catch (e) {
            console.log(e);
          }
          core.loader.hide();
        } else {
          this.edit = false;
          scheduleRoleItem.visible = false;
        }
      }
    },
    async changeUserCompanyRole() {
      const companyRoleItem = this.companyRoleItem;
      const result = await core.alert.show({
        title: "확인",
        body: "업체 권한 변경을 계속 진행하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "계속",
      });
      if (result === "confirm") {
        if (companyRoleItem.role !== this.user.company.role) {
          core.loader.show();
          const params = {
            userId: this.user.id,
            role: companyRoleItem.role,
          };

          try {
            this.updateUser(await CompanyService.updateUserCompanyRole(params));
            companyRoleItem.visible = false;
          } catch (e) {
            console.log(e);
          }
          core.loader.hide();
        } else {
          this.edit = false;
          companyRoleItem.visible = false;
        }
      }
    },
    async changeUserCompanyAttendanceYn() {
      const item = this.companyAttendanceItem;
      const result = await core.alert.show({
        title: "확인",
        body: "출근부 사용 여부 변경을 계속 진행하시겠습니까?",
        showCancelButton: true,
        confirmButtonText: "계속",
      });
      if (result === "confirm") {
        if (item.attendanceYn !== this.user.company.attendanceYn) {
          core.loader.show();
          const params = {
            userId: this.user.id,
            attendanceYn: item.attendanceYn,
          };

          try {
            this.updateUser(await CompanyService.updateUserCompanyAttendanceYn(params));
            item.visible = false;
          } catch (e) {
            console.log(e);
          }
          core.loader.hide();
        } else {
          this.edit = false;
          item.visible = false;
        }
      }
    },
    updateUser(user: any, changedUser?: boolean) {
      if (changedUser == null) changedUser = true;
      this.user = user;
      this.companyRoleItem.role = this.user.company.role;
      this.companyScheduleRoleItem.role = this.user.company.scheduleRole;
      this.companyAttendanceItem.attendanceYn = this.user.company.attendanceYn;
      this.changedUser = changedUser;
      this.edit = false;

      if (changedUser) {
        this.updatePreVmEvent(UpdateEventType.UPDATE, core.utils.deepCopy(this.user));
      }
    },
    async addTeam() {
      // 팀 추가
      console.log("addTeam");
      if (this.teamList == null) {
        this.teamList = await this.$store.getters["app/getTeamList"]();
      }
      const teamList = [] as any;
      this.teamList.forEach((team) => {
        if (this.user.company.teamList != null) {
          let exists = false;
          this.user.company.teamList.some((userTeam) => {
            if (team.id == userTeam.id) {
              exists = true;
              return true;
            }
          });
          if (!exists) {
            team.text = `[${team.category.name}] ${team.name}`;
            teamList.push(team);
          }
        }
      });
      if (teamList.length === 0) {
        const result = await core.alert.show({
          title: "알림",
          body: `추가할 수 있는 팀이 없습니다!`,
          confirmButtonText: "확인",
        });
      } else {
        const modal = this.modal.team;
        modal.list = teamList;
        modal.visible = true;
      }
    },
    async removeTeam(team: any) {
      //console.log("removeTeam : ", team);
      const result = await core.alert.show({
        title: "확인",
        body: `"[${team.category.name}]${team.name}" 팀을 삭제하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        //console.log("deleteUser : ", user);
        core.loader.show();
        try {
          const result = await TeamService.deleteUser(team.id, Number(this.user.id));
          //console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }

        await this.getUser();

        core.loader.hide();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      }
    },
    async addPlace() {
      // 팀 추가

      console.log("addPlace");
      if (this.placeList == null) {
        this.placeList = await this.$store.getters["app/getPlaceList"]();
      }

      const placeList = [] as any;
      this.placeList.forEach((place) => {
        let exists = false;
        if (this.user.company.options != null && this.user.company.options.placeList != null) {
          this.user.company.placeList.some((place) => {
            if (place.id == place.id) {
              exists = true;
              return true;
            }
          });
        }
        if (!exists) {
          place.text = `${place.name}`;
          placeList.push(place);
        }
      });
      if (placeList.length === 0) {
        const result = await core.alert.show({
          title: "알림",
          body: `추가할 수 있는 아파르가 없습니다!`,
          confirmButtonText: "확인",
        });
      } else {
        const modal = this.modal.place;
        modal.list = placeList;
        modal.visible = true;
      }
    },
    async removePlace(item: any) {
      //console.log("removePlace : ", team);
      const result = await core.alert.show({
        title: "확인",
        body: `접근 가능 아파트 "${item.name}" 를 삭제하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        //console.log("deleteUser : ", user);
        core.loader.show();
        try {
          const options = core.utils.deepCopy(this.user.company.options) as any;
          if (options != null && options.accessPlaceList != null) {
            const placeList = options.accessPlaceList;
            for (let i = 0; i < placeList.length; i++) {
              if (placeList[i].id == item.id) {
                placeList.splice(i, 1);
                break;
              }
            }
            const params = {
              userId: this.user.id,
              options: options,
            };
            const result = await CompanyService.updateUserCompanyOptions(params);
          }
          //console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }

        await this.getUser();

        core.loader.hide();
        this.updatePreVmEvent(UpdateEventType.RELOAD);
      }
    },
  },
});
