
import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import { Category } from "@/components/unit-price/AddEditV2Component.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import {
  SpecialUnitPriceImageModel,
  SpecialUnitPriceSrcImageModel,
} from "@/models/special-unit-price/special-unit-price.model";
import Constant from "@/store/constant";
import SelectFileDetailModal from "@/modals/core/SelectFileDetailModal.vue";
import { FileDetailModel } from "@/models/file/file-detail.model";
import SpecialUnitPriceService from "@/services/special-unit-price/special-unit-price.service";
import core from "@/core";
import { cloneDeep } from "lodash";

export default mixins(MixinsComponent).extend({
  name: "UnitPriceAddEditCardItemV2Component",
  components: { SelectFileDetailModal },

  props: {
    properties: {
      type: Object,
      default: () => {
        return {
          updateEvent: null as UpdateEvent | null,
        };
      },
    },
    unitPrice: {
      type: Object,
      default: () => {
        return null;
      },
    },
    category: {
      type: Object as () => Category,
      default: () => {
        return null as Category | null;
      },
    },
    specialUnitPriceSrcImageList: {
      type: Array as () => SpecialUnitPriceSrcImageModel[],
      default: () => {
        return [] as SpecialUnitPriceSrcImageModel[];
      },
    },
    specialUnitPriceImageList: {
      type: Array as () => SpecialUnitPriceImageModel[],
      default: () => {
        return [] as SpecialUnitPriceImageModel[];
      },
    },
  },
  data: () => ({
    useSpecialPrice: false,
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
    fileDetailList: [] as FileDetailModel[],
    modal: {
      selectFileDetail: {
        visible: false,
        params: {
          typeItem: null,
        } as any,
        updateEvent: null as UpdateEvent | null,
      },
    },
    ready: false,
  }),
  mounted() {
    this.changedCategory();
    this.ready = true;
  },
  watch: {
    category() {
      console.log("change category : ", this.category);
      this.changedCategory();
    },
    "modal.selectFileDetail.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.selectFileDetail);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const itemList = event.item;
          if (itemList != null && itemList.length > 0) {
            const typeItem = this.modal.selectFileDetail.params.typeItem;
            // console.log("typeItem : ", typeItem);
            // console.log("itemList : ", itemList);
            if (typeItem == null) {
              this.category.typeList.forEach((typeItem) => {
                const typeName = typeItem.name;
                this.addImages(typeName, itemList);
              });
              core.alert.show({
                title: "알림",
                body: "이미지가 추가되었습니다",
              });
            } else {
              const typeName = typeItem.name;
              this.addImages(typeName, itemList);
            }
          }
        }
      }
    },
  },
  methods: {
    changedAmount(list, item) {
      // console.log("change item : ", item);
      let amountModel = "";
      const preAmountModel = item.amountModel;
      if (this.isNotBlank(item.unitPriceModel) && this.isNotBlank(item.quantityModel)) {
        amountModel = String(Number(item.unitPriceModel) * Number(item.quantityModel));
      } else {
        amountModel = "";
      }
      item.amountModel = amountModel;
      list.some((orgItem, index) => {
        // console.log("orgItem : ", orgItem);
        if (orgItem.index === item.index) {
          list.splice(index, 1, cloneDeep(item));
          return true;
        }
      });
      // console.log("preAmountModel : ", preAmountModel, ", amountModel : ", item.amountModel);
    },
    moveImage(direction, image, typeItem) {
      const imageList = typeItem.imageList;

      let pos = 0;
      imageList.some((_image, index) => {
        if (image.srcImageId === _image.srcImageId) {
          pos = index;
          return true;
        }
      });

      if (direction === "down") {
        if (pos + 1 < imageList.length) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos + 1, 0, fromItem[0]);
        }
      } else if (direction === "up") {
        if (0 < pos) {
          const fromItem = imageList.splice(pos, 1);
          imageList.splice(pos - 1, 0, fromItem[0]);
        }
      }

      this.updateImages(typeItem, imageList);
    },
    removeImage(image, typeItem) {
      const imageList = typeItem.imageList;
      imageList.some((_image, index) => {
        if (image.srcImageId === _image.srcImageId) {
          imageList.splice(index, 1);
          return true;
        }
      });
      this.updateImages(typeItem, imageList);
    },
    updateImages(typeItem, imageList) {
      const unitPrice = this.unitPrice;
      if (unitPrice) {
        const placeId = unitPrice.placeId;
        const categoryId = this.category.id;
        const srcImageIdList = [] as number[];
        const type = typeItem.name;
        imageList.forEach((image) => {
          srcImageIdList.push(image.srcImageId);
        });
        SpecialUnitPriceService.updateImages(placeId, categoryId, type, srcImageIdList).then(
          (imageList) => {
            this.changedSpecialUnitPriceImageList(
              placeId,
              categoryId,
              typeItem,
              imageList as SpecialUnitPriceImageModel[]
            );
          }
        );
      }
    },
    changedCategory() {
      if (this.category != null && this.category.typeList) {
        this.category.typeList.forEach((typeItem: any) => {
          const imageList = typeItem.imageList;
          if (this.specialUnitPriceImageList != null && this.specialUnitPriceImageList.length > 0) {
            this.specialUnitPriceImageList.forEach((specialUnitPriceImage) => {
              if (
                specialUnitPriceImage.categoryId === this.category.id &&
                specialUnitPriceImage.type === typeItem.name
              ) {
                imageList.push(specialUnitPriceImage);
              }
            });
            imageList.sort((a: any, b: any) => {
              if (a.no < b.no) return -1;
              else if (a.no > b.no) return 1;
              return 0;
            });
          }
        });
      }
      if (
        this.specialUnitPriceSrcImageList != null &&
        this.specialUnitPriceSrcImageList.length > 0
      ) {
        this.specialUnitPriceSrcImageList.forEach((srcImage) => {
          this.fileDetailList.push(srcImage.image);
        });
      }
    },
    async addImages(type, fileDetailList) {
      const unitPrice = this.unitPrice;
      if (unitPrice) {
        const placeId = unitPrice.placeId;
        const categoryId = this.category.id;
        let typeItem = null as any;
        this.category.typeList.some((_typeItem) => {
          if (_typeItem.name === type) {
            typeItem = _typeItem;
            return true;
          }
        });
        if (typeItem == null) {
          console.log("not found type");
          return;
        }

        // console.log("placeId : ", placeId);
        // console.log("categoryId : ", categoryId);
        // console.log("type : ", type);
        // console.log("fileDetailList : ", fileDetailList);
        const srcImageIdList = [] as number[];
        typeItem.imageList.forEach((image) => {
          srcImageIdList.push(image.srcImageId);
        });
        fileDetailList.forEach((fileDetail) => {
          this.specialUnitPriceSrcImageList.some((specialUnitPriceSrcImage) => {
            if (specialUnitPriceSrcImage.fileDetailId === fileDetail.id) {
              srcImageIdList.push(specialUnitPriceSrcImage.id);
              return true;
            }
          });
        });
        if (srcImageIdList.length > 0) {
          const imageList = (await SpecialUnitPriceService.updateImages(
            placeId,
            categoryId,
            type,
            srcImageIdList
          )) as SpecialUnitPriceImageModel[];
          this.changedSpecialUnitPriceImageList(placeId, categoryId, typeItem, imageList);
        }
      } else {
        console.log("not found unitPrice : ", unitPrice);
      }
    },
    changedSpecialUnitPriceImageList(
      placeId: number,
      categoryId: number,
      typeItem: any,
      imageList: SpecialUnitPriceImageModel[]
    ) {
      // console.log("imageList : ", imageList);
      const type = typeItem.name;
      const specialUnitPriceImageList = this.specialUnitPriceImageList;
      let running = true;
      while (running) {
        running = false;
        const length = specialUnitPriceImageList.length;
        for (let i = 0; i < length; i++) {
          const specialUnitPriceImage = specialUnitPriceImageList[i];
          if (
            specialUnitPriceImage.placeId === placeId &&
            specialUnitPriceImage.categoryId === categoryId &&
            specialUnitPriceImage.type === type
          ) {
            specialUnitPriceImageList.splice(i, 1);
            running = true;
            break;
          }
        }
      }

      imageList.some((image) => {
        this.specialUnitPriceImageList.push(image);
      });
      typeItem.imageList.length = 0;
      imageList.forEach((image) => {
        typeItem.imageList.push(image);
      });
    },
    showImageSelectView(typeItem) {
      // console.log("typeItem : ", typeItem);
      const modal = this.modal.selectFileDetail;

      const imageList = [] as SpecialUnitPriceImageModel[];
      if (typeItem != null) {
        typeItem.imageList.some((image) => {
          imageList.push(image);
        });
      } else {
        // typeItem null 이면 모든 타입의 이미지 추가
        this.category.typeList.forEach((typeItem) => {
          typeItem.imageList.forEach((typeItemImage) => {
            let exists = false;
            imageList.some((image) => {
              if (typeItemImage.srcImageId === image.srcImageId) {
                exists = true;
                return true;
              }
            });
            if (!exists) {
              imageList.push(typeItemImage);
            }
          });
        });
      }
      this.fileDetailList = [];
      this.specialUnitPriceSrcImageList.forEach((srcImage) => {
        let exists = false;
        imageList.some((image) => {
          if (image.srcImageId === srcImage.id) {
            exists = true;
            return true;
          }
        });
        if (!exists) {
          this.fileDetailList.push(srcImage.image);
        }
      });
      if (this.fileDetailList.length > 0) {
        modal.params.typeItem = typeItem;
        modal.visible = true;
      } else {
        core.alert.show({
          title: "알림",
          body: "추가할 이미지 파일이 없습니다",
        });
      }
    },
    getFilename(uri: string) {
      if (uri != null) {
        const pos = uri.lastIndexOf("/");
        if (pos > -1) {
          return uri.substring(pos + 1, uri.length);
        }
      }
      return uri;
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    addWorkScope() {
      this.properties.updateEvent = new UpdateEvent(UpdateEventType.EVENT, "", {
        type: "addWorkScope",
      });
    },
    editWorkScope(item) {
      this.properties.updateEvent = new UpdateEvent(UpdateEventType.EVENT, "", {
        type: "editWorkScope",
        item: item,
      });
    },
    deleteWorkScope(item) {
      this.properties.updateEvent = new UpdateEvent(UpdateEventType.EVENT, "", {
        type: "deleteWorkScope",
        item: item,
      });
    },
    editType(typeItem) {
      this.properties.updateEvent = new UpdateEvent(UpdateEventType.EVENT, "", {
        type: "typeItem",
        item: typeItem,
      });
    },
  },
});
